import React from "react";
import { Image, View } from "react-native";
import Header from "./Header";
import { useTheme } from "@emotion/react";
import { ODSText } from "../../../openbrix-design-system/ui/atoms/ODSText";
import getScreenType from "../../../openbrix-design-system/utils/getScreenType";
import { Body, Content, HeaderContent, HeaderImage, Picture1 } from "./styles";
import Footer from "./Footer";
import Wrapper from "./Wrapper";
import { TouchableOpacity } from "react-native-gesture-handler";
const HeaderWeb = require('../../../openbrix-design-system/images/tlyfe-pages/cb-desktop.png');
const HeaderTab = require('../../../openbrix-design-system/images/tlyfe-pages/cb-tab.png');
const HeaderMobile = require('../../../openbrix-design-system/images/tlyfe-pages/cb-mobile.png');
const Picture = require('../../../openbrix-design-system/images/tlyfe-pages/credit-builder/picture1.png');
const FooterWeb=require('../../../openbrix-design-system/images/tlyfe-pages/credit-builder/footer-web.png');
const FooterTab=require('../../../openbrix-design-system/images/tlyfe-pages/credit-builder/footer-tab.png');
const FooterMobile=require('../../../openbrix-design-system/images/tlyfe-pages/credit-builder/footer-mobile.png');


const CreditBuilderLanding = () => {
  const { colors } = useTheme();
  const layout = getScreenType();
  return (
    <><Wrapper
    theme={colors.transparency.yellow}
    drawerColor={colors.theme.yellow}
    type='credit'
    headerTitle={'Build your credit score with tlyfe'}
    headerBody={'Make your rent payments count towards improving your credit score.'}
    headerImage={layout == "web" ? HeaderWeb : layout == "tab" ? HeaderTab : HeaderMobile}
      rightImage={Picture}
      leftText={'Record your rental payments with tlyfe and have these reported to all major credit reference agencies, helping to improve your credit score and get you mortgage-ready!'}
      footerImage={layout == "web" ? FooterWeb : layout == "tab" ? FooterTab : FooterMobile}
    >
      <Body>
        <View style={{ padding: layout == "phone" ? 24 : 0 }}>
          <ODSText marginTop={layout == "phone" ? 16 : 0} type='h2-light' color={colors.text.primary}>How it works</ODSText>
          <View style={{ flexDirection: 'row', flexWrap: 'wrap', marginTop: layout == "web" ? 48 : 24, gap: layout == "web" ? 32 : 24 }}>
            <Content>
              <ODSText type="h4" color={colors.text.primary}>Pre-qualification</ODSText>
              <ODSText marginTop={20} type="h6-light" color={colors.text.primary}>Confirm your identity by answering a few quick questions- providing your tenancy details will allow future rent payments ta be reported to the three main credit agencies namely Experian, Equifax and TransUnion. </ODSText>
            </Content>
           {layout!='web' && <View style={{ width: '100%', height: 0.25, backgroundColor: colors.text.primary }} />}
            <Content>
              <ODSText type="h4" color={colors.text.primary}>Connect your bank for read-only access</ODSText>
              <ODSText marginTop={20} type="h6-light" color={colors.text.primary}>{`The rent reporting service is FCA regulated and the technology we use is supported by an all major banks.

Connect to your bank with confidence, thanks to high-level data encryption. `}</ODSText>

            </Content>
            {layout!='web' && <View style={{ width: '100%', height: 0.25, backgroundColor: colors.text.primary }} />}
          {layout=="web" &&  <View style={{ width: '100%', height: 0.25, backgroundColor: colors.text.primary }} />}
            <Content>
              <ODSText type="h4" color={colors.text.primary}>Connect your bank for read-only access</ODSText>
              <ODSText marginTop={20} type="h6-light" color={colors.text.primary}>{`The service will read and identify

rent payments through the secure access provided. Rent payments

will appear on statutory credit 

reports after 6-8 weeks. `}</ODSText>

            </Content>
            {layout!='web' && <View style={{ width: '100%', height: 0.25, backgroundColor: colors.text.primary }} />}
            <Content>
              <ODSText type="h4" color={colors.text.primary}>Supported banks include:</ODSText>
              <ODSText marginTop={20} type="h6-light" color={colors.text.primary}>Bank of Scotland, Barclays, Chelsea Building Society, Danske Bank, First Direct, Halifax, HSBC, Lloyds Bank, MBNA, Monzo, Nationwide, Natwest, Revolut, Royal Bank of Scotland, Santander, Starling Bank, Tesco Bank, Tide, Transferwise, TSB, Ulster Bank, Yorkshire Building Society.</ODSText>
            </Content>
            {layout!='web' && <View style={{ width: '100%', height: 0.25, backgroundColor: colors.text.primary }} />}
            {layout=="web" &&  <View style={{ width: '100%', height: 0.25, backgroundColor: colors.text.primary }} />}
          </View>
        </View>
        <View style={{ flexDirection: 'row', alignItems: 'center', alignSelf: 'center', gap: 32, marginBottom: 32 }}>
          <Image source={require('../../../openbrix-design-system/images/tlyfe-pages/credit-builder/equifax.png')} style={{ width: 129, height: 38, resizeMode: 'contain', marginTop: 20 }} />
          <Image source={require('../../../openbrix-design-system/images/tlyfe-pages/credit-builder/transunion.png')} style={{ width: 129, height: 38, resizeMode: 'contain' }} />
        </View>
      </Body>
      <View style={{width:'100%',height:layout=="phone"?252:190,backgroundColor:colors.elements.color,justifyContent:'center',alignItems:'center'}}>
        <ODSText type="h3" color={colors.text.inverted}>Download today!</ODSText>
        <View style={{ flexDirection: layout == "phone" ? 'column' : 'row', gap: 16, alignItems: 'center' ,marginTop:32}}>
        <TouchableOpacity onPress={() => window.open('https://apps.apple.com/in/app/tlyfe/id6449941281')}>
              <Image source={require('../../../openbrix-design-system/images/appstore.png')} style={{ width: 180, height: 52 }} />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => window.open('https://play.google.com/store/apps/details?id=com.tlyfe&hl=en_IN&gl=US')}>
              <Image source={require('../../../openbrix-design-system/images/playstore.png')} style={{ width: 180, height: 52 }} />
              </TouchableOpacity>
            </View>
      </View>

    </Wrapper>
</>
  )
}
export default CreditBuilderLanding;