import React, { useEffect, useState } from 'react';
import Loader from './../../commonComponents/activityIndicator/activityIndicator';
import { Dimensions, Image, Text, TouchableOpacity, View } from 'react-native';
import { Entypo } from '@expo/vector-icons';
import { useDispatch, useSelector } from 'react-redux';
import { showToaster } from '../../redux/actions/toast';
import {
  ButtonLinkBox,
  ButtonLinkText,
  ButtonLinkView,
  FormView,
  WideButtonView,
  WideButtonWrapper,
} from './myAccountStyles';
import WideButton from '../../commonComponents/wideButton';
import CustomTextInput from '../../commonComponents/customTextInput/customTextInput';
import DropDownPicker from '../../commonComponents/DropDownPicker';
import OpenMarket from './openMarket';
import { LogoutApi, getProfile, referFriend, updateProfile } from '../../redux/actions/auth';
import getPlatformType from '../../helpers/getPlatform';
import { EmailValidation, MobileValidation } from '../../helpers/validation';
import NotificationList from '../notificationSettings/NotificationList';
import { getDigitalVerificationStatus } from '../openFlow/helpers';
import { COLORS } from '../../constants/colorPallete';
import BackGroundGenerator from '../LandingPages/common/BackGroundGenerator';
import { BoxShadow, TabContainer, Wrapper } from '../idVerification/styles';
import { LinearGradient } from 'expo-linear-gradient';
import { openFlow } from '../../constants/openFlo';
import CustomPopUp from '../../commonComponents/customPopUp/customPopUp';
import { navigateAction } from '../../helpers/navigationHelpers';
import { Typography } from '../openFlow/style';
import { validateEmail } from '../../helpers/regex';
import ShareMedia from '../../commonComponents/shareMedia/shareMedia';
import { ShareOptions } from '../propertyDetails/styles';

const layoutType = getPlatformType();
const WIN_HEIGHT = Dimensions.get('window').height;
const openMarketData = [
  {
    image: require('../../assets/SVG/prop-saved.svg'),
    name: 'Saved Properties',
    external: true,
    external_url: 'Saved Properties',
    id: 'SAVED-PROPS',
  },
  {
    image: require('../../assets/SVG/prop-alerts.svg'),
    name: 'Properties Alert',
    external: true,
    external_url: 'My Alerts',
    id: 'MY-ALERTS',
  },
];
const openMarketDetailData = [
  {
    logo: require('../../assets/images/creditScore.png'),
    name: 'Company Name',
    prduct_name: 'Product Name',
  },
  {
    logo: require('../../assets/images/creditScore.png'),
    name: 'Company Name',
    prduct_name: 'Product Name',
  },
  {
    logo: require('../../assets/images/creditScore.png'),
    name: 'Company Name',
    prduct_name: 'Product Name',
  },
];
const MyAccount = (props) => {
  const dispatch = useDispatch();
  const [deletePopUpIsVisible, setDeletePopUpIsVisible] = useState(false);
  const [showShare,setShowShare]=useState(false);

  const [state, setState] = React.useState({
    currentTab: 'tab1',
    firstName: '',
    lastName: '',
    contact: '',
    email: '',
    user_id: '',
    idverificationStatus: '',
    yoti_reason: '',
    opbrix_id: '',
    TextInputDisableHolder: true,
    prevProfile: null,
    showDetails: false,
    contactError: '',
    isTenantOrBuyer: '',
    showLoading: false,
  });
  const [referalState, setreferalState] = React.useState({
email:"",
showError:false
  });
  const [selectedIndex, setSelectedIndex] = useState(0);

  const TabView = () => {
    return (
      <LinearGradient
        colors={[COLORS.GREEN, COLORS.NGREEN]}
        start={{
          x: 0,
          y: 1,
        }}
        end={{
          x: 1,
          y: 1,
        }}
        style={{
          height: '100%',
          width: '100%',
        }}
      >
        <TabContainer style={{ backgroundColor: 'transparent' }}>
          <TouchableOpacity
            id={'AccountTab'}
            onPress={() => setSelectedIndex(0)}
            style={{
              flex: 1 / 3,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Text
              style={{
                fontSize: layoutType == 'phone' ? 14 : 18,
                color: 'white',
                fontFamily: selectedIndex == 0 ? 'Lato-Bold' : 'Lato',
              }}
            >
              Account
            </Text>
            {selectedIndex == 0 && (
              <View
                style={{
                  width: layoutType == 'phone' ? '85%' : '30%',
                  height: 2,
                  backgroundColor: 'white',
                  marginTop: 8,
                }}
              />
            )}
          </TouchableOpacity>
          <TouchableOpacity
            id={'Notification'}
            onPress={() => setSelectedIndex(1)}
            style={{
              flex: 1 / 3,
              justifyContent: 'center',
              alignItems: 'center',
              borderLeftWidth: 1,
              borderRightWidth: 1,
              borderColor: layoutType == 'phone' ? 'white' : 'white',
            }}
          >
            <Text
              style={{
                fontSize: layoutType == 'phone' ? 14 : 18,
                color: 'white',
                fontFamily: selectedIndex == 1 ? 'Lato-Bold' : 'Lato',
              }}
            >
              My Invoices
            </Text>
            {selectedIndex == 1 && (
              <View
                style={{
                  width: layoutType == 'phone' ? '85%' : '30%',
                  height: 2,
                  backgroundColor: 'white',
                  marginTop: 8,
                }}
              />
            )}
          </TouchableOpacity>
          <TouchableOpacity
            id={'Myproperties'}
            onPress={() => setSelectedIndex(2)}
            style={{
              flex: 1 / 3,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Text
              style={{
                fontSize: layoutType == 'phone' ? 14 : 18,
                color: 'white',
                fontFamily: selectedIndex == 2 ? 'Lato-Bold' : 'Lato',
              }}
            >
              My Properties
            </Text>
            {selectedIndex == 2 && (
              <View
                style={{
                  width: layoutType == 'phone' ? '85%' : '30%',
                  height: 2,
                  backgroundColor: 'white',
                  marginTop: 8,
                }}
              />
            )}
          </TouchableOpacity>
        </TabContainer>
      </LinearGradient>
    );
  };
  const profileLoading = useSelector((state) => state.authReducer.profileLoading);
  const profile = useSelector((state) => state.authReducer.profile);

  useEffect(() => {
    dispatch(getProfile({}, props.navigation.navigate));
  }, []);
  useEffect(() => {
    if (profile != undefined) {
      setState({
        ...state,
        firstName: profile.data.first_name,
        lastName: profile.data.last_name,
        contact: profile.data.contact_number,
        email: profile.data.email,
        idverificationStatus: profile?.data?.appUser?.idverification,
        yoti_reason: profile.data?.appUser?.yoti_reason,
        opbrix_id: profile?.data?.appUser?.opbrix_id,
        user_id: profile.data.user_id,
        title: profile?.data?.title,
      });
    }
  }, [profile]);

  const validateForm = () => {
    const { firstName, lastName, contact, user_id, title } = state;
    let phoneCheck = contact?MobileValidation(contact):{status:true};

    if (phoneCheck.status) {
      setState({
        ...state,
        TextInputDisableHolder: !state.TextInputDisableHolder,
      });
      dispatch(
        updateProfile({
          user: {
            first_name: firstName,
            last_name: lastName,
            contact_number: contact,
            user_id,
            user_type: 0,
            title: title,
          },
        })
      );
    } else {
      setState({
        ...state,
        contactError: phoneCheck.msg,
      });
      dispatch(showToaster('error', phoneCheck.msg));
    }
  };

  const toggleEditState = () => {
    validateForm();
  };
  const tab1 = () => {
    return (
      <>
        <Wrapper>
          <View style={{ flexDirection: 'column' }}>
            <BoxShadow wWidth={'344px'} bottom={'10px'} right={'19px'}>
              <View
                style={{
                  width: layoutType != 'web' ? 140 : 170,
                  height: layoutType != 'web' ? 140 : 170,
                  borderRadius: layoutType != 'web' ? 70 : 85,
                  backgroundColor: 'transparent',
                  justifyContent: 'center',
                  alignItems: 'center',
                  alignSelf: 'center',
                }}
              >
                <Image
                  source={require('./../../assets/images/user.png')}
                  style={{
                    width: layoutType != 'web' ? 100 : 130,
                    height: layoutType != 'web' ? 100 : 130,
                    borderRadius: layoutType != 'web' ? 50 : 65,

                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                ></Image>
              </View>
              <View style={{ width: '90%' }}>
                <Text>Tlyfe ID</Text>
                <View
                  style={{
                    height: 30,
                    width: '100%',
                    marginTop: 10,
                    borderColor: '#D0D0D0',
                    borderWidth: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 5,
                  }}
                >
                  <Text
                    id="myAccountTlyfeId"
                    style={{
                      fontSize: 19.5,
                      lineHeight: 23,
                      color: COLORS.LGREEN,
                    }}
                  >
                    {state.opbrix_id}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  marginTop: 15,
                  width: '90%',
                }}
              >
                <Text>ID Status</Text>
                <View
                  style={{
                    height: 30,
                    width: '100%',
                    marginTop: 10,
                    borderColor: '#D0D0D0',
                    borderWidth: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 5,
                  }}
                >
                  <Text
                    id="MY-ACC-ID-STATUS"
                    style={{
                      fontSize: 19.5,
                      lineHeight: 23,
                      color: getDigitalVerificationStatus(state.idverificationStatus).color,
                    }}
                  >
                    {getDigitalVerificationStatus(state.idverificationStatus).StatusText}
                  </Text>
                </View>
              </View>
            </BoxShadow>
            <BoxShadow
              style={{ marginTop: 10, padding: 0 }}
              wWidth={'344px'}
              top={'10px'}
              right={'19px'}
            >
              <View
                style={{
                  height: 72,
                  width: '100%',
                  borderTopEndRadius: 8,
                  borderTopStartRadius: 8,
                  backgroundColor: COLORS.LGREEN,
                }}
              >
                <Typography
                  top={'13px'}
                  align={'left'}
                  size={'24px'}
                  bold={'900'}
                  color={'white'}
                  left={'16px'}
                >
                  Refer a friend
                </Typography>
              </View>
              <View style={{ padding: 16 }}>
                <Typography
                  align={'justify'}
                  bottom={'18px'}
                  size={'14px'}
                  bold={'400'}
                  color={COLORS.BLACK}
                >
                  Love tlyfe? Why not refer a friend so they can enjoy the benefits of tlyfe.{' '}
                </Typography>
                <CustomTextInput
                  id="myAccountEmail"
                  width="100%"
                  title="Email"
                  showError={referalState.showError}
                  errorMessage={"Invalid email"}
                  onChangeText={(email) =>
                    setreferalState({
                      ...referalState,
                      email,
                    })
                  }
                  name="email"
                  prefilledValue={referalState?.email}
                />
                <View style={{ width: '100%',justifyContent:'space-between',marginTop:31 ,flexDirection:'row'}}>

                <TouchableOpacity onPress={()=>setShowShare(!showShare)} style={{ flexDirection: 'row', alignItems: 'center', borderWidth: 0.5, borderRadius: 10, padding: 5,marginRight:15 }} >
             <Text> Share</Text>
              <Image
                source={require('../../assets/images/share.png')}
                resizeMode="contain"
                style={{ width: 22, height: 22, marginLeft: 10 }}
              />
            </TouchableOpacity>
          
                  <WideButton
                    id={'Refer-submit'}
                    gradiant
                    isLGreen
                    onPress={() => {
                      if(EmailValidation(referalState?.email).status){
                        if(referalState?.email){
                          setreferalState({
                            ...referalState,
                            showError:false,
                          })
                          dispatch(referFriend({email:referalState?.email },()=>{
                            setreferalState({
                              email:"",
                              showError:false
                            })
                          }))
                        }else{
                          setreferalState({
                            ...referalState,
                            email:'',
                            showError:true,
                          })
                        }
                      }else{
                        dispatch(showToaster("error", EmailValidation(referalState?.email).msg))
                      }
                    
                     
                    }}
                    width={120}
                    borderRadius={15}
                    childText={'Submit'}
                  />
                </View>
               {showShare && <ShareMedia
                              // shareUrl={`Refer a friend https://play.google.com/store/apps/details?id=com.tlyfe&hl=en&gl=US`}
                              shareUrl={`Refer a Friend\nCheck it out!\n\nhttps://play.google.com/store/apps/details?id=com.tlyfe&hl=en&gl=US`}

                              emailSubject={
                                "Refer a friend"
                              }
                              emailBody={`Refer a friend https://play.google.com/store/apps/details?id=com.tlyfe&hl=en&gl=US`}
                            />}
              </View>
            </BoxShadow>
          </View>
          <BoxShadow wWidth={'344px'}>
            <View
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                padding: '2%',
              }}
            >
              <View
                style={{
                  width: '100%',
                  marginBottom: 10,
                }}
              >
                <Text
                  style={{
                    fontSize: 18,
                    color: 'black',
                    fontWeight: 'bold',
                    lineHeight: 18,
                  }}
                >
                  Title
                </Text>
                <DropDownPicker
                  items={openFlow?.titles.map((salutation) => {
                    return {
                      value: salutation,
                      label: salutation,
                    };
                  })}
                  id="openflo-Title"
                  placeholder="Title"
                  defaultValue={state?.title}
                  onChangeItem={(e) => {
                    setState({
                      ...state,
                      title: e?.value,
                    });
                  }}
                  dropDownStyle={{ backgroundColor: 'white' }}
                  zIndex={1001}
                  disabled={state.TextInputDisableHolder}
                />
              </View>
              <CustomTextInput
                id="myAccountFirstName"
                width="100%"
                title="First Name"
                type="text"
                onChangeText={(firstName) =>
                  setState({
                    ...state,
                    firstName,
                  })
                }
                name="firstName"
                prefilledValue={state.firstName}
                errorMessage={''}
                disabled={state.idverificationStatus === 2 || state.TextInputDisableHolder}
              />
              <CustomTextInput
                id="myAccountLastName"
                width="100%"
                title="Last Name"
                type="text"
                onChangeText={(lastName) =>
                  setState({
                    ...state,
                    lastName,
                  })
                }
                name="lastName"
                prefilledValue={state.lastName}
                errorMessage={''}
                disabled={state.idverificationStatus === 2 || state.TextInputDisableHolder}
              />
              <CustomTextInput
                id="myAccountContactNumber"
                width="100%"
                title="Contact Number"
                type="number"
                keyboardType="numberic"
                onChangeText={(contact) =>
                  setState({
                    ...state,
                    contact: contact,
                    contactError: '',
                  })
                }
                name="contact"
                prefilledValue={state.contact}
                showError={state.contactError == '' ? false : true}
                errorMessage={state.contactError}
                disabled={state.TextInputDisableHolder}
              />
              <CustomTextInput
                id="myAccountEmail"
                width="100%"
                title="Email"
                onChangeText={(email) =>
                  setState({
                    ...state,
                    email,
                  })
                }
                disabled={true}
                name="email"
                prefilledValue={state.email}
                showError={state.email ? false : true}
                errorMessage=""
              />
            </View>
          </BoxShadow>
        </Wrapper>
        {tab1Footer()}
      </>
    );
  };
  const tab2 = (
    <>
      <BoxShadow
        tWidth={'80%'}
        wWidth={'80%'}
        style={{
          marginTop: 20,
          alignSelf: 'center',
        }}
      >
        <FormView>
          <NotificationList />
        </FormView>
      </BoxShadow>
     
    </>
  );

  const tab1Footer = () => {
    return (
      <WideButtonView>
        <WideButtonWrapper>
          <WideButton
            id={'EDIT-ACCOUNT'}
            gradiant
            isLGreen
            onPress={() => {
              state.TextInputDisableHolder
                ? setState(
                    {
                      ...state,
                      showLoading: true,
                      TextInputDisableHolder: !state.TextInputDisableHolder,
                    },
                    () =>
                      setTimeout(() => {
                        setState({
                          ...state,
                          showLoading: false,
                        });
                      }, 800)
                  )
                : toggleEditState();
            }}
            borderRadius={15}
            childText={state.TextInputDisableHolder ? 'Edit' : 'Save'}
          />
        </WideButtonWrapper>
        <WideButtonWrapper>
          <WideButton
            onPress={() => props.navigation.navigate('Change Password')}
            borderRadius={15}
            buttonType={'stripe'}
            backgroundColor={COLORS.LGREEN}
            childText="Change Password"
            whiteButton={true}
            styles={{ marginTop: '2%' }}
            id={'CHNAGE-PASSS'}
          />
        </WideButtonWrapper>
        <WideButtonWrapper>
          <WideButton
            gradiant
            isLGreen
            onPress={() => {
              setDeletePopUpIsVisible(true);
            }}
            borderRadius={15}
            childText={'Delete Account'}
          />
        </WideButtonWrapper>
        <ButtonLinkView>
        <TouchableOpacity style={{ flex: 1 }}>
          <ButtonLinkBox
            id={'ARCHIVE-NOTIFICATION'}
            onPress={() =>
              props.navigation.navigate('Notification Settings', {
                property_notification: profile?.data?.appUser?.property_notification,
                marketplace_notification: profile?.data?.appUser?.property_notification,
                maintenance_notification: profile?.data?.appUser?.maintenance_notification,
                rental_notification: profile?.data?.appUser?.rental_notification,
                openflow_notification: profile?.data?.appUser?.openflow_notification,
              })
            }
          >
            <ButtonLinkText id={'ARCHIVEDNOTIFICATION_LINK'}>
              Archived Notifications & Settings
            </ButtonLinkText>
            <Entypo name="chevron-right" size={17} color={COLORS.orange} />
          </ButtonLinkBox>
        </TouchableOpacity>
      </ButtonLinkView>
      </WideButtonView>
    );
  };

  return (
    <BackGroundGenerator props={props} isTLYF>
      <Loader loading={profileLoading} />
      <View style={{ minHeight: WIN_HEIGHT - 300 }}>
        <TabView />
        <View
          style={{
            width: '100%',
            marginVertical: 30,
          }}
        >
          {selectedIndex == 0 && tab1()}
          {selectedIndex == 1 && tab2}
          {selectedIndex == 2 && (
            <BoxShadow
              wWidth={'80%'}
              tWidth={'80%'}
              style={{
                marginTop: 20,
                alignSelf: 'center',
              }}
            >
              <OpenMarket
                openMarketDetailData={openMarketDetailData}
                openMarketData={openMarketData}
                navigation={props.navigation}
              />
            </BoxShadow>
          )}
        </View>
      </View>
      <CustomPopUp
        loading={deletePopUpIsVisible}
        primaryButtonText={'Sure'}
        secondaryButtonText={'cancel'}
        primaryText={'Delete Account?'}
        secondaryText={' Are you sure you want to delete your account?'}
        onPrimaryButtonPress={() => {
          setDeletePopUpIsVisible(false);
         dispatch(LogoutApi(true, props.navigation));
        }}
        onSecondaryButtonPress={() => {
          setDeletePopUpIsVisible(false);
        }}
      />

    </BackGroundGenerator>
  );
};
export default MyAccount;
