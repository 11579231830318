import React, { useState, useEffect } from 'react';
import Modal  from "react-native-modal";
import { useDispatch, useSelector } from 'react-redux';
import { useIsFocused } from '@react-navigation/native';
import { View, ImageBackground, Text } from 'react-native';

import { tdsActions } from './tdsRedux/tds.actions';
import { backgroundTdsArr } from './backgroundTdsArr';
import getPlatformType from '../../helpers/getPlatform';
import { navigateAction } from '../../helpers/navigationHelpers';
import { COLORS, generalColorsTheme } from './../../constants/colorPallete';
import BackGroundGenerator from '../LandingPages/common/BackGroundGenerator';
import { TabHeader, ActivateButtonWrapper, Container, ButtonWrapper, DownloadButtonWrapper, TabsWrapper, Typography, TabBody, BoxContainer } from './style';

const custodialDashboard = (props) => {

  let focus = useIsFocused();
  const dispatch = useDispatch();
  const layoutType = getPlatformType();
  const [type, setType] = useState('web');
  const [account, setAccount] = useState({});
  const [tenancy, setTenancy] = useState({});
  const [leadTenant, setLeadTenant] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [otherTenants, setOtherTenants] = useState([]);
  const [activeTab, setActiveTab] = useState('Tenants');
  const tdsAccount = useSelector((state) => state.tds?.tdsAccount);
  const tenancyResponse = useSelector((state) => state.tds?.tenancy);
  const depositResponse = useSelector((state) => state.tds?.depositCertificate);
  const activeCaseStatus = [
    "Cancelled",
    "Cancel - tenancy not ended",
    "Tenant changeover rejected",
    "Tenant changeover cancelled",
    "Deposit Amendment Completed",
    "Deposit Amendment Requested",
    "Tenant changeover completed",
    "User-to-user transfer rejected",
    "User-to-user transfer completed",
    "Deposit Amendment - Deposit Split confirmed",
    "Deposit Amendment - Awaiting confirmation of payment split",
  ]

  const respondStatus = [
    "Repayment requested - agent/landlord",
    "Repayment requested - no response from lead tenant",
    "Repayment requested - awaiting response to statutory declaration",
    "Repayment requested - statutory declaration received",
    "Repayment requested - statutory declaration required"
  ]

  useEffect(() => {
    dispatch(tdsActions.getTDSAccount());
    dispatch(tdsActions.getTenancy(props.route.params.tenancy_id, props.route.params.dan));
  }, [focus]);

  useEffect(() => {
    if (tdsAccount) {
      setAccount(tdsAccount);
    }
  }, [tdsAccount]);

  const isBankAccountAdded = () => {
    return account?.person?.bank_account_number && account?.person?.bank_sort_code;
  }

  const handleViewClick = () => {
    if(isBankAccountAdded()) {
      navigateAction(props.navigation, 'View Custodial Repayment', { case_id: tenancy?.tenancy?.case_id, tenancy_id: tenancy?.tenancy?.tenancy_id, dan: tenancy?.tenancy?.dan })
    } else {
      setShowModal(true)
    }
  }

  const handleRespondClick = () => {
    if(isBankAccountAdded()) {
      navigateAction(props.navigation, 'Custodial Respond', { case_id: tenancy?.tenancy?.case_id, tenancy_id: tenancy?.tenancy?.tenancy_id, dan: tenancy?.tenancy?.dan })
    } else {
      setShowModal(true)
    }
  }

  const handleClick = () => {
    if(isBankAccountAdded()) {
      navigateAction(props.navigation, 'Custodial Repayment', { case_id: tenancy?.tenancy?.case_id, tenancy_id: tenancy?.tenancy?.tenancy_id, dan: tenancy?.tenancy?.dan })
    } else {
      setShowModal(true)
    }
  }

  const isAllowedToRaiseInitialRepaymentRequest = () => {

    return tenancy?.tenancy?.status == "Deposit Held" &&
      tenancy?.tenancy?.gross_amount == 0 &&
      tenancy?.tenancy?.tenant_classification == "Lead Tenant" &&
      tenancy?.tenancy?.case_id == 0 &&
      !activeCaseStatus.includes(tenancy?.tenancy?.case_status) &&
      account?.person?.requestrepaymentcustodialpermission
  }

  const isAllowedToRespondToMemberRepaymentRequest = () => {
    return tenancy?.tenancy?.status == "Deposit Held" &&
      tenancy?.tenancy?.tenant_classification == "Lead Tenant" &&
      !tenancy?.tenancy?.raised_by_tenant &&
      respondStatus.includes(tenancy?.tenancy?.case_status) &&
      account?.person?.respondtorepaymentrequestpermission
  }

  const isAllowedToViewRepaymentRequest = () => {
    return tenancy?.tenancy?.case_id != 0 && !isAllowedToRespondToMemberRepaymentRequest() && !isAllowedToRaiseInitialRepaymentRequest()
  }

  useEffect(() => {
    if(tenancyResponse) {
      setTenancy(tenancyResponse);
      let leadTenant = {};
      let otherTenants = []
      tenancyResponse?.tenants.map(tenant=>{
        if(tenant.tenant_classification == "Lead Tenant") {
          leadTenant = tenant 
        } else {
          otherTenants.push(tenant);
        }
      });
      setLeadTenant(leadTenant);
      setOtherTenants(otherTenants);
    }
  }, [tenancyResponse]);


  const renderPropertySection = () => {
    return (
      <View style={{ width: '100%', padding: 43 }}>
        <Typography align={'left'} color={'#194592'} size={'27px'} bold={700}>
          Address
        </Typography>
        <Typography align={'left'} top={'33px'} color={'#000000'}  bold={500}>
          { tenancy?.property.organisationname }
        </Typography>
        <Typography align={'left'} color={'#000000'}  bold={500}>
          { tenancy?.property.addresscity }, { tenancy?.property.addresscounty }
        </Typography>
        <Typography align={'left'} top={'20px'} color={'#000000'}  bold={500}>
          { tenancy?.property.addresspostcode }, { tenancy?.property.addresscountry }
        </Typography>
      </View>
    );
  };

  const renderLandLordSection = () => {
    return (
      <View style={{ width: '100%',padding:43 }}>
        {/* <TabHeader left={'40px'} align={'flex-start'}> */}
          <Typography align={'left'} color={'#194592'} size={'27px'} bold={700}>
            Name
          </Typography>
          <Typography
            // bottom={'50px'}
            align={'left'}
            top={'33px'}
            color={'#000000'}
            
            bold={500}
          >
            {tenancy?.property?.membername}
          </Typography>
        {/* </TabHeader> */}
        {/* <TabHeader left={'40px'} align={'flex-start'} hideBoderLeft>
          <Typography top={'43px'} align={'left'} color={'#194592'} size={'27px'} bold={700}>
            Address
          </Typography>
          <Typography align={'left'} top={'33px'} color={'#000000'}  bold={500}>
          </Typography>
          <Typography align={'left'} color={'#000000'}  bold={500}>
          </Typography>
          <Typography
            bottom={'50px'}
            align={'left'}
            top={'20px'}
            color={'#000000'}
            
            bold={500}
          >
          </Typography>
        </TabHeader> */}
      </View>
    );
  };

  const renderTeanantSection = () => {
    return (
      <View style={{ width: '100%', flexDirection: 'row' }}>
        <TabHeader left={'40px'} align={'flex-start'}>
          <Typography top={'43px'} align={'left'} color={'#194592'} size={'27px'} bold={700}>
            Lead
          </Typography>
          <Typography
            bottom={'50px'}
            align={'left'}
            top={'33px'}
            color={'#000000'}
            
            bold={500}
          >
            {leadTenant?.fullname}
          </Typography>
        </TabHeader>
        <TabHeader left={'40px'} align={'flex-start'} hideBoderLeft>
          <Typography top={'43px'} align={'left'} color={'#194592'} size={'27px'} bold={700}>
            Additional tenants
          </Typography>
          {otherTenants.map(otherTenant=>{
            return (<Typography
              bottom={'50px'}
              align={'left'}
              top={'33px'}
              color={'#000000'}
              
              bold={500}
            >
              Name: {otherTenant?.fullname}
            </Typography>)
          })}
        </TabHeader>
      </View>
    );
  };

  const renderDetailsTabs = () => {
    return (
      <TabsWrapper>
        <View
          style={{
            flexDirection: 'row',
            width: '100%',
            height: 94,
            borderBottomWidth: 1,
            borderBottomColor: generalColorsTheme.active,
          }}
        >
          <TabHeader id={"TDS-CUSTO-TAB-TENANT"}  onPress={() => setActiveTab('Tenants')}>
            <Typography
              color={
                activeTab === 'Tenants' ? generalColorsTheme.active : generalColorsTheme.inactive
              }
              size={'23px'}
              bold={700}
            >
              Tenants
            </Typography>
          </TabHeader>
          <TabHeader id={"TDS-CUSTO-TAB-PROPERTY"} onPress={() => setActiveTab('Property')}>
            <Typography
              color={
                activeTab === 'Property' ? generalColorsTheme.active : generalColorsTheme.inactive
              }
              size={'23px'}
              bold={700}
            >
              Property
            </Typography>
          </TabHeader>
          <TabHeader id={"TDS-CUSTO-TAB-LANDLORD"} onPress={() => setActiveTab('Landlord')} hideBoderLeft>
            <Typography
              color={
                activeTab === 'Landlord' ? generalColorsTheme.active : generalColorsTheme.inactive
              }
              size={'23px'}
              bold={700}
            >
              Landlord
            </Typography>
          </TabHeader>
        </View>
        <TabBody>
          {activeTab === 'Tenants' ? renderTeanantSection() : null}
          {activeTab === 'Property' ? renderPropertySection() : null}
          {activeTab === 'Landlord' ? renderLandLordSection() : null}
        </TabBody>
      </TabsWrapper>
    );
  };

  const renderCertificateSection = () => {
    return (
      <ImageBackground
        source={require('./web/DepositImage.png')}
        style={{
          height: 274,
          width: "100%",
          borderRadius: 10,
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: 23,
        }}
      >
        <Typography color={'#FFFFFF'} size={'35px'} bold={800}>
          Deposit Certificate{' '}
        </Typography>
        <DownloadButtonWrapper 
        id={"TDS-CERTIFICATE-DOWNLOAD"}
        onPress={() => dispatch(tdsActions.downloadTenancyCertificate(tenancy?.tenancy?.dpc_id))}>
          <Typography color={'#4C664B'} size={'19px'} bold={800}>
            DOWNLOAD
          </Typography>
        </DownloadButtonWrapper>
      </ImageBackground>
    );
  };


  const renderButtonSection = () => {
    return (
      <>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between',marginTop:30 }}>
        { isAllowedToRaiseInitialRepaymentRequest() ? 
          <ButtonWrapper 
            style={{ align: 'center' }}
            onPress={handleClick}
          >
            <Typography color={'#FFFFFF'} size={'19px'} bold={700}>
              REQUEST REPAYMENT OF DEPOSIT
            </Typography>
          </ButtonWrapper> 
        : null }

        { isAllowedToRespondToMemberRepaymentRequest() ? 
          <ButtonWrapper 
            style={{ align: 'center' }}
            onPress={handleRespondClick}
            color={'#F99595'}
            bcolor={'#F99595'}

          >
            <Typography color={'#FFFFFF'} size={'19px'} bold={700}>
              RESPOND TO REPAYMENT REQUEST
            </Typography>
          </ButtonWrapper> 
        : null }

        { isAllowedToViewRepaymentRequest() ? 
          <ButtonWrapper 
            style={{ align: 'center' }}
            onPress={handleViewClick}
            id={"VIEW-REPAYMENT-DEPOSIT"}
            bcolor={COLORS.LGREEN}
            color={COLORS.LGREEN}
          >
            <Typography 
                  id={"VIEW-REPAYMENT-DEPOSIT-TEXT"}
            color={"white"} size={'19px'} bold={700}>
              VIEW REPAYMENT OF DEPOSIT
            </Typography>
          </ButtonWrapper> 
        : null }

      </View>
      </>
    );
  };

  const renderBoxSection = () => {
    return (
      <View style={{ width: '100%', flexDirection: 'row',flexWrap:'wrap' ,marginTop: 20 ,justifyContent:'center'}}>
        <BoxContainer  bottom={"30px"} style={{ marginRight:layoutType=="phone"?0:8,width:layoutType=="phone"?'100%':'45%' }}>
          <Typography
          id={"TDS-DEPOSIT-INFO"}
          color={'#194592'}   bold={700}>
            Deposit Info
          </Typography>
          { tenancy?.tenancy?.case_status ? 
            <Typography top={8} color={'red'} size={'18px'} bold={400}>
              {tenancy?.tenancy?.case_status}
            </Typography>
          : null }
          <Typography align={'left'} top={'29px'} color={'#194592'}  bold={600}>
            Certificate Number:
          </Typography>
          <Typography align={'left'} color={'#000000'} top={5}  bold={500}>
            { tenancy?.tenancy?.dan }
          </Typography>
          <Typography align={'left'} top={'29px'} color={'#194592'}  bold={600}>
            Tenant Type:
          </Typography>
          <Typography align={'left'} color={'#000000'} top={5} bold={500}>
          { tenancy?.tenancy?.tenant_classification }
          </Typography>
          <Typography align={'left'} top={'29px'} color={'#194592'}  bold={600}>
            Tenant Start Date:
          </Typography>
          <Typography align={'left'} color={'#000000'} top={5} bold={500}>
            { tenancy?.tenancy?.tenancy_start_date }
          </Typography>
          <Typography align={'left'} top={'29px'} color={'#194592'}  bold={600}>
            Deposit Amount Protected:
          </Typography>
          <Typography align={'left'} color={'#000000'} top={5} bold={500}>
            £{ tenancy?.tenancy?.deposit_amount }
          </Typography>
          <Typography align={'left'} top={'29px'} color={'#194592'}  bold={600}>
            Deposit Amount Stated in Agreement:
          </Typography>
          <Typography align={'left'} color={'#000000'} top={5} bold={500}>
            £{ tenancy?.tenancy?.protected_amount }
          </Typography>
        </BoxContainer>
        <BoxContainer style={{ marginLeft:layoutType!="phone"?8:0,marginTop:layoutType=="phone"?10:0,width:layoutType=="phone"?'100%':'45%' }}>
          <Typography top={'55px'} color={'#0000002'} size={'23px'} bold={700}>
            Improve your credit history?
          </Typography>
          <Typography top={'45px'} color={'#454545'}  bold={400}>
            We’re pleased to confirm your deposit is protected, but did you know your rent payments
            can also be used to improve your credit position?
          </Typography>
          <Typography bottom={'30px'} top={'50px'} color={'#454545'}  bold={400}>
            Find out how by clicking the button below
          </Typography>
          <ButtonWrapper 
          id={"TDS-GET-STARTED"}
          style={{ width: '100%' }} onPress={() => navigateAction(props.navigation, 'Credit Builder')}>
            <Typography 
               id={"TDS-GET-STARTED-TEXT"}
            color={'#FFFFFF'} size={'19px'} bold={700}>
              GET STARTED
            </Typography>
          </ButtonWrapper>
        </BoxContainer>
      </View>
    );
  };

  return (
    <>
      <BackGroundGenerator
        isBack={true}
        height={'200px'}
        infoIcon
        isTds
        props={props}
        redirectTDSAccount={()=> { navigateAction(props.navigation, 'Custodial Account') }}
        tdsAccountIcon
        layoutType={(data) => { setType(data) }}
        isTLYF
        backgroundArr={backgroundTdsArr}
        backNavigation={()=> navigateAction(props.navigation, 'Custodial Tenancies') }
      >
        <Container>
          { tenancy?.tenancy?.dan ?
            <>
              {renderBoxSection()}
              {renderButtonSection()}
              {renderCertificateSection()}
              {renderDetailsTabs()}
            </>
            : null
          }
        </Container>
        <Modal 
          isVisible={showModal}
          onBackdropPress={() => setShowModal(false)}
          transparent={true} 
          coverScreen={false} 
          hasBackdrop={false} 
          backdropOpacity={0} 
          style={{ 
            margin: 0, 
            width: '100%', 
            flex: 1, 
            backgroundColor: '#00000080', 
            paddingVertical: layoutType == 'phone' ? '5%' : '1%', 
            paddingHorizontal: layoutType == 'phone' ? '8%' : '3%', 
          }} 
        > 
        <View 
          style={{ 
            width: layoutType == 'phone' ? '365px' : '500px', 
            backgroundColor: '#FAFAFA', 
            borderRadius: 20, 
            overflow: 'hidden', 
            alignSelf: 'center', 
            padding: layoutType == 'phone' ? '5%' : '2%', 
          }} 
        > 

          <Typography color={'#4C664B'}  bold={400}> 
            Please add Bank Account first
          </Typography> 
          <ButtonWrapper 
            color={'#638ACC'} 
            width={'100%'} 
            style={{ marginTop: 30 }} 
            onPress={() => { 
              setShowModal(false);
              navigateAction(props.navigation, 'Custodial Account')
            }} 
          > 
            <Typography color={'#FFFFFF'} size={'19px'} bold={700}> 
              Add Bank Account 
            </Typography> 
          </ButtonWrapper> 
        </View> 
      </Modal> 

      </BackGroundGenerator>
    </>
  );
};

export default custodialDashboard;
