import { Text, View } from "react-native";
import React, { useEffect, useState } from "react";
import { Hoverable } from "react-native-web-hooks";
import { useDispatch, useSelector } from "react-redux";

import { backgroundTdsArr } from "./backgroundTdsArr";
import { navigateAction } from "../../helpers/navigationHelpers";
import BackGroundGenerator from "../LandingPages/common/BackGroundGenerator";
import CustomTextInput from "../../commonComponents/customTextInput/customTextInput";
import { ButtonWrapper, Container, primaryColor, Section, Typography } from "./style";

const insuredDeposit = (props) => {

  const { navigation } = props;
  const dispatch = useDispatch();
  const [type, setType] = useState("web");
  const [hoverStartTime, setHoverStartTime] = useState(null);
  const searchDepositResponse = useSelector((state) => state.tds?.searchedDeposit);
  const userId = useSelector((state) => state.authReducer?.profile?.data?.appUser?.app_user_id);

  useEffect(() => {
    if (searchDepositResponse) {
      if (searchDepositResponse.scheme !== "custodial") {
        console.log("searchDepositResponsesearchDepositResponse", searchDepositResponse);
      }
    }
  }, [searchDepositResponse]);

  //commented out until tom or someone else can finish the backend for this
  const handleHoverIn = () => {
    // setHoverStartTime(Date.now());
  };

  const handleHoverOut = (type) => {
    //if (hoverStartTime) {
    //  const duration = (Date.now() - hoverStartTime) / 1000.0;
    //  dispatch(
    //    appUserTrackingProductHover({
    //      app_user_id: userId,
    //      product: type,
    //      hover_time_seconds: duration,
    //      current_page: navigationRef.current.getCurrentRoute().name,
    //    })
    //  );
    //}
    //setHoverStartTime(null);
  };

  return (
    <>
      <BackGroundGenerator
        isTds
        props={props}
        layoutType={(data) => {
          setType(data);
        }}
        isTLYF
        backgroundArr={backgroundTdsArr}
        isBack={true}
        backNavigation={() => navigateAction(props.navigation, "TDS Search Deposit")}
      >

        <Container>
          <View
            style={{
              width: "100%",
              backgroundColor: "#638ACC",
              justifyContent: "center",
              alignItems: "center",
              paddingVertical: 20,
              marginVertical: 20,
              borderRadius: 10,
            }}
          >
            <Text style={{
              color: "white",
              fontSize: 23,
              fontWeight: "600",
              textAlign: "center",
              marginBottom: "10px",
            }}>
              {" "}
              {"Your deposit is Protected with TDS Insured"}
            </Text>
            <ButtonWrapper
              color={"#A4D4A2"}
              bcolor={"#A4D4A2"}
              style={{ marginTop: 10 }}
              onPress={() => {
                window.open(searchDepositResponse?.certificate, "_blank");
              }}>
              <Text style={{ color: "white" }}>
                Download Deposit Certificate
              </Text>
            </ButtonWrapper>
          </View>
          <Hoverable
            onHoverIn={() => handleHoverIn("Credit Builder")}
            onHoverOut={() => handleHoverOut("Credit Builder")}
          >
            <Section style={{
              alignSelf: "center",
              marginTop: 20,
            }}>

              <Text style={{
                color: "black",
                fontSize: 18,
                fontWeight: "600",
                textAlign: "center",
              }}>
                {"Did you know you can help boost your "}
                <Text style={{ fontWeight: 800 }}>{"credit score"}</Text>
                {" with tlyfe?"}
              </Text>

              <ButtonWrapper
                color={"#A4D4A2"}
                bcolor={"#A4D4A2"}
                style={{ marginTop: 10 }}
                onPress={() => {
                  window.location.replace("/credit-builder");
                }}>
                <Text style={{ color: "white" }}>
                  Go To Credit Builder
                </Text>
              </ButtonWrapper>
            </Section>
          </Hoverable>

          <Section style={{
            alignSelf: "center",
            marginTop: 20,
          }}>

            <CustomTextInput
              id="dan"
              width={"100%"}
              title="Certificate Code/DAN Number"
              type="text"
              name="dan"
              prefilledValue={searchDepositResponse?.dan || ""}
              bColor={"#638ACC"}

            />

            <CustomTextInput
              id="depositAmount"
              width={"100%"}
              title="Deposit Amount"
              type="number"
              name="deposit_amount"
              onlyNumbers={true}
              prefilledValue={searchDepositResponse?.deposit_amount || ""}
              bColor={"#638ACC"}

            />
            <CustomTextInput
              id="Postcode"
              width={"100%"}
              title="Address"
              type="text"
              name="deposit_postcode"
              prefilledValue={searchDepositResponse?.address || ""}
              bColor={"#638ACC"}

            />
            <CustomTextInput
              id="Tenant_Surname"
              width={"100%"}
              title="Tenant Surname"
              type="text"
              name="tenant_surname"
              prefilledValue={searchDepositResponse?.tenants && searchDepositResponse?.tenants.length && searchDepositResponse?.tenants[0]?.surname ? searchDepositResponse?.tenants[0]?.surname : ""}
              bColor={"#638ACC"}
            />

            <CustomTextInput
              id="Tenancy Start Date"
              width={"100%"}
              title="Tenancy Start Date"
              type="text"
              name="Tenancy Start Date"
              prefilledValue={searchDepositResponse?.start_date ? searchDepositResponse?.start_date : ""}
              bColor={"#638ACC"}
            />

            <ButtonWrapper
              color={"#A4D4A2"}
              bcolor={"#A4D4A2"}
              style={{ marginTop: 40 }} onPress={() =>
              navigateAction(props.navigation, "TDS Search Deposit")

            }>
              <Text style={{ color: "white" }}>
                Search Again
              </Text>
            </ButtonWrapper>

            <View style={{
              height: 1,
              width: "100%",
              backgroundColor: primaryColor,
              marginTop: 20,
              marginBottom: 20,
            }}/>
            <Typography id={"TDS-LOGIN"} color={"#000"}>Already have a TDS account?<Typography
              id={"TDS-ACCCOUNT-CLICK-HERE"}
              onPress={() => navigateAction(navigation, "TDS Search Deposit")} color={primaryColor}> Click
              here</Typography></Typography>
          </Section>

          <Hoverable
            onHoverIn={() => handleHoverIn("LandingInsurance")}
            onHoverOut={() => handleHoverOut("LandingInsurance")}
          >
            <Section style={{
              alignSelf: "center",
              marginTop: 20,
            }}>

              <Text style={{
                color: "black",
                fontSize: 18,
                fontWeight: "600",
                textAlign: "center",
              }}>
                {"Protect more than just your deposit, with Tenant Contents Insurance!"}
              </Text>
              <ButtonWrapper
                color={"#A4D4A2"}
                bcolor={"#A4D4A2"}
                style={{ marginTop: 10 }}
                onPress={() => {
                  // window.open("https://paragonadvance.com/tlyfe-from-openbrix/");
                  navigateAction(props.navigation, "TenantInsurance");
                }}>
                <Text style={{ color: "white" }}>
                  Get a free quote today!
                </Text>
              </ButtonWrapper>
            </Section>
          </Hoverable>
        </Container>
      </BackGroundGenerator>
    </>
  );
};

export default insuredDeposit;
