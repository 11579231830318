import React, { Component } from 'react';
import {
  View,
  Text,
  Dimensions,
  SafeAreaView,
  Image,
  TouchableOpacity,
  Platform,
  ScrollView,
  FlatList,
  TextInput,
} from 'react-native';
import Hyperlink from 'react-native-hyperlink';
import * as Linking from 'expo-linking';
import Footer from '../../commonComponents/footerComponent/customFooter';

import { MaterialIcons } from '@expo/vector-icons';
import getPlatformType from '../../helpers/getPlatform';
import Header from '../../commonComponents/headerComponent/headerComponent';
import {
  Container,
  FormContainer,
  BottomContainer,
  ContactTitle,
  ContactHead,
  FormControls,
  MainContent,
} from './styles';
import WideButton from '../../commonComponents/wideButton';
import CustomTextInput from '../../commonComponents/customTextInput/customTextInput';
import { EmailValidation, NameValidation, SubjectValidation } from '../../helpers/validation';
const layoutType = getPlatformType();
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Loader from './../../commonComponents/activityIndicator/activityIndicator';

import { contactUs, getProfile } from '../../redux/actions/auth';
import { Background } from '../rentalLandingScreen/rentalLandingScreen.style';
import { COLORS } from '../../constants/colorPallete';
import { callIfAvailable } from '../../helpers/callEmailHelper';
import { showToaster } from '../../redux/actions/toast';
import BackGroundGenerator from '../LandingPages/common/BackGroundGenerator';
import { backgroundSearchArr } from '../LandingPages/images/openFlo/searchArrayOpenflo';
import { Typography } from '../openFlow/style';
class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: props?.profile?.data?.first_name
        ? props?.profile?.data?.first_name + props?.profile?.data?.last_name
        : '',
      nameErrorMsg: '',
      email: props?.profile?.data?.email ? props?.profile?.data?.email : '',
      emailErrorMsg: '',
      topic: '',
      message: '',
      profile: null,
      first_name: '',
      last_name: '',
      pre_email: '',
      topicErrorMsg: '',
      messageErrorMsg: '',
      
    };
  }
  validate = () => {
    const {
      name,
      topic,
      email,
      message,
      topicErrorMsg,
      messageErrorMsg,
      nameErrorMsg,
      emailErrorMsg,
    } = this.state;
    let emailCheck = EmailValidation(email);
    let nameCheck = NameValidation(name);
    let subjectCheck = SubjectValidation(topic);
    // if (emailCheck.status && nameCheck.status && subjectCheck.status) {
    //api call
    if (message || topic) {
      this.props.contactUs({
        email: email,
        topic: topic,
        name: name,
        message: message,
      });
      this.setState({ email: '', topic: '', name: '', message: '' });
    } else {
      this.props.showToaster('error', 'Please enter Subject or Message to proceed!');
    }
  };

  callAgent = async (x) => {
    let status = false;
    if (x) {
      status = await callIfAvailable(x);
      if (!status) {
        props.showToaster('error', "Sorry, agent's contact unavailable");
      }
    } else if (!status) {
      props.showToaster('error', "Sorry, agent's contact unavailable");
    }
  };
  render() {
    const { navigation, loading, profileLoading,token } = this.props;
    const {
      name,
      topic,
      email,
      message,
      topicErrorMsg,
      messageErrorMsg,
      nameErrorMsg,
      emailErrorMsg,
    } = this.state;
    const openLink = (url) => {
      window.open(url);
    };
    let arr = JSON.parse(JSON.stringify(backgroundSearchArr));
    return (
      <BackGroundGenerator
      props={this.props}
      isTLYF={true}
      isLanding
    >
      <View style={{marginVertical:40}}>



        <Container style={{ backgroundColor: 'white', padding: 30, borderRadius: 30 }}>
          <View
            style={{
              width: '100%',
              paddingVertical: 15,
            }}
          >
              <Typography
            raj
            bold={'700'}
            color={'#1C1C1C'}
            size={ '30px'}
            line={ '35px' }
            align={'center'}
            bottom={'30'}
          >
          SEND US A MESSAGE
          </Typography>

            <FormContainer>
              <View
                style={{
                  flexGrow: layoutType !== 'phone' ? 1 : 0,
                  marginBottom: 16,
                  width: layoutType !== 'phone' ? 'auto' : '100%',
                }}
              >
                 <Typography bottom={'10px'} 
                 align={"left"}
                 color={COLORS.BLACK} raj size={"16px"} bold={"600"}>
            Your Name
                </Typography>
                <View
                  style={{
                    width: '100%',
                    height: 40,
                  }}
                >
                  <TextInput
                    style={{
                      width: '100%',
                      height: '100%',
                      padding: 10,
                      borderColor: '#b2b2b2',
                      borderWidth: 1,
                      borderRadius: 50,
                    }}
                    id={'C_NAME'}
                    maxLength={40}
                    onChangeText={(text) => this.setState({ name: text, emailErrorMsg: '' })}
                    value={this.state.name}
                  />
                </View>
              </View>
              <View
                style={{
                  flexGrow: layoutType !== 'phone' ? 1 : 0,
                  marginLeft: layoutType !== 'phone' ? 16 : 0,
                  marginBottom: 16,
                  width: layoutType !== 'phone' ? 'auto' : '100%',
                }}
              >
             
                <Typography bottom={'10px'} 
                 align={"left"}
                 color={COLORS.BLACK} raj size={"16px"} bold={"600"}>
                    Your Email Address
                </Typography>
                <View
                  style={{
                    width: '100%',
                    height: 40,
                  }}
                >
                  <TextInput
                    style={{
                      width: '100%',
                      height: '100%',
                      padding: 10,
                      borderColor: '#b2b2b2',
                      borderWidth: 1,
                      borderRadius: 50,
                    }}
                    id={'C_EMAIL'}
                    maxLength={40}
                    onChangeText={(text) => this.setState({ email: text, emailErrorMsg: '' })}
                    value={this.state.email}
                  />
                </View>
              </View>
            </FormContainer>
            <View style={{ width: '100%', marginBottom: 16 }}>
              <View>
               

                <Typography bottom={'10px'} 
                 align={"left"}
                 color={COLORS.BLACK} raj size={"16px"} bold={"600"}>
                      Subject of message
                </Typography>
                <View
                  style={{
                    width: '100%',
                    height: 40,
                    marginBottom: 15,
                  }}
                >
                  <TextInput
                    style={{
                      width: '100%',
                      height: '100%',
                      padding: 10,
                      borderColor: '#b2b2b2',
                      borderWidth: 1,
                      borderRadius: 50,
                    }}
                    id={'C_SUBJECTOFMESSAGE'}
                    maxLength={40}
                    onChangeText={(text) => this.setState({ topic: text })}
                    value={topic}
                  />
                </View>
              </View>
            </View>
            <View style={{ width: '100%' }}>
              <View>
          
                <Typography bottom={'10px'} 
                 align={"left"}
                 color={COLORS.BLACK} raj size={"16px"} bold={"600"}>
                  Write your message here
                </Typography>
                <View
                  style={{
                    width: '100%',
                    height: 150,
                    borderColor: '#b2b2b2',
                    borderWidth: 1,
                    borderRadius: 5,
                  }}
                >
                  <TextInput
                    style={{
                      width: '100%',
                      height: '100%',
                      padding: 10,
                    }}
                    id={'C_SUBJECTOFMESSAGEFULL'}
                    maxLength={2000}
                    multiline
                    onChangeText={(text) => this.setState({ message: text })}
                    value={message}
                  />
                </View>
              </View>
            </View>
            <View
              style={{
                alignSelf: 'center',
                marginTop: 20,
                width:"100%",
              }}
            >
              <WideButton
                childText={'SEND'}
                buttonType={'solid'}
                gradiant
                height={55}
                id={'C_SUBMIT'}
                isLGreen={token}
                textSize={24}
                isRaj={true}
                onPress={() => this.validate()}
              />
            </View>
          </View>
        </Container>

        <Loader loading={loading} />
      </View>
      </BackGroundGenerator>
    );
  }
}

const mapStateToProps = (state) => ({
  token : state?.appReducer?.loginData?.data?.token,
  loading: state.authReducer.contactusLoading,
  profile: state.authReducer.profile,
  profileLoading: state.authReducer.profileLoading,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      contactUs,
      getProfile,
      showToaster,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);
