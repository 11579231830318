import { View,Image, Dimensions } from 'react-native';
import React, { useCallback, useRef, useState } from "react";
import { useSelector } from 'react-redux';

import CommonAuth from "./CommonAuth";
import MediaHubList from "../../mediaHub/mediaHubList";
import BackGroundGenerator from "../common/BackGroundGenerator";
import { backgroundSearchArr } from "../images/tlyfe/searchArrayTlyfe";

const LandingInfoHub = (props) => {

  const [type, setType] = useState("web");
  const scrollViewRef = useRef();
  const authData = useSelector((state) => state?.appReducer?.mobileData?.data?.isWebView);
  const token = useSelector((state) => state?.appReducer?.loginData?.data?.token);
  const handleScrollButtonClick = useCallback(() => {
    const height=Dimensions.get('window').height;
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollTo({ y:layoutType=='web'?height:height/2, animated: true });
    }
  }, []);
  return (
    <>
      <BackGroundGenerator
        props={props}
        layoutType={(data) => setType(data)}
        backgroundArr={backgroundSearchArr}
        isTLYF={true}
        isLanding
        cloudHeader={token?false: true}
        scrollViewRef={scrollViewRef}
      >
        <CommonAuth
          heading={"INFO HUB"}
          navigation={props.navigation.navigate}
          type={type}
          subheading={"IN COLLABORATION WITH"}
          onScrollButtonClick={handleScrollButtonClick}
          desc={""}
          hideButton={true}
          showTdsLogo
          hideAuth={true}
          isTLYF={true}
          customImageHeader={{
            height: "60vh",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
            paddingHorizontal: "8%",
            width:"100%"
          }}
          {...props}
        />
        <View style={ type=="web"?{width:"80%",alignSelf:"center"}:{marginLeft:25}}>
        <Image source={require('./images/tds.png')} style={{width:114,marginTop:10,marginBottom:0,height:59,resizeMode:'contain'}}/>
        </View>
        <MediaHubList navigation={props.navigation} detailsLink="LandingInfoHubDetails"/>
        {
          authData||token ? null : <View style={{ height: 300 }}></View>
        }
      </BackGroundGenerator>     
    </>
  );
};

export default LandingInfoHub;
