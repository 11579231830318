import React, { useState } from 'react';
import { TouchableOpacity, View, StyleSheet } from 'react-native';
import { ODSText } from '../ODSText';
import { useTheme } from '@emotion/react';
interface ODSTabsProps {
  items?: string[];
  activeTab?: number;
  onTabChange: (index: number) => void;
  color?: string;
  selectedColor?: string;
  restStyle?: any;
}
const ODSTabs = ({
  items = ['My Docs', 'Property Docs', 'Share Centre'],
  activeTab = 0,
  onTabChange = () => {},
  color,
  selectedColor,
  restStyle,
}: ODSTabsProps) => {
  const [selected, setSelected] = useState(activeTab);
  const { colors } = useTheme();
  color = color ? color : colors.main.purple[500];
  selectedColor = selectedColor ? selectedColor : colors.main.purple[700];

  const _onChange = (index) => {
    setSelected(index);
    onTabChange(index);
  };

  return (
    <View style={[styles.container, { backgroundColor: selectedColor }, restStyle]}>
      {items.map((e, index) => (
        <TouchableOpacity
          key={index}
          onPress={() => _onChange(index)}
          style={[
            styles.tab,
            {
              backgroundColor: selected === index ? selectedColor : color,
              borderTopLeftRadius: index === 0 ? 8 : 0,
              borderBottomLeftRadius: index === 0 ? 8 : 0,
              borderTopRightRadius: index === items.length - 1 ? 8 : 0,
              borderBottomRightRadius: index === items.length - 1 ? 8 : 0,
            },
          ]}>
          <ODSText
            textAlign={'center'}
            color={colors.text.inverted}
            type={selected === index ? 'sm-bold' : 'sm'}>
            {e}
          </ODSText>
        </TouchableOpacity>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    borderRadius: 8,
  },
  tab: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 20,
    marginLeft: 1,
  },
});

export default ODSTabs;
