import { tdsConstants } from "../../../components/tds/tdsRedux/tds.constants";
import { types } from "../../actionTypes";

const authReducer = (
  state = {
    loginLoading: false,
    loginData: null,
    loginStatus: false,
    signupStatus: false,
    signupLoading: false,
    signupData: null,
    profile: null,
    profileLoading: false,
    resetPasswordLoading: false,
    resetPasswordData: null,
    resetPasswordStatus: false,
    changePasswordLoading: false,
    changePasswordData: null,
    changePasswordStatus: false,
    uploadDoc: null,
    contactusLoading: false,
    contactusData: null,
    updateNotiLoading: false,
    isBritishPassword:false
  },
  action,
) => {
  switch (action.type) {
    case types.LOGIN_REQUEST: {
      return {
        ...state,
        loginLoading: true,
      };
    }
    case types.LOGIN_SUCCESS: {
      return {
        ...state,
        loginLoading: false,
        loginData: action.payload,
        loginStatus: action.payload.status,
      };
    }
    case types.LOGIN_FAILED: {
      return {
        ...state,
        loginLoading: false,
        loginData: action.payload,
        loginStatus: false,
      };
    }
    case types.SIGNUP_REQUEST: {
      return {
        ...state,
        signupLoading: true,
      };
    }
    case types.SIGNUP_SUCCESS: {
      return {
        ...state,
        signupLoading: false,
        signupData: action.payload,
        signupStatus: action.payload.status,
      };
    }
    case types.SIGNUP_FAILED: {
      return {
        ...state,
        signupLoading: false,
        signupData: action.payload,
        signupStatus: false,
      };
    }
    case types.PROFILE_REQUEST: {

      return {
        ...state,
        profileLoading: true,
      };
    }
    case types.PROFILE_REQUEST_SUCCESS: {

      return {
        ...state,
        profileLoading: false,
        profile: action.payload,
      };
    }
    case types.PROFILE_REQUEST_FAILED: {
      return {
        ...state,
        profileLoading: false,
        profile: action.payload,
        profileStatus: false,
      };
    }
    case types.GET_INVOICE_REQUSET: {
      return {
        ...state,
        profileLoading: true,

      };
    }
    case types.GET_INVOICE_RESPONSE: {
      return {
        ...state,
        profileLoading: false,
      };
    }
    case types.RESET_PASSWORD_REQUEST: {
      return {
        ...state,
        resetPasswordLoading: true,
      };
    }
    case types.RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        resetPasswordLoading: false,
        resetPasswordData: action.payload,
        resetPasswordStatus: action.payload.status,
      };
    }
    case types.RESET_PASSWORD_FAILED: {
      return {
        ...state,
        resetPasswordLoading: false,
        resetPasswordData: action.payload,
        resetPasswordStatus: false,
      };
    }
    case types.CHANGE_PASSWORD_REQUEST: {
      return {
        ...state,
        changePasswordLoading: true,
      };
    }
    case types.CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        changePasswordLoading: false,
        changePasswordData: action.payload,
        changePasswordStatus: action.payload.status,
      };
    }
    case types.CHANGE_PASSWORD_FAILED: {
      return {
        ...state,
        changePasswordLoading: false,
        changePasswordData: action.payload,
        changePasswordStatus: false,
      };
    }
    case types.UPLOAD_DOC_SUCCESS: {
      return {
        ...state,
        uploadStatus: action.payload,
      };
    }

    case types.UPLOAD_DOC: {

      return {
        ...state,
        upLoading: true,
      };
    }

    case types.UPLOAD_FILE: {
      return {
        ...state,
        fileupLoading: true,
      };
    }
    case types.UPLOAD_FILE_SUCCESS: {

      return {
        ...state,
        fileupLoading: false,
        uploadedFiles: action.data,
      };
    }
    case  tdsConstants.LOGIN_TO_TDS_FAILURE: {
      return {
        ...state,
        profile: {
          ...state?.profile,
          data:{
            ...state?.profile?.data,
            tdkToken:null
          }
        },
      };
    }

    case types.UPLOAD_FILE_RESET: {

      return {
        ...state,
        fileupLoading: false,
        uploadedFiles: null,
      };
    }

    case types.UPLOAD_FILE_FAIL: {

      return {
        ...state,
        fileupLoading: false,
        uploadedFiles: null,
      };
    }

    case types.GET_DOC: {
      return {
        ...state,
        docLoading: true,
      };
    }
    case types.GET_DOC_SUCCESS: {
      return {
        ...state,
        docLoading: false,
        docs: action.payload,
      };
    }
    case types.GET_DOC_FAIL: {
      return {
        ...state,
        docLoading: false,
        docs: [],
      };
    }
    case types.UPDATE_SHARE_STATUS_DOC: {
      return {
        ...state,
        updateStatusLoading: true,
      };
    }
    case types.UPDATE_SHARE_STATUS_DOC_SUCCESS: {

      return {
        ...state,
        updateStatusLoading: false,
        updateStatus: action.payload.status,
      };
    }
    case types.GET_DOC_FAIL: {
      return {
        ...state,
        updateStatusLoading: false,
        updateStatus: false,
      };
    }
    case types.DELETE_DOC: {
      return {
        ...state,
        deleteDocLoading: true,
      };
    }
    case types.DELETE_DOC_SUCCESS: {
      // 
      return {
        ...state,
        deleteDocLoading: false,
        deleteStatus: action.payload.status,
      };
    }
    case types.DELETE_DOC_FAIL: {
      return {
        ...state,
        deleteDocLoading: false,
        deleteStatus: action.payload.status,
      };
    }
    case types.UPDATE_PROFILE: {
      return {
        ...state,
        updateProfileLoading: true,
      };
    }
    case types.UPDATE_PROFILE_SUCCESS: {
      return {
        ...state,
        updateProfileLoading: false,
        updateProfileStatus: action.payload.status,
      };
    }
    case types.UPDATE_PROFILE_FAIL: {
      return {
        ...state,
        updateProfileLoading: false,
        updateProfileStatus: false,
      };
    }
    case types.CONTACTUS_REQUEST: {
      return {
        ...state,
        contactusLoading: true,
      };
    }
    case types.CONTACTUS_SUCCESS: {
      return {
        ...state,
        contactusLoading: false,
        contactusData: action.payload,
      };
    }
    case types.CONTACTUS_FAILED: {
      return {
        ...state,
        contactusLoading: false,
        contactusData: action.payload,
      };
    }
      ;
    case types.UPDATE_NOTIFICATION_REQUEST: {
      return {
        ...state,
        updateNotiLoading: true,
      };
    }
    case types.UPDATE_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        updateNotiLoading: false,

      };
    }
    case types.UPDATE_NOTIFICATION_FAILED: {
      return {
        ...state,
        updateNotiLoading: false,

      };
    }
    case types.UPDATE_IS_BRITISH_PASSWORD:{
      return {
        ...state,
        isBritishPassword: action.payload,
      };
    }
    default:
      return state;
  }
};

export default authReducer;
