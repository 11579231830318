import React, { useState, useEffect } from 'react';
import { Image, TouchableOpacity, View } from 'react-native';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Entypo } from '@expo/vector-icons';
import { Link } from '@react-navigation/native';
import { MaterialIcons } from '@expo/vector-icons';
import getPlatformType from './../../helpers/getPlatform';
import { addNotification, onNotificationRemove } from './../../redux/actions/notification';
import DataTable from 'react-data-table-component';

import { COLORS } from '../../constants/colorPallete';
import { Title } from '../../commonComponents/NoData/styles';
import {
  ListBox,
  NotificationBox,
  NotificationName,
  NotificationNameText,
  NotificationRemove,
  NotificationRemoveText,
  NotificationDate,
  NotificationBoxLeft,
  NotificationBoxRight,
  NotificationDateText,
} from './myAccountStyles';
import { Pressable } from 'react-native';
import { Typography } from '../openFlow/style';
import { getInvoice } from '../../redux/actions/app';
import moment from 'moment';

const layoutType = getPlatformType();

const NotificationList = (props) => {
  const [list, setList] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getInvoice((data) => {
        if(data&& data.data &&data?.data?.length)
        console.log('datadatadata', data);
        setList(data.data)
      })
    );
  }, [props?.isArchived]);

  const columns = [
    {
      name: 'Date',
      selector: 'Date',
      cell: (row) => (
        <Typography color={'#000'} bold={'400'} size={14}>
                   {moment(row.createdAt).format('Do MMM YYYY')}
        </Typography>
      ),
    },
    {
      name: 'Value',
      selector: 'Value',
      cell: (row) => (
        <Typography color={'#000'} bold={'400'} size={14}>
          £{row?.openbrix_product?.product_price}
        </Typography>
      ),
    },
    {
      name: 'Status',
      selector: 'Status',
      cell: (row) => (
        <View
          style={{
            backgroundColor: COLORS.GREEN,
            paddingHorizontal: 10,
            paddingVertical: 3,
            borderRadius: 10,
          }}
        >
          <Typography color={'#1B8A8C'} bold={'400'} size={14}>
          Paid
          </Typography>
        </View>
      ),
    },
    {
      name: 'Download',
      selector: 'Download',
      cell: (row) => (
        <TouchableOpacity
          style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}
          onPress={()=>window.open(row.invoice_url,"_blank")}
        >
          <MaterialIcons name="file-download" size={24} color={'#1B8A8C'} />
          <Typography color={'#1B8A8C'} bold={'400'} size={14}>
            {'PDF'}
          </Typography>
        </TouchableOpacity>
      ),
    },
  ];
  console.log("listlist",list)
  return <DataTable columns={columns} data={list} responsive />;
};
const mapStateToProps = (state) => {
  return {
    notificationReducer: state.notificationReducer,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onNotificationRemove,
      addNotification,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(NotificationList);
