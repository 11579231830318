import React, { useState } from 'react';
import {
  Text,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import { ODSIcon } from '../ODSIcon';
import { ODSText } from '../ODSText';
import getScreenType from '../../../utils/getScreenType';
import { useTheme } from '@emotion/react';

const ODSWelcomeTile = ({
  subtitle,
  title,
  onPress
}) => {
  const theme = useTheme();

  return (
    <View style={[styles(theme.colors).containerStyle]}>
      <ODSText marginTop={24} color={theme.colors.text.primary} type={'h4'}>
        {title}
      </ODSText>
      <ODSText marginTop={16} color={theme.colors.text.secondary} type={'md'}>
        {subtitle}
      </ODSText>
      <View style={{ marginTop: 24, paddingVertical: 24, paddingHorizontal: 16, flexDirection: 'row', alignItems: 'center', borderTopWidth: 1, borderTopColor: theme.colors.other['border-neutral'] }}>
        <ODSIcon icon="TB-Profile-Info" size={40} color={theme.colors.elements['color-light']} />
        <View style={{ width: 20 }} />
        <View style={{ flex: 1 }}>
          <ODSText color={theme.colors.text.primary} type={'md-bold'}>Keep your data updated</ODSText>
          <ODSText width={'100%'} marginTop={12} color={theme.colors.text.secondary} type={'sm'}>Go to your profile to update your personal details</ODSText>
        </View>
        <View style={{ width: 20 }} />
        <TouchableOpacity onPress={onPress && onPress}>
          <ODSIcon icon="TB-Arrow-Right" size={40} color={theme.colors.button.main} />
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = (theme) =>
  StyleSheet.create({
    containerStyle: {
      width: getScreenType() == "phone" ? "96%" : 408,
      borderRadius: 12,
      shadowColor: '#101828',
      shadowOffset: { width: 0, height: 1 },
      shadowOpacity: 0.1,
      shadowRadius: 4,
      elevation: 2,
      backgroundColor: theme.neutral[100],
      paddingHorizontal: 8,
      paddingVertical: 10,
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'center'
    },
  });

export default ODSWelcomeTile;
