import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { ImageBackground, View, TouchableOpacity, Text } from "react-native";

import ShareCode from './shareCode';
import OpenFlowDocs from './openFlowDocs';
import ShareDocument from './shareDocument';
import { Typography } from "../../../openFlow/style";
import { deleteDoc, fetchDoc } from '../../../../redux/actions/auth';
import { COLORS } from '../../../../constants/colorPallete';
import { TabContainer } from '../../../idVerification/styles';
import getPlatformType from "../../../../helpers/getPlatform";
import { Wrapper } from "../../../identifyAndFinance/stylesNew";
import BackButton from "../../../../commonComponents/BackButton";
import { tenantActions } from "../../openFlowRedux/tenant.actions";
import BackGroundGenerator from "../../../LandingPages/common/BackGroundGenerator";
import { Feather } from '@expo/vector-icons';
import { documentPopup } from "../../../../redux/actions/statusPopup";
import { navigateAction, replaceAction } from "../../../../helpers/navigationHelpers";
import AddNewDocument from "../../../addNewDocument";
import { getImage } from "../../../../../openbrix-design-system/utils/getScreenType";
import ODSButton from "../../../../../openbrix-design-system/ui/atoms/ODSButton";
import { useTheme } from "@emotion/react";
import { ODSText } from "../../../../../openbrix-design-system/ui/atoms/ODSText";
import ODSTabs from "../../../../../openbrix-design-system/ui/atoms/ODSTabs";
import DocNShare from "../../../../../openbrix-design-system/ui/molecules/DocNShare";
import CustomPopUp from "../../../../commonComponents/customPopUp/customPopUp.web";
const PreQualification = (props) => {

  const dispatch = useDispatch();
  const layout = getPlatformType();
  const [flowId, setFlowId] = React.useState(null);
  const [teanants, setUser] = React.useState(null);
  const [myDocuments, setMyDocuments] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const globalState = useSelector((state) => state?.tenant);
  const [propertyDocuments, setPropertyDocuments] = useState([]);
  const docs = useSelector((state) => state?.authReducer?.docs);
  const userId = useSelector((state) => state.authReducer?.profile?.data?.appUser?.app_user_id);
  const [myDocOpen,setMyDocOpen]=useState(true);
  const [propOpen,setPropOpen]=useState(false);

  const [docToRemove, setRemoveDoc] = useState("");
  const [documentArray, setDocuments] = useState([]);
  const [popUpIsVisible, setShowPopup] = useState(false);



  const deleteDocument = (data) => {
    dispatch(deleteDoc(data?.document_id));
    setRemoveDoc(null);
  };
  useEffect(() => {
    if (docs) {
      if (docs?.status) {
        setMyDocuments(docs?.data?.myDocuments);
        setPropertyDocuments(docs?.data?.rentalDocuments);
      }
    }
  }, [docs]);

  useEffect(() => {
    dispatch(fetchDoc());
    dispatch(tenantActions.getCodesStatus());
  }, []);

  useEffect(() => {
    if (globalState?.idendityData?.tenants && globalState?.idendityData?.tenants.length) {
      let currentUSer = getAppUserDetails(globalState?.idendityData?.tenants, userId);
      if (currentUSer?.length) {
        setUser(currentUSer[0]);
      }
    }
  }, [globalState?.idendityData?.tenants]);

  useEffect(() => {
    if (props?.route?.params?.id) {
      dispatch(tenantActions.getIdentityData(props?.route?.params?.id));
      setFlowId(props?.route?.params?.id);
    }
  }, [props?.route?.params?.id]);
  const _onShareSave=(values)=>{
    dispatch(
      tenantActions.saveAgentEmail(values,
        (data) => {
          dispatch(tenantActions.getCodesStatus());
        },
      ),
    );
  }

  const tabView = () => {
    return (
      <TabContainer style={{ backgroundColor: COLORS.LGREEN, marginTop: 40, width: layout == 'phone' ? '100%': '60%' }}>
        <TouchableOpacity
          onPress={() => setSelectedIndex(0)}
          id={'tab-idref'}
          testID={'tab-idref'}
          style={{ flex: 1 / 2, justifyContent: 'center', alignItems: 'center' }}
        >
          <Text
            style={{
              fontSize: layout == 'phone' ? 14 : 18,
              color: "white",
              fontFamily: selectedIndex == 0 ? 'Lato-Bold' : 'Lato',
            }}
          >
            My Docs
          </Text>
          {selectedIndex == 0 && (
            <View
              style={{
                width: layout == 'phone' ? '85%' : '30%',
                height: 2,
                backgroundColor:  "white",
                marginTop: 8,
              }}
            />
          )}
        </TouchableOpacity>
        {/* <TouchableOpacity
          testID={'tab-fullref'}
          id={'tab-fullref'}
          onPress={() => setSelectedIndex(1)}
          style={{
            flex: 1 / 2,
            justifyContent: 'center',
            alignItems: 'center',
            borderLeftWidth: 1,
            borderRightWidth: 1,
            borderColor: layout == 'phone' ? 'white' : 'white',
          }}
        >
          <Text
            style={{
              fontSize: layout == 'phone' ? 14 : 18,
              color: "white",
              fontFamily: selectedIndex == 1 ? 'Lato-Bold' : 'Lato',
            }}
          >
           Property Docs
          </Text>
          {selectedIndex == 1 && (
            <View
              style={{
                width: layout == 'phone' ? '85%' : '30%',
                height: 2,
                backgroundColor:  "white",
                marginTop: 8,
              }}
            />
          )}
        </TouchableOpacity> */}

        <TouchableOpacity
          testID={'tab-fullref'}
          id={'tab-fullref'}
          onPress={() => setSelectedIndex(2)}
          style={{
            flex: 1 / 2,
            justifyContent: 'center',
            alignItems: 'center',
            borderLeftWidth: 1,
            // borderRightWidth: 1,
            borderColor: layout == 'phone' ? 'white' : 'white',
          }}
        >
          <Text
            style={{
              fontSize: layout == 'phone' ? 14 : 18,
              color: "white",
              fontFamily: selectedIndex == 1 ? 'Lato-Bold' : 'Lato',
            }}
          >
            Share items
          </Text>
          {selectedIndex == 2 && (
            <View
              style={{
                width: layout == 'phone' ? '85%' : '30%',
                height: 2,
                backgroundColor:  "white",
                marginTop: 8,
              }}
            />
          )}
        </TouchableOpacity>
      </TabContainer>
    );
  };
  const [showModal, setShowModal] = React.useState(false);
  const {colors}=useTheme();

  return (
    <BackGroundGenerator isTLYF props={props}>
     <ImageBackground
       
        style={{
          width: '100%',
          height: '271px',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
        resizeMode="cover"
      >
        <ODSButton
          rIcon={'TB-Arrow-Left'}
          restStyle={{ marginTop: 20, marginLeft: 20 }}
          type="secondary"
          disabled={false}
          themeColor={colors.main.purple}
          onPress={() => replaceAction(props?.navigation, 'Dashboard')}
        >
          My Dashboard
        </ODSButton>
      </ImageBackground>
     <DocNShare 
     onShareSave={(value)=>_onShareSave(value)}
     globalState={globalState} propertyDocs={propertyDocuments} addDoc={()=>setShowModal(true)} DocumentData={myDocuments} flowId={flowId} navigation={props.navigation} onDelete={(item)=>{
         setRemoveDoc(item);
         setShowPopup(true);
     }}/>
           
      {/* <Wrapper>
        {tabView()}
        <View style={{ width: layout == 'phone' ? '100%': '60%', backgroundColor: '#FFFFFF', paddingTop: 10, paddingBottom: 30, minHeight: 300 }}>
          {selectedIndex == 2 &&
            <ShareCode teanant={teanants} flowId={flowId} />
          }
          {selectedIndex==0
          && 
          <View style={{width:'100%',alignItems:'center'}}>
            <View style={{width:'95%',paddingHorizontal:20,minHeight:50,borderWidth:1,borderColor:'#B2B2B2',borderRadius:8,marginTop:20}}>
              <View style={{justifyContent:'space-between',flexDirection:'row',alignItems:'center',height:50,borderBottomWidth:myDocOpen?1:0,borderBottomColor:'#B2B2B2'}}>
              <Typography color={COLORS.LGREEN} size={16}>Document Share Center</Typography>
              <TouchableOpacity onPress={()=>setMyDocOpen(!myDocOpen)} >
               <Feather name={myDocOpen?"chevron-up":"chevron-down" }size={24} color={COLORS.LGREEN} />
              </TouchableOpacity>
              </View>
              {myDocOpen &&  <ShareDocument navigation={props.navigation} teanant={teanants} allData={myDocuments} flowId={flowId} />}
            </View>
            <View style={{width:'95%',paddingHorizontal:20,minHeight:50,borderWidth:1,borderColor:'#B2B2B2',borderRadius:8,marginTop:20}}>
              <View style={{justifyContent:'space-between',flexDirection:'row',alignItems:'center',height:50,borderBottomWidth:propOpen?1:0,borderBottomColor:'#B2B2B2'}}>
              <Typography color={COLORS.LGREEN} size={16}>Property Documents</Typography>
              <TouchableOpacity onPress={()=>setPropOpen(!propOpen)} >
               <Feather name={propOpen?"chevron-up":"chevron-down" }size={24} color={COLORS.LGREEN} />
              </TouchableOpacity>
              </View>
              {propOpen && <OpenFlowDocs teanant={teanants} flowId={flowId} allData={propertyDocuments} />}
            </View>
            <TouchableOpacity onPress={() =>setShowModal(true)} style={{ justifyContent: "center", alignItems: "center", marginTop: 100,backgroundColor:COLORS.LGREEN ,width:'100%',height:60}}>
           <Typography size={'20px'} color={'white'} bold={'600'}>Add Documents</Typography>
          </TouchableOpacity>
          </View>
          }
        </View>
        <AddNewDocument
        showModal={showModal}
        setShowModal={setShowModal}
        navigation={props.navigation}
        />
      </Wrapper> */}
       <AddNewDocument
        showModal={showModal}
        setShowModal={setShowModal}
        navigation={props.navigation}
        />
      <CustomPopUp
        loading={popUpIsVisible}
        primaryButtonText={"Sure"}
        secondaryButtonText={"cancel"}
        primaryText={"Remove Doc?"}
        secondaryText={" Are you sure you want to remove Doc?"}
        onPrimaryButtonPress={() => {
          setShowPopup(false);
          // dispatch(documentPopup(false))
          deleteDocument(docToRemove);
        }}
        onSecondaryButtonPress={() => {
          setShowPopup(false);
          // dispatch(documentPopup(false))
        }}
      />
    </BackGroundGenerator>
  );
};

export default PreQualification;
