import React, { Fragment, useEffect, useState } from "react";
import { Image, TouchableOpacity, View } from "react-native";
// import CustomPopUp from "./../../commonComponents/customPopUp/customPopUp";

import Modal  from "react-native-modal";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import idx from "idx";
// import { LogoutApi } from "../../redux/actions/auth";
import { DrawerIcon, DrawerMenu, DrawerWrapper, MenuItem, SubMenuItem } from "./drawerStyle";
// import SocialIcons from "../../commonComponents/footerComponentLanding/socialIcons";
import { useTheme } from "@emotion/react";
import { ODSIcon } from "../../../../openbrix-design-system/ui/atoms/ODSIcon";
import { ODSText } from "../../../../openbrix-design-system/ui/atoms/ODSText";
import { navigateAction } from "../../../helpers/navigationHelpers";
import { COLORS } from "../../../constants/colorPallete";
import getScreenType from "../../../../openbrix-design-system/utils/getScreenType";
import { useNavigation } from "@react-navigation/native";
import Portal from "../../../../openbrix-design-system/ui/atoms/ODSModal/potral";

const layoutType = getScreenType();
const Drawer = (props) => {
  const [popUpIsVisible, setpopUpIsVisible] = useState(false);
  const [deletePopUpIsVisible, setDeletePopUpIsVisible] = useState(false);
  const [showDashboard,setShowDashboard]=useState(false);

  const [tdsRoute, setTdsRoute] = useState("tdsLocation");
  const {colors}=useTheme();

  const {
    data,
    borderColor,
    token,
    isLanding,
    profile,
    visible,
    setVisible,
    theme
  } = props;
  const navigation=useNavigation();
  const tdsLocationSelected = useSelector((state) => state.appReducer?.tdsLocationSelected);

  useEffect(() => {
    if (profile && profile?.data) {
      if (profile?.data?.tdkToken?.user_id) {
        setTdsRoute("Custodial Tenancies");
      } else {
        setTdsRoute(tdsLocationSelected?tdsLocationSelected: "tdsLocation");
      }
    } else {
      setTdsRoute(tdsLocationSelected?tdsLocationSelected: "tdsLocation");
    }
    // setModalVisible(visible)
  }, [profile]);
  return (
    <Portal>
    <Modal
      isVisible={visible}
      animationIn="slideInRight"
      animationOut="slideOutRight"
      animationInTiming={1000}
      animationOutTiming={1000}
      coverScreen={false}
      hasBackdrop={false}
      backdropOpacity={0}
      onBackdropPress={() => setVisible(false)}
      style={{
        margin: 0,
        width:layoutType=="phone"?'100%':400,
        marginLeft:'auto',
        height:'100%'
      }}
    >
      <View
        style={{ flex: 1}}
        // onPress={() => setVisible(false)}
      >
        <DrawerWrapper>
          <View
            style={{
                width:layoutType=="phone"?'100%':400,
              minHeight: "70%",
              paddingBottom: 50,
              backgroundColor:theme
            }}
          >
            <DrawerIcon>
            <Image source={require('../../../../openbrix-design-system/images/logos/tlyfe_logo_white.png')} style={{ width: 74, height: 34,resizeMode:'contain' }} />
              <TouchableOpacity onPress={() => setVisible(false)}>
                <ODSIcon icon="TB-Close" size={32} color={'#fff'}/>
              </TouchableOpacity>
            </DrawerIcon>
            <DrawerMenu>
                <TouchableOpacity onPress={()=>{
                   setVisible(false);
                   navigateAction(navigation, "Introduction")
                }} style={{marginVertical:24,paddingHorizontal:24}}>
                      <ODSText type="h4" textAlign='right' color={colors.text.inverted}>Introduction</ODSText>
                </TouchableOpacity>
                
                <TouchableOpacity 
                onPress={()=>{
                  setVisible(false);
                  navigation.navigate('RentReadyLanding')
               }}
                style={{marginVertical:24,paddingHorizontal:24}}>
                  <ODSText type="h4" textAlign='right' color={colors.text.inverted}>Rent Ready</ODSText>
                </TouchableOpacity>
                
                <TouchableOpacity
                onPress={()=>{
                  setVisible(false);
                  navigateAction(navigation, "InsuranceLanding")
               }}
                style={{marginVertical:24,paddingHorizontal:24}}>
                    <ODSText type="h4" textAlign='right' color={colors.text.inverted}>Tenant Insurance</ODSText>
                </TouchableOpacity>
                
                <TouchableOpacity onPress={()=>{
                   setVisible(false);
                   navigateAction(navigation, "CreditBuilderLanding")
                }} style={{marginVertical:24,paddingHorizontal:24}}>
                     <ODSText type="h4" textAlign='right' color={colors.text.inverted}>Credit Builder</ODSText>
                </TouchableOpacity>
                <TouchableOpacity onPress={()=>{
                   setVisible(false);
                   navigateAction(navigation, "MediaHub")
                }} style={{marginVertical:24,paddingHorizontal:24}}>
                     <ODSText type="h4" textAlign='right' color={colors.text.inverted}>Info Hub</ODSText>
                </TouchableOpacity>
                <TouchableOpacity onPress={()=>{
                   setVisible(false);
                   navigateAction(navigation, "Sign in")
                }} style={{marginVertical:24,paddingHorizontal:24}}>
                     <ODSText type="h4" textAlign='right' color={colors.text.inverted}>Login</ODSText>
                </TouchableOpacity>
               
              </DrawerMenu> 
    </View>         
        </DrawerWrapper>
      </View>
    
      
    </Modal>
    </Portal>
  );
};
const mapStateToProps = (state) => ({
  token: idx(state, (_) => _.appReducer.loginData.data.token),
    profile:idx(state, (_) => _.authReducer.profile.data), 
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
    //   LogoutApi,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Drawer);
