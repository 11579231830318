import React, { useState } from "react";

import MediaHubList from "./mediaHubList";
import BackGroundGenerator from "../LandingPages/common/BackGroundGenerator";
import { backgroundSearchArr } from "../LandingPages/images/tlyfe/searchArrayTlyfe";
import { View } from "react-native";

const MediaHub = (props) => {
  const [type, setType] = useState("web");

  return (
    <BackGroundGenerator
        props={props}
        layoutType={(data) => setType(data)}
        backgroundArr={backgroundSearchArr}
        isTLYF={true}
        isLanding
      >
        <View style={{marginTop:100}}>
      <MediaHubList navigation={props.navigation} detailsLink="MediaHubDetailsPage" />
      </View>
    </BackGroundGenerator>
  );
};

export default MediaHub;
