import React, { useState } from 'react';
import { ScrollView, View } from 'react-native';
import styled from '@emotion/native';
import { ComboboxItem } from './comboboxItem';
import ComboboxList from './comboboxList';
import { ODSText } from '../ODSText';
import { ODSIcon } from '../ODSIcon';
import { useTheme } from '@emotion/react';

const LabelText = styled.Text`
  color: ${(props) => props.theme.colors.text.primary};
  font-size: 14px;
`;

interface ComboboxProps {
  data: any[];
  renderItem: ({
    item,
    index,
    handleAction,
  }: {
    item: any;
    index: number;
    handleAction: (value: any) => void;
  }) => JSX.Element; // Render function for items
  leftIcon?: string;
  renderLeftIcon?: React.ReactNode;
  renderRightIcon?: React.ReactNode;
  rightIcon?: string;
  width?: number;
  height?: number;
  placeholder?: string;
  value?: any;
  disabled?: boolean;
  renderLabel?: React.ReactNode;
  label?: string;
  error?: boolean;
  onChange?: any;
  hints?: {
    hint: string;
    state: 'success' | 'error' | 'disabled' | 'default';
  }[];
}

export function ODSCombobox({
  data,
  renderItem,
  leftIcon,
  renderLeftIcon,
  rightIcon = 'TB-Caret-Down',
  hints,
  renderRightIcon,
  width,
  height,
  placeholder,
  value,
  disabled = false,
  renderLabel,
  label,
  error = false,
  onChange = {},
  ...props
}) {
  const [visible, setVisible] = useState(false);
  const theme = useTheme();
  const [selectedValue, setComboboxedValue] = useState(value);

  const handleAction = (actionValue: any) => {
    setComboboxedValue(actionValue);
    setVisible(false);
    onChange(actionValue);
  };
  const getHintTextColor = (state: 'disabled' | 'error' | 'success' | 'default') => {
    switch (state) {
      case 'disabled':
        return theme.colors.disabled.text;
      case 'error':
        return theme.colors.feedback.error[700];
      case 'success':
        return theme.colors.feedback.success[700];
      default:
        return theme.colors.text.primary;
    }
  };
  const renderText = selectedValue ? selectedValue.label : placeholder;
  return (
    <View style={{ flex: 1, marginBottom: 10, position: 'relative' }}>
      {label ? (
        <ODSText
          type="sm-bold"
          color={disabled ? theme.colors.disabled.text : theme.colors.text.primary}
          paddingVertical={8}>
          {label}
        </ODSText>
      ) : (
        <View style={{ paddingVertical: 4 }}>{renderLabel}</View> || null
      )}
      <StyledPressable
        width={width}
        height={height}
        onPress={() => !disabled && setVisible(!visible)}
        error={error}
        disabled={disabled}
        isFocused={visible}
        {...props}>
        {leftIcon && (
          <StyledLeftIconContainer>
            <ODSIcon size={22} icon={leftIcon} />
          </StyledLeftIconContainer>
        )}
        {renderLeftIcon && <StyledLeftIconContainer>{renderLeftIcon}</StyledLeftIconContainer>}
        <ODSText
          flex={1}
          paddingHorizontal={12}
          type="md"
          color={selectedValue ? theme.colors.text.primary : theme.colors.disabled.text}>
          {renderText}
        </ODSText>
        {rightIcon && (
          <StyledRightIconContainer>
            <ODSIcon size={22} icon={visible ? 'TB-Caret-Up' : rightIcon} />
          </StyledRightIconContainer>
        )}
        {renderRightIcon && <StyledRightIconContainer>{renderRightIcon}</StyledRightIconContainer>}
      </StyledPressable>
      {hints !== null &&
        hints !== undefined &&
        hints.map((item, index) => (
          <ODSText paddingVertical={10} type="xs" color={getHintTextColor(item.state)}>
            {item.hint}
          </ODSText>
        ))}
      {visible && (
        <ComboboxList width={width} height={height} label={label}>
          <ScrollView style={{ position: 'relative' }}>
            {data.map((item, index) => {
              if (renderItem) {
                return renderItem({ item, index, handleAction });
              }
              return (
                <ComboboxItem
                  width={width}
                  height={height}
                  key={index}
                  value={item}
                  selectedValue={selectedValue || {}}
                  action={() => handleAction(item)}>
                  <ODSIcon icon={item?.icon} size={20} />
                  <ODSText color={theme.colors.text.primary} type="md">
                    {item.label}
                  </ODSText>
                </ComboboxItem>
              );
            })}
          </ScrollView>
        </ComboboxList>
      )}
    </View>
  );
}

const StyledPressable = styled.TouchableOpacity<{
  width?: number;
  height?: number;
  error: boolean;
  disabled: boolean;
}>`
  border-radius: 4px;
  flex-direction: row;
  align-items: center;
  position: relative;
  padding: 0 4px;
  border: ${(props) =>
    props.error
      ? `1px solid ${props.theme.colors.feedback.error[100]}`
      : props.isFocused
      ? `2px solid ${props.theme.colors.other['border-color']}`
      : `1px solid ${props.theme.colors.other['border-neutral']}`};

  ${({ width, height }) => {
    return `
        width: ${width ? width : '100%'};
        height: ${height ? height : 44}px;
      `;
  }};
  ${({ disabled, error, theme }) => `
  background-color: ${theme.colors.elements.invert};
  opacity: ${disabled ? 0.5 : 1};
  `}
`;

const StyledRightIconContainer = styled.View`
  margin-right: 8px;
`;
const StyledLeftIconContainer = styled.View`
  margin-left: 8px;
`;

export default ODSCombobox;
