import React, { useState } from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import CustomPicker from './CustomDropdown';
import { Typography } from '../../../openFlow/style';
import getPlatformType from '../../../../helpers/getPlatform';
import { COLORS } from '../../../../constants/colorPallete';
import { FontAwesome } from '@expo/vector-icons';
import WideButton from '../../../../commonComponents/wideButton';
const InfohubFilters = (props) => {
  const DateValues = [
    { value: '2024', label: '2024' },
    { value: '2023', label: '2023' },
    { value: '2022', label: '2022' },
    { value: '2021', label: '2021' },
    { value: '2020', label: '2020' },
  ];
  const SortValues = [
    { value: 0, label: 'Most views first' },
    { value: 1, label: 'Less views first' },
    { value: 2, label: 'Most recent first' },
    { value: 3, label: 'Oldest first' },
  ];
  const [selectedDate, setSelectedDate] = useState(null);
  const layoutType = getPlatformType();
  const [showFilter, setShowFilter] = useState(true);
  const [filters,setFilters]=useState({
    category:'',
    source:'',
    year:''
  });
  const [sort,setSort]=useState({
    sortBy:'',
    sortOrder:''
  });
  const transformedCategory = props?.categories.map(item => ({
    value: item.category_id,
    label: item.name
  }));
  const transformedSources = props?.sources.map(item => ({
    value: item.source_id,
    label: item.name
  }));

  return (
    <>
      {layoutType == 'phone' && (
        <TouchableOpacity
          onPress={() => setShowFilter(!showFilter)}
          style={{
            width: '100%',
            height: 50,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: COLORS.LGREEN,
            flexDirection: 'row',
            marginTop: 20,
            borderRadius: showFilter ? 0 : 8,

          }}
        >
          <FontAwesome name="filter" size={22} color="white" />
          <Typography top={5} left={10} raj fontSize={16}>
            Filter and sorting
          </Typography>
        </TouchableOpacity>
      )}
      {(showFilter || layoutType != 'phone') && (
        <View
          style={{
            borderBottomColor: '#b2b2b2',
            borderBottomWidth: layoutType == 'phone' ? 1 : 1,
            paddingBottom: layoutType == 'phone' ? 0 : 30,
            marginBottom: layoutType == 'phone' ? 10 : 30,
            backgroundColor: layoutType == 'phone' ? '#fafafa' : 'white',
            borderWidth: layoutType == 'phone' ? 1 : 0,
            borderColor: '#b2b2b2',
            width: '100%',
          }}
        >
          <View
            style={{
              flexDirection: layoutType == 'phone' ? 'column' : 'row',
              width: '100%',
              padding: 10,
              marginTop: 15,
              justifyContent:"space-between"
            }}
          >
            <View style={{ width: layoutType=="phone"?'100%':'75%' }}>
              <Typography raj fontSize={14} bottom={5} color={'#4C4E64DE'} align={'flex-start'}>
                Filter by
              </Typography>
              <View
                style={{
                  flexDirection: layoutType == 'phone' ? 'column' : 'row',
                  justifyContent: 'space-between',
                  width:'100%'
                }}
              >
                <CustomPicker
                  data={DateValues}
                  value={filters?.year}
                  onSelect={(e)=>setFilters({...filters,year:e})}
                  label={'Date'}
                />
                <CustomPicker
                  data={transformedCategory}
                  value={filters?.category}
                  onSelect={(e)=>setFilters({...filters,category:e})}
                  label={'Category'}
                />
                <CustomPicker
                  data={transformedSources}
                  value={filters?.source}
                  onSelect={(e)=>setFilters({...filters,source:e})}
                  label={'Source'}
                />
              </View>
            </View>
            <View style={{ width: '2%' }} />
            <View style={{ width: layoutType=="phone"?'100%':'80%' }}>
              <Typography raj fontSize={14} bottom={5} color={'#4C4E64DE'} align={'flex-start'}>
                Sort by
              </Typography>
              <View
                style={{
                  flexDirection: layoutType == 'phone' ? 'column' : 'row',
                  justifyContent: 'space-between',
          
                }}
              >
                <CustomPicker
                  data={SortValues}
                  onSelect={(e)=>{
                    if (e == 0) {
                      setSort({ ...sort, sortBy: 'number_of_views', sortOrder: 'DESC' })
                  }
                  else if (e == 1) {
                      setSort({ ...sort, sortBy: 'number_of_views', sortOrder: 'ASC' })
                  }
                  else if (e == 2) {
                      setSort({ ...sort, sortBy: 'createdAt', sortOrder: 'DESC' })
                  }
                  else {
                      setSort({ ...sort, sortBy: 'createdAt', sortOrder: 'ASC' })
                  }
                  }}                 
                  label={'Views'}
                />
              
              </View>
            </View>
          </View>
          <View style={{flexDirection:'row',alignSelf:'flex-end',marginTop:15,marginBottom:20,justifyContent:layoutType=="phone"?'center':'flex-end',width:'100%'}}>
          <WideButton
                  onPress={() => {
                   setFilters({
                    category:'',
                    source:'',
                    year:''
                  });
                  setSort({
                    sortBy:'',
                    sortOrder:''
                  })
                  props?.onApply({filters:{
                    category:'',
                    source:'',
                    year:''
                  },sort:{
                    sortBy:'',
                    sortOrder:''
                  }})
                  }}
                  isRaj={true}
                  isLGreen={true}
                  backgroundColor={COLORS.LGREEN}
                  borderRadius={15}
                  childText="RESET"
                  height={35}
                  width={layoutType=="phone"?'40%':150}
                  buttonType={'stripe'}
                  gradiant
                  alignSelf={'flex-end'}
                  textSize={'18px'}
                />
                <View style={{width:20}}/>
                 <WideButton
                  onPress={() => {
                   props?.onApply({filters,sort})
                  }}
                  isRaj={true}
                  isLGreen={true}
                  borderRadius={15}
                  childText="APPLY"
                  height={35}
                  width={layoutType=="phone"?'40%':150}                  
                  buttonType={'solid'}
                  gradiant
                  alignSelf={'flex-end'}
                  textSize={'18px'}
                />
          </View>

         
        </View>
      )}
    </>
  );
};
const styles = StyleSheet.create({
});
export default InfohubFilters;
