import React from "react";
import { Image, Linking, TouchableOpacity, View } from "react-native";
import { useTheme } from "@emotion/react";
import { ODSText } from "../../../openbrix-design-system/ui/atoms/ODSText";
import getScreenType from "../../../openbrix-design-system/utils/getScreenType";
import { Body, Content, HeaderContent, HeaderImage, Picture1 } from "./styles";
import Footer from "./Footer";
import Wrapper from "./Wrapper";
const HeaderWeb = require('../../../openbrix-design-system/images/tlyfe-pages/rr-desktop.png');
const HeaderTab = require('../../../openbrix-design-system/images/tlyfe-pages/rr-tab.png');
const HeaderMobile = require('../../../openbrix-design-system/images/tlyfe-pages/rr-mobile.png');
const Picture = require('../../../openbrix-design-system/images/tlyfe-pages/rent-ready/picture1.png');
const FooterWeb=require('../../../openbrix-design-system/images/tlyfe-pages/rent-ready/footer-web.png');
const FooterTab=require('../../../openbrix-design-system/images/tlyfe-pages/rent-ready/footer-tab.png');
const FooterMobile=require('../../../openbrix-design-system/images/tlyfe-pages/rent-ready/footer-mobile.png');


const RentReadyLanding = () => {
  const { colors } = useTheme();
  const layout = getScreenType();
  return (
    <><Wrapper
    theme={colors.transparency.blue}
    drawerColor={colors.theme.blue}
    type="rent"
    headerTitle={'Secure your rental home with tlyfe'}
    headerBody={'Pre-qualify yourself to stand out from the crowd!'}
    headerImage={layout == "web" ? HeaderWeb : layout == "tab" ? HeaderTab : HeaderMobile}
      rightImage={Picture}
      leftText={`Give yourself the best chance of securing your next rental property by using our Pre-qualification tool to become a ‘Rent Ready’ Tenant.

Share the results with your Agent to put yourself ahead of the crowd!!`}
      footerImage={layout == "web" ? FooterWeb : layout == "tab" ? FooterTab : FooterMobile}
    >
      <Body>
        <View style={{ padding: layout == "phone" ? 24 : 0,marginBottom:32 }}>
          <ODSText marginTop={layout == "phone" ? 16 : 0} type='h2-light' color={colors.text.primary}>Become a Rent Ready tenant</ODSText>
          <View style={{ flexDirection: 'row', flexWrap: 'wrap', marginTop: layout == "web" ? 48 : 24, gap: layout == "web" ? 32 : 24 }}>
            <Content>
              <ODSText type="h4" color={colors.text.primary}>Affordability</ODSText>
              <ODSText marginTop={20} type="h6-light" color={colors.text.primary}>{`Understand how much rent you can afford.

Give yourself the best chance of securing your next rental property by using our 

Pre-Qualification tool to become a ‘Rent Ready’ Tenant.

Share the results with your agent to secure priority viewings!`}</ODSText>
            </Content>
            {layout!='web' && <View style={{ width: '100%', height: 0.25, backgroundColor: colors.text.primary }} />}
            <Content>
              <ODSText type="h4" color={colors.text.primary}>Right-to-Rent</ODSText>
              <ODSText marginTop={20} type="h6-light" color={colors.text.primary}>{`You are required by law to prove your ‘right-to-rent’ in England.

Using our quick and easy to follow process you can validate your right to rent at the click of a button,

ideal for overseas Tenants!`}</ODSText>

            </Content>
             <View style={{ width: '100%', height: 0.25, backgroundColor: colors.text.primary }} />
            <Content>
              <ODSText type="h4" color={colors.text.primary}>ID verification</ODSText>
              <ODSText marginTop={20} type="h6-light" color={colors.text.primary}>{`Verify your ID and share it securely with your Agent or Landlord.

Once you have your ID verified you can share your verified identity as many times as you like.

You decide who sees this information, giving you complete control!`}</ODSText>
            </Content>
            {layout!='web' && <View style={{ width: '100%', height: 0.25, backgroundColor: colors.text.primary }} />}
            <Content>
              <ODSText type="h4" color={colors.text.primary}>Pre-qualification reference</ODSText>
              <ODSText marginTop={20} type="h6-light" color={colors.text.primary}>{`Validate that you are ‘Rent-Ready’ and give yourself a head start in the rental process.
              
Before viewing properties, you can present your agent or landlord with evidence of your affordability and confirmation that you meet the criteria required to rent a property, all verified by our trusted partner!`}</ODSText>

            </Content>
          </View>
        </View>
      </Body>
      <View style={{width:'100%',height:layout=="phone"?252:190,backgroundColor:colors.theme.pink,justifyContent:'center',alignItems:'center'}}>
        <ODSText type="h3" color={colors.text.inverted}>Download today!</ODSText>
        <View style={{ flexDirection: layout == "phone" ? 'column' : 'row', gap: 16, alignItems: 'center' ,marginTop:32}}>
            <TouchableOpacity onPress={() => window.open('https://apps.apple.com/in/app/tlyfe/id6449941281')}>
            <Image source={require('../../../openbrix-design-system/images/appstore.png')} style={{ width: 180, height: 52 }} />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => window.open('https://play.google.com/store/apps/details?id=com.tlyfe&hl=en_IN&gl=US')}>
            <Image source={require('../../../openbrix-design-system/images/playstore.png')} style={{ width: 180, height: 52 }} />
            </TouchableOpacity>
            </View>
      </View>

    </Wrapper>
</>
  )
}
export default RentReadyLanding;