import Regex from "./regex";
import moment from "moment";
import intl from "intl";
import "intl/locale-data/jsonp/en-GB";

/**
 * needed to eslint doesn't remove the import intl line
 */
let a = intl;
export const EmailValidation = (text) => {
  let email = text.trim();

  if (email.length == 0) {
    return {
      msg: "Email is a required field",
      status: false,
    };
  } else {
    if (!Regex.validateEmail(email)) {
      return {
        msg: "Please enter a valid email address.",
        status: false,
      };
    } else {
      return {
        msg: "Valid",
        status: true,
      };
    }
  }
};
export const PasswordValidation = (pass) => {
  if (pass.length == 0) {
    return {
      msg: "Password is a required field",
      status: false,
    };
  } else {
    if (!Regex.validatePassword(pass)) {
      return {
        msg: "Password must contain atleast 6 words.",
        status: false,
      };
    } else {
      return {
        msg: "Valid",
        status: true,
      };
    }
  }
};
export const NumValidation = (number) => {
  const numReg = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
  if (!number) {
    return {
      msg: "Valid",
      status: true,
    };
  }
  if (numReg.test(number)) {
    return {
      msg: "Valid",
      status: true,
    };
  } else {
    return {
      msg: "Failed to validate phone number",
      status: false,
    };
  }
};
export const NameValidation = (name) => {
  if (name.length == 0) {
    return {
      msg: "Name is a required field",
      status: false,
    };
  } else {
    return {
      msg: "Valid",
      status: true,
    };
  }
};
export const SubjectValidation = (name) => {
  if (name.length == 0) {
    return {
      msg: "Subject is a required field",
      status: false,
    };
  } else {
    if (!Regex.validateName(name)) {
      return {
        msg: "Invalid Subject",
        status: false,
      };
    } else {
      return {
        msg: "Valid",
        status: true,
      };
    }
  }
};
export const MobileValidation = (mobile) => {
  if (mobile.length == 0) {
    return {
      msg: "Valid",
      status: true,
    };
  } else {
    if (!Regex.validateNumbers(mobile) || mobile.length < 7 || mobile.length > 12) {
      return {
        msg: "Wrong Contact number",
        status: false,
      };
    } else {
      return {
        msg: "Valid",
        status: true,
      };
    }
  }
};
export const PasswordComparisonValidation = (pass1, pass2) => {
  if (pass1 !== pass2) {
    return {
      msg: "New password and retyped passwords should match",
      status: false,
    };
  } else {
    return {
      msg: "Valid",
      status: true,
    };
  }
};
export const LocationValidation = (location) => {
  if (location.length == 0) {
    return {
      msg: "Location is a required field",
      status: false,
    };
  } else {
    return {
      msg: "Valid",
      status: true,
    };
  }
};
export const FormatDate = (date) => {
  return typeof date == "date"
    ? moment(date)
    .format("DD/MM/YYYY")
    : moment(new Date(date))
    .format("DD/MM/YYYY");
};
export const FormatPrice = (price) => {
  /*
    
   if(price==null || price == undefined){
      return null
   }
   else{
      var newPrice=price.toFixed(2);
      return(
         typeof(price)!="number"?price:(newPrice).replace(/\d(?=(\d{3})+\.)/g, '$&,')
      );
   }*/
  return price;
};
export const PriceFormat = (price) => {
  // 
  // Intl.NumberFormat = IntlPolyfill.NumberFormat;

  // var format = new Intl.NumberFormat("en-GB", {
  //   style: "currency",
  //   currency: "GBP",
  //   minimumFractionDigits: 0,
  // });

  if (price == null || price == undefined) {
    return null;
  } else {
    //   var newPrice=price.toFixed(2);
    //   return(
    //      typeof(price)!="number"?price:parseInt((newPrice).replace(/\d(?=(\d{3})+\.)/g, '$&,'))
    //   );
    // return format.format(price);
    return price;
  }
};
