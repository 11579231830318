import React, { useState } from 'react';
import {
    Text,
    StyleSheet,
    TouchableOpacity,
    View,
    ImageBackground,
    Image,
} from 'react-native';
import { ODSIcon } from '../ODSIcon';
import { ODSText } from '../ODSText';
import getScreenType from '../../../utils/getScreenType';
import { useTheme } from '@emotion/react';
import ODSButton from '../ODSButton';
import ODSKnob from '../ODSKnob';
const webImage1 = require('../../../images/dashboard/tenancy-web1.png')
const mobileImage1 = require('../../../images/dashboard/tenancy-mobile2.png')
const webImage = require('../../../images/dashboard/tenancy-web.png')
const mobileImage = require('../../../images/dashboard/tenancy-mobile.png')


const ODSTenancyTile = ({
    onPress,
    movedIn,
    actionRequired,
    percentage = 0,
    actionButtons
}) => {
    const theme = useTheme();
    const layout = getScreenType();

    if (movedIn) {
        return (
            <View style={[styles(theme.colors).containerStyle, { height: 259 }]}>

                <View style={{ width: '100%', height: '100%' }}>
                    <Image source={layout == "phone" ? mobileImage1 : webImage1} style={{ width: '100%', height: 109, borderTopLeftRadius: 12, borderTopRightRadius: 12 }} />
                    <View style={{ justifyContent: 'center', height: 150, alignItems: 'center', width: '100%' }}>
                        <ODSText color={theme.colors.text.primary} type={'h6'}>Congrats, you’ve moved in!</ODSText>
                        <ODSButton
                            restStyle={{ marginTop: 24 }}
                            type="secondary"
                            disabled={false}
                            onPress={onPress}
                        >
                            Check your details
                        </ODSButton>
                    </View>
                </View>
            </View>
        )
    }

    return (
        <View style={[styles(theme.colors).containerStyle, { height: actionRequired ? 427 : 347 }]}>
            <ImageBackground
                style={{ width: '100%', height: 109, alignItems: 'flex-end' }}
                source={layout == "phone" ? mobileImage : webImage}
                imageStyle={{ borderTopLeftRadius: 12, borderTopRightRadius: 12 }}
            >
                <ODSKnob
                    size={65}
                    strokeWidth={3}
                    progressPercent={percentage}
                    text={percentage + "%"}
                />
            </ImageBackground>
            <View style={{ padding: 24, justifyContent: 'center', height: actionRequired ? 318 : 218 }}>
                <ODSText color={theme.colors.text.primary} type={'h5'} marginTop={actionRequired ? 12 : 32}>
                    My Tenancy
                </ODSText>
                <ODSText marginTop={actionRequired ? 12 : 16} color={theme.colors.text.primary} type={'sm'}>
                    Your letting agent has chosen to use Openflo to help you move into your new property. As you progress the through your move in, there will be actions to complete which will appear here.
                </ODSText>
                {(actionRequired && actionButtons.length>0) ?
                    <View style={{ width: '100%', justifyContent: 'center', alignItems: 'center', marginTop: 16 }}>
                        <View style={{ width: '100%', padding: 20, borderWidth: 1, borderColor: theme.colors.feedback.error[400], borderRadius: 8 }}>
                            <ODSText color={theme.colors.feedback.error[400]} type={'sm'}>Action Required
                            </ODSText>
                            {actionButtons &&
                                <TouchableOpacity onPress={() => actionButtons[0].navigate()} style={{ flexDirection: 'row', alignItems: 'center', marginTop: layout == "phone" ? 8 : 16 }}>
                                    <ODSText textDecorationLine={'underline'} color={theme.colors.text.primary} type={'md'}>{actionButtons[0]?.button_text}
                                    </ODSText>
                                    <View style={{ width: 20, height: 20, marginLeft: 10, backgroundColor: theme.colors.button.main, justifyContent: 'center', alignItems: 'center', borderRadius: 20 }}>
                                        <ODSIcon icon='TB-Arrow-Right' size={12} color={theme.colors.elements.invert} />
                                    </View>
                                </TouchableOpacity>}
                        </View>
                        <ODSButton
                            restStyle={{ alignSelf: 'flex-end', marginTop: 16 }}
                            type="primary"
                            disabled={false}
                            onPress={onPress}
                        >
                            Go to My Tenancy
                        </ODSButton>
                    </View> : <ODSButton
                        restStyle={{ alignSelf: 'flex-end', marginTop: actionRequired ? 12 : 32 }}
                        type="primary"
                        disabled={false}
                        onPress={onPress}
                    >
                        Go to My Tenancy
                    </ODSButton>}
            </View>
        </View>
    );
};

const styles = (theme) =>
    StyleSheet.create({
        containerStyle: {
            width: getScreenType() == "phone" ? "96%" : 405,
            borderRadius: 12,
            shadowColor: '#101828',
            shadowOffset: { width: 0, height: 1 },
            shadowOpacity: 0.1,
            shadowRadius: 4,
            elevation: 2,
            backgroundColor: theme.neutral[100],
            alignSelf: 'center'
        },
    });

export default ODSTenancyTile;
