import { debounce } from 'lodash';
import draftToHtml from 'draftjs-to-html';
import { AntDesign } from '@expo/vector-icons';
import { Link } from '@react-navigation/native';
import { useDispatch, useSelector } from 'react-redux';
import { View, Image, StyleSheet, Text, TextInput, TouchableOpacity } from 'react-native';
import React, { useEffect, useState, useCallback } from 'react';
import { Feather } from '@expo/vector-icons';
import { Typography } from '../openFlow/style';
import { COLORS } from '../../constants/colorPallete';
import getPlatformType from '../../helpers/getPlatform';
import WideButton from '../../commonComponents/wideButton';
import { MediaBox, MediaWrapper, MediaListWrapper } from './styles';
import VimeoPlayer from '../../commonComponents/youtubePlayer/vimeoPlayer';
import { getArticles, getCategories, getSources, getVideos } from '../../redux/actions/mediaHub/index';
import YoutubePlayer from '../../commonComponents/youtubePlayer/youtubePlayer';
import Loader from '../../commonComponents/activityIndicator/activityIndicator';
import InfohubFilters from '../LandingPages/common/InfohubFilters';
const layout = getPlatformType();
const MediaHubList = (props) => {
  const pageSize = 10;
  const dispatch = useDispatch();
  const [type, setType] = useState('web');
  const [videos, setVideos] = useState([]);
  const [search, setSearch] = useState('');
  const [articles, setArticles] = useState([]);
  const [videoPage, setVideoPage] = useState(0);
  const [articlePage, setArticlePage] = useState(0);
  const [totalVideos, setTotalVideos] = useState(0);
  const [selectedTab, setSelectedTab] = useState(0);
  const [totalArticles, setTotalArticles] = useState(0);
  const isLoading = useSelector((state) => state.mediaReducer?.loading);
  const [categories,setCat]=useState([]);
  const [sources,setSources]=useState([]);
  const [selectedDate,setDate]=useState('');
  const [selectedCategory,setCategory]=useState('');
  const [selectedSource,setSelectedSource]=useState('');
  const [sortBy,setSortBy]=useState('');
  const [sortOrder,setSortOrder]=useState('desc');
  const [onApplyClick,setApplyClick]=useState(false);


  useEffect(() => {
    // getVideos1(videoPage, search);
    // getArticles1(articlePage, search);
    getCat();
    getSource()
  }, []);

  useEffect(()=>{
    setArticlePage(0);
    setVideoPage(0);
    if(selectedTab==0){
      getVideos1(videoPage, search,true);
    }
    else{
      getArticles1(articlePage, search,true);
    }
  },[onApplyClick,selectedTab])

  const getArticles1 = (page, searchValue, clear = false) => {
    dispatch(
      getArticles(page, pageSize, sortOrder,sortBy,selectedCategory,selectedSource,selectedDate,searchValue, (data) => {
        setTotalArticles(data.count);
        if (clear) {
          setArticles([...data.data]);
        } else {
          setArticles([...articles, ...data.data]);
        }
      })
    );
  };

  const getVideos1 = (page, searchValue, clear = false) => {
    dispatch(
      getVideos(page, pageSize, sortOrder,sortBy,selectedCategory,selectedSource,selectedDate ,searchValue, (data) => {
        setTotalVideos(data.count);
        if (clear) {
          setVideos([...data.data]);
        } else {
          setVideos([...videos, ...data.data]);
        }
      })
    );
  };
  const getCat = () => {
    dispatch(
      getCategories((data) => {
        setCat(data?.rows)
      })
    );
  };
  const getSource = () => {
    dispatch(
      getSources((data) => {
        setSources(data?.rows)
      })
    );
  };

  const handleMore = () => {
    const page = articlePage + 1;
    setArticlePage(page);
    getArticles1(page, search);
  };

  const handleMore1 = () => {
    const page = videoPage + 1;
    setVideoPage(page);
    getVideos1(page, search);
  };

  const clearSearch = () => {
    setSearch('');
    getVideos1(0, '', true);
    getArticles1(0, '', true);
  };

  function handleSearch(value) {
    setSearch(value);
    if (value.length > 2) {
      getVideos1(0, value, true);
      getArticles1(0, value, true);
    }
  }

  const renderDescription = (data) => {
    // const rawContent = typeof data.description === 'string' ? JSON.parse(data.description) : data.description;
    const descriptionData = JSON.parse(data.description);
    const textBlocks = descriptionData.blocks.map((block) => block.text).join('');

    // const text = draftToHtml(rawContent)
    return <Text numberOfLines={2}>{textBlocks}</Text>;
  };
  const renderDataTopStory = (data, isVideo) => {
    return (
      <View
        style={{
          flexDirection: layout == 'phone' ? 'column' : 'row',
          width: layout == 'phone' ? '100%' : 830,
          marginVertical: 15,
          marginHorizontal: layout == 'phone' ? 0 : 15,
          height: layout == 'phone' ? 423 : 270,
          boxShadow: '1px 3px 8px rgba(0, 0, 0, 0.15)',
          borderWidth: 1,
          borderColor: 'rgba(76, 78, 100, 0.38)',
          borderRadius: 8,
        }}
      >
        {isVideo
          ? data?.link &&
            data?.link != '' && (
              <View
                style={{
                  width: layout == 'phone' ? '100%' : '60%',
                  height: layout == 'phone' ? '55%' : '100%',
                }}
              >
                <View
                  style={{
                    width: '100%',
                    height: layout == 'phone' ? '60%' : '70%',
                    borderTopLeftRadius: 8,
                    borderTopRightRadius: layout == 'phone' ? 8 : 0,
                  }}
                >
                  {data.video_type == 'Youtube' ? (
                    <YoutubePlayer
                      url={data.link}
                      width="100%"
                      height="100%"
                      style={{
                        borderTopLeftRadius: 8,
                        borderTopRightRadius: layout == 'phone' ? 8 : 0,
                        overflow: 'hidden',
                      }}
                    />
                  ) : (
                    <VimeoPlayer
                      url={data.link}
                      width="100%"
                      height="100%"
                      style={{
                        borderTopLeftRadius: 8,
                        borderTopRightRadius: layout == 'phone' ? 8 : 0,
                      }}
                    />
                  )}
                </View>
                <View
                  style={{
                    height: layout == 'phone' ? '40%' : '30%',
                    borderBottomLeftRadius: layout == 'phone' ? 0 : 8,
                    backgroundColor: COLORS.LGREEN,
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography raj size={40}>
                    TOP STORY
                  </Typography>
                </View>
              </View>
            )
          : (data?.image || data?.image != '') && (
              <View
                style={{
                  width: layout == 'phone' ? '100%' : '60%',
                  height: layout == 'phone' ? '55%' : '100%',
                }}
              >
                <Image
                  source={data?.cover_image}
                  // resizeMode='stretch'
                  style={{
                    width: '100%',
                    height: layout == 'phone' ? '60%' : '70%',
                    borderTopLeftRadius: 8,
                    borderTopRightRadius: layout == 'phone' ? 8 : 0,
                  }}
                />
                <View
                  style={{
                    height: layout == 'phone' ? '40%' : '30%',
                    borderBottomLeftRadius: layout == 'phone' ? 0 : 8,
                    backgroundColor: COLORS.LGREEN,
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography raj size={40}>
                    TOP STORY
                  </Typography>
                </View>
              </View>
            )}

        <View
          style={{
            width: layout == 'phone' ? '100%' : '40%',
            height: layout == 'phone' ? '45%' : '100%',
            justifyContent: 'space-between',
            padding: 20,
          }}
        >
          <View style={{ marginTop: data?.image != '' ? 0 : 20 }}>
            <Typography
              top={5}
              numberOfLines={3}
              align={'left'}
              raj
              bold={'600'}
              color={'#1C1C1C'}
              size={layout == 'phone' ? '20px' : '24px'}
            >
              {data.title}
            </Typography>
            {/* {renderDescription(data)}  */}
          </View>
          <WideButton
            onPress={() => {
              props.navigation.navigate(props.detailsLink, { id: data.info_id });
            }}
            isRaj={true}
            isLGreen={true}
            borderRadius={15}
            childText="READ MORE"
            height={35}
            width={layout == 'phone' ? '60%' : '45%'}
            buttonType={'solid'}
            gradiant
            alignSelf={'flex-end'}
            textSize={'18px'}
            ricon={<AntDesign name="arrowright" size={20} color="white" />}
          />
        </View>
      </View>
    );
  };
  const renderData = (data) => {
    return (
      <View
        style={{
          width: layout == 'phone' ? '100%' : 390,
          marginVertical: 15,
          marginHorizontal: layout == 'phone' ? 0 : 15,
          height: layout == 'phone' ? 270 : 300,
          boxShadow: '1px 3px 8px rgba(0, 0, 0, 0.15)',
          borderWidth: 1,
          borderColor: 'rgba(76, 78, 100, 0.38)',
          borderRadius: 8,
        }}
      >
        {(data?.image || data?.image != '') && (
          <Image
            source={data?.cover_image}
            resizeMode="stretch"
            style={{
              width: '100%',
              borderTopRightRadius: 8,
              borderTopLeftRadius: 8,
              height: '45%',
            }}
          />
        )}
        <View
          style={{ marginHorizontal: 20, marginTop: data?.image != '' ? 0 : 20, height: '38%' }}
        >
          <Typography
            top={5}
            numberOfLines={3}
            align={'left'}
            raj
            bold={'600'}
            color={'#1C1C1C'}
            size={layout == 'phone' ? '20px' : '24px'}
          >
            {data.title}
          </Typography>
          {/* {renderDescription(data)}  */}
        </View>
        <View style={{ marginRight: 20 }}>
          <WideButton
            onPress={() => {
              props.navigation.navigate(props.detailsLink, { id: data.info_id });
            }}
            isRaj={true}
            isLGreen={true}
            borderRadius={15}
            childText="READ MORE"
            height={35}
            width={layout == 'phone' ? '60%' : '40%'}
            buttonType={'solid'}
            gradiant
            alignSelf={'flex-end'}
            textSize={'18px'}
            ricon={<AntDesign name="arrowright" size={20} color="white" />}
          />
        </View>
      </View>
    );
  };

  const renderVideo = (data) => {
    return (
      <View
        style={{
          width: layout == 'phone' ? '100%' : 390,
          marginVertical: 15,
          marginHorizontal: layout == 'phone' ? 0 : 15,
          height: layout == 'phone' ? 270 : 300,
          boxShadow: '1px 3px 8px rgba(0, 0, 0, 0.15)',
          borderWidth: 1,
          borderColor: 'rgba(76, 78, 100, 0.38)',
          borderRadius: 8,
        }}
      >
        {data?.link && data?.link != '' && (
          <View style={{ height: '45%', borderTopRightRadius: 8, borderTopLeftRadius: 8 }}>
            {data.video_type == 'Youtube' ? (
              <YoutubePlayer
                url={data.link}
                width="100%"
                height="100%"
                style={{ borderTopRightRadius: 8, borderTopLeftRadius: 8, overflow: 'hidden' }}
              />
            ) : (
              <VimeoPlayer
                url={data.link}
                width="100%"
                height="100%"
                style={{ borderTopRightRadius: 8, borderTopLeftRadius: 8 }}
              />
            )}
          </View>
        )}
        <View
          style={{
            height: '38%',
            flexDirection: data?.link != '' ? 'row' : 'column',
            paddingHorizontal: 20,
            marginTop: data?.link != '' ? 0 : 20,
          }}
        >
          <Typography
            align={'left'}
            top={5}
            numberOfLines={3}
            raj
            bold={'600'}
            color={'#1C1C1C'}
            size={layout == 'phone' ? '20px' : '24px'}
          >
            {data.title}
          </Typography>
        </View>
        <View style={{ marginRight: 20 }}>
          <WideButton
            onPress={() => {
              props.navigation.navigate(props.detailsLink, { id: data.info_id });
            }}
            isRaj={true}
            isLGreen={true}
            borderRadius={15}
            childText="WATCH VIDEO"
            height={35}
            width={layout == 'phone' ? '60%' : '40%'}
            buttonType={'solid'}
            alignSelf={data?.link != '' ? 'flex-end' : 'flex-end'}
            gradiant
            textSize={'18px'}
            ricon={<AntDesign name="arrowright" size={20} color="white" />}
          />
        </View>
      </View>
    );
  };
  const renderArticles = () => {
    return articles.map((article, index) => {
      if (article?.is_top_story) {
        return renderDataTopStory(article);
      } else if (article.media_type === 'videos') {
        return renderVideo(article);
      } else {
        return renderData(article);
      }
    });
  };

  const renderVideos = () => {
    return videos.map((video, index) => {
      if (videos?.is_top_story) {
        return renderDataTopStory(video, true);
      } else if (video.media_type == 'videos') {
        return renderVideo(video);
      } else {
        return renderData(video);
      }
    });
  };

  return (
    <>
      <MediaListWrapper>
        <View
          style={{
            flexDirection: layout == 'phone' ? 'column-reverse' : 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <View style={{ flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>
            <TouchableOpacity
              onPress={() => {
                setSelectedTab(0);
                clearSearch();
              }}
            >
              <Typography
                raj
                bold={'600'}
                color={selectedTab == 0 ? COLORS.gradientColor3 : 'rgba(76, 78, 100, 0.38)'}
                underline={selectedTab == 0 ? true : false}
                size={type !== 'web' ? '20px' : '22px'}
                line={type !== 'web' ? '2px' : '22px'}
                top={'0px'}
              >
                Articles & Info
              </Typography>
            </TouchableOpacity>
            <View
              style={{
                height: 30,
                width: 1,
                backgroundColor: 'rgba(76, 78, 100, 0.38)',
                marginHorizontal: layout == 'phone' ? 30 : 60,
              }}
            />
            <TouchableOpacity
              onPress={() => {
                setSelectedTab(1);
                setSearch('');
              }}
            >
              <Typography
                raj
                bold={'600'}
                color={selectedTab == 1 ? COLORS.gradientColor3 : 'rgba(76, 78, 100, 0.38)'}
                underline={selectedTab == 1 ? true : false}
                size={type !== 'web' ? '20px' : '22px'}
                line={type !== 'web' ? '2px' : '22px'}
                top={'0px'}
              >
                News
              </Typography>
            </TouchableOpacity>
          </View>
        </View>
        <InfohubFilters sources={sources} categories={categories} onApply={(e)=>{
          setCategory(e?.filters?.category);
          setSelectedSource(e?.filters?.source);
          setDate(e?.filters?.year);
          setSortBy(e?.sort?.sortBy);
          setSortOrder(e?.sort?.sortOrder);
          setApplyClick(!onApplyClick)
        }} />
        <View style={styles.input}>
          <Feather name="search" size={18} color={COLORS.LGREEN} />
          <TextInput
            style={{
              flex: 1,
              height: 40,
              marginLeft: 10,
              outlineStyle: 'none',
              fontFamily: 'Raj',
              fontSize: 20,
            }}
            value={search}
            placeholderTextColor={'#b2b2b2'}
            placeholder="Search"
            onChangeText={handleSearch}
            underlineColor="white"
            caretHidden={true}
          />
        </View>

        {selectedTab == 1 ? (
          <>
            <MediaWrapper>{renderArticles()}</MediaWrapper>
            {totalArticles > articles.length ? (
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  margin: '20px',
                }}
              >
                <WideButton
                  onPress={() => {
                    handleMore();
                  }}
                  isRaj={true}
                  isLGreen={true}
                  borderRadius={15}
                  childText="LOAD MORE"
                  height={50}
                  width={'40%'}
                  buttonType={'solid'}
                  gradiant
                  alignSelf={'center'}
                  textSize={'24px'}
                />
              </View>
            ) : null}
          </>
        ) : (
          <>
            <MediaWrapper>{renderVideos()}</MediaWrapper>
            {totalVideos > videos.length ? (
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: 20,
                }}
              >
                <WideButton
                  onPress={() => {
                    handleMore1();
                  }}
                  isRaj={true}
                  isLGreen={true}
                  borderRadius={15}
                  childText="LOAD MORE"
                  height={50}
                  width={'40%'}
                  buttonType={'solid'}
                  gradiant
                  alignSelf={'center'}
                  textSize={'24px'}
                />
              </View>
            ) : null}
          </>
        )}
      </MediaListWrapper>
      <Loader loading={isLoading} />
    </>
  );
};

const styles = StyleSheet.create({
  input: {
    borderWidth: 1,
    borderColor: 'rgba(76, 78, 100, 0.38)',
    height: 40,
    backgroundColor: 'white',
    borderRadius: 8,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 15,
    paddingVertical: 5,
    alignSelf: 'center',
    width: layout == 'phone' ? '100%' : 430,
    marginTop: layout == 'phone' ? 15 : 0,
    marginBottom: 30,
  },
});

export default MediaHubList;
