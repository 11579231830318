import React from 'react';

var __html = require('./termsandconditions.html');
var template = { __html: __html };

const Terms=()=>{
  return(
    <div style={{width:"80%",alignSelf:'flex-start',padding:40,background:'white'}} dangerouslySetInnerHTML={template} />
  )
}
export default Terms;
