import styled from "styled-components/native";
import getPlatformType from "../../helpers/getPlatform";

const layoutType = getPlatformType();
let Container,
  MediaBox,
  MediaWrapper,
  TitleText,
  MediaLink,
  MediaListWrapper,
  MediaDesc,
  FooterBox,
  MediaDate,
  MediaVideoBox,
  MediaVideoDesc,
  SectionText,
  DualView,
  DualViewBox,
  SubTitleText,
  ShareBox,
  ShareCount,
  ShareMedia,
  MediaBigDate,
  ImageViewBox,
  DFlex,
  UserInfo,
  WideButtonWrapper;

Container = styled.View`
  width: 1130px;
  margin: 0 auto;
`;

MediaListWrapper = styled.View`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: white;
  border-radius: 20px;
  width:${layoutType=="web"?"93%":"90%"};
  alignSelf:center;
  margin-top: 20px;
  margin-bottom: 100px;
  box-shadow: 1px 3px 12px rgba(0, 0, 0, 0.15);
  minHeight:100px;
  padding-horizontal:30px;
  padding-vertical:30px;
`;

MediaWrapper = styled.View`
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top:20px;
`;

MediaBox = styled.View`
  width: 450px;
  margin: 20px;
  border-radius: 20px;
  align-self: flex-start;
  background-color: white;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 6.68px;
  height:280px;
`;

TitleText = styled.Text`
  font-size: 15px;
  font-weight: 600;
`;

MediaLink = styled.Text`
  color: #2074b6;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 12px;
`;

MediaDesc = styled.Text`
  font-size: 14px;
  margin-bottom: 20px;
  max-height: 85px;
  height: 85px;
`;

FooterBox = styled.View`
  padding: 50px 0px;
`;

MediaDate = styled.Text`
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 12px;
`;

MediaVideoBox = styled.View`
  width: 100%;
  background-color: white;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 0px 0px 25px 0px;
  width: 48%;
  margin: 20px 0px;
  justify-content: space-between;
  min-width:300px;

`;
MediaVideoDesc = styled.Text`
  font-size: 14px;
  margin-bottom: 20px;
  color: #b0b0b0;
  margin-top: 5px;
`;
SectionText = styled.Text`
  color: #000000;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 26px;
  margin-top: 20px;
`;
DualView = styled.View`
  display: flex;
  flex-direction: row;
`;
DualViewBox = styled.View`
  width: 50%;
`;
SubTitleText = styled.Text`
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 6px;
`;
ShareBox = styled.View`
  flex-direction: row;
`;
ShareMedia = styled.View``;
MediaBigDate = styled.Text`
  font-size: 25px;
  line-height: 30px;
  color: #e71e82;
`;

ShareCount = styled.View``;
WideButtonWrapper = styled.View`
  width: 100px;
  align-items: center;
  margin-left: 5px;
`;

if (layoutType == "phone") {
  Container = styled.View`
    width: 342px;
    margin: 0 auto;
    min-height: 70px;
  `;
  MediaWrapper = styled.View`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
    padding: 20px 0px;
  `;
  MediaListWrapper = styled.View`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: white;
  border-radius: 20px;
  padding-horizontal: 15px;
  padding-vertical:20px;
  margin-horizontal:10px;
  margin-top: 20px;
  margin-bottom: 100px;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 6.68px;
`;
  MediaBox = styled.View`
    width: 100%;
    height: 100%;
    background-color: white;
    border-bottom-left-radius: 20px;
    border-top-right-radius: 20px;
    padding: 20px;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 6.68px;
    height: 300px;
    margin: 20px 0px;
    justify-content: space-between;
    min-width:300px;

  `;
  MediaDesc = styled.Text`
    font-size: 14px;
    margin-bottom: 0px;
    max-height: 105px;
    height: 105px;
  `;
  MediaVideoBox = styled.View`
    width: 100%;
    height: 100%;
    background-color: white;
    border-bottom-left-radius: 20px;
    border-top-right-radius: 20px;
    padding: 0px 0px 25px 0px;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 6.68px;
    min-height: 355px;
    height: auto;
    margin: 20px 0px;
    justify-content: space-between;
  `;
  MediaVideoDesc = styled.Text`
    font-size: 14px;
    margin-bottom: 20px;
    color: #b0b0b0;
    margin-top: 5px;
  `;
  ImageViewBox = styled.View`
    width: 100%;
    height: 245px;
  `;
  DFlex = styled.View`
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  `;
  UserInfo = styled.View`
    width: 100%;
    padding-bottom: 16px;
    align-items: center;
  `;
  TitleText = styled.Text`
    font-size: 18px;
  `;
  SectionText = styled.Text``;
  DualView = styled.View`
    flex-direction: column;
  `;
  DualViewBox = styled.View`
    width: 100%;
  `;
} else if (layoutType == "web") {
  ImageViewBox = styled.View`
    width: 40%;
    height: 275px;
    padding-right:0px;
    padding-left: 16px;

  `;
  DFlex = styled.View`
    display: flex;
    flex-direction: row;
    margin-top: 20px;
  `;
  UserInfo = styled.View`
    width: 150px;
    padding-right: 12px;
    padding-bottom: 16px;
  `;
  TitleText = styled.Text`
    font-size: 18px;
    margin-top: 16px;
  `;
  SectionText = styled.Text`
    padding-left: 16px;
  `;
  Container = styled.View`
    min-height: 80vh;
    width: 1130px;
    margin: 0 auto;
  `;
} else {
  Container = styled.View`
    width: 944px;
    margin: 0 auto;
    min-height: 80vh;
  `;
  ImageViewBox = styled.View`
    width: 40%;
    height: 245px;
    padding-right: 16px;
  `;
  DFlex = styled.View`
    display: flex;
    flex-direction: row;
    margin-top: 20px;
  `;
  UserInfo = styled.View`
    width: 150px;
    padding-right: 12px;
    padding-bottom: 16px;
  `;
  TitleText = styled.Text`
    font-size: 18px;
  `;
  SectionText = styled.Text`
    padding-left: 16px;
  `;
}

export {
  Container,
  MediaWrapper,
  MediaBox,
  TitleText,
  MediaLink,
  MediaDesc,
  FooterBox,
  MediaDate,
  MediaListWrapper,
  MediaVideoBox,
  MediaVideoDesc,
  SectionText,
  DualView,
  DualViewBox,
  SubTitleText,
  ShareBox,
  ShareCount,
  ShareMedia,
  MediaBigDate,
  ImageViewBox,
  DFlex,
  UserInfo,
  WideButtonWrapper,
};
