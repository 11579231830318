import React, { useCallback, useEffect, useRef, useState } from "react";
import { Dimensions, Image, ImageBackground, View } from "react-native";
import stylesMobile from "./stylesMobile";
import stylesTab from "./stylesTab";
import stylesWeb from "./stylesWeb";
import { backgroundSearchArr } from "../images/tlyfe/searchArrayTlyfe";
import BackGroundGenerator from "../common/BackGroundGenerator";
import { navigateAction } from "../../../helpers/navigationHelpers";
import { useSelector } from "react-redux";
import { Typography } from "../../openFlow/style";
import { COLORS } from "../../../constants/colorPallete";
import getPlatformType from "../../../helpers/getPlatform";
import CommonAuth from "./CommonAuth";
import ImageView from "./imageView";

const LandingCredit = (props) => {
  const scollToRef = useRef();
  const scrollViewRef = useRef();

  const FEATURED = [
    {
      heading: "Supported banks include:",
      heading2: "",
      subheading:
        "Bank of Scotland, Barclays, Chelsea Building Society, Danske Bank, First Direct, Halifax, HSBC, Lloyds Bank, MBNA, Monzo, Nationwide, Natwest, Revolut, Royal Bank of Scotland, Santander, Starling Bank, Tesco Bank, Tide, Transferwise, TSB, Ulster Bank, Yorkshire Building Society.",
      imageUrl: require("./images/credit/credit.png"),
      onButtonClick: () => {
        scollToRef.current.scrollIntoView({ behavior: "smooth" });
      },
      buttonText: "CLICK HERE TO REGISTER/LOGIN",
      buttonStyle: { marginTop: 30 },
      bottomList: [
        { imageUrl: require("./images/credit/Experian.png") },
        { imageUrl: require("./images/credit/Equifax.png") },
        { imageUrl: require("./images/credit/TransUnion.png") },
      ],
    },
  ];
  const [type, setType] = useState("web");
  const layoutType = getPlatformType();
  const isAnylyticsEnable = useSelector(
    (state) => state?.cookiesReducer?.customiseCookies.Analytics,
  );
  const { navigate } = props.navigation;
  const navigateTo = (params, from) => {
    if (from) {
      navigateAction(props?.navigation, params, from);
    } else {
      navigateAction(props?.navigation, params);
    }
  };
  const [property_for, setPropertyFor] = useState(1);
  const styles = type == "web" ? stylesWeb : type == "mobile" ? stylesMobile : stylesTab;
  const [state, setState] = useState({
    from: "",
  });

  useEffect(() => {
    if (props?.route?.params?.from) {
      setState({
        ...state,
        from: props?.route?.params?.from ? props?.route?.params?.from : "",
      });
    }
  }, [props?.route?.params?.from]);
  console.log("type", type);
  const handleScrollButtonClick = useCallback(() => {
    const height=Dimensions.get('window').height;
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollTo({ y:layoutType=='web'?height:height/2, animated: true });
    }
  }, []);
  let BottomWrapper = type == "web" ? ImageBackground : View;
  return (
    <>

      <BackGroundGenerator
        props={props}
        layoutType={(data) => setType(data)}
        backgroundArr={backgroundSearchArr}
        isTLYF={true}
        isLanding
        cloudHeader={true}
        scrollViewRef={scrollViewRef}
      >
        <div
          ref={scollToRef}
        />
        <CommonAuth
          heading={"IMPROVE YOUR CREDIT SCORE WITH TLYFE"}
          navigation={navigate}
          onScrollButtonClick={handleScrollButtonClick}
          type={type}
          isTLYF={true}
          subheading={"Future proof and secure your dream home with recorded rental records!".toUpperCase()}
          desc={""}
          hideButton={false}
          {...props}
        />
        <View style={[styles.textContainer, { backgroundColor: "#FAFAFB" }]}>
          <View
            style={[
              styles.shawow,
              {
                width: "100%",
                flexDirection: "column",
                paddingHorizontal: "6%",
                borderRadius: 23,
                borderBottomWidth: 2,
                borderTopWidth: 1,
                borderColor: COLORS.GREEN,
                alignItems: "center",
              },
            ]}
          >
            <Typography
              line={"35px"}
              raj
              bold={"600"}
              top={"40px"}
              color={"#1C1C1C"}
              size={"26px"}
              align={"center"}
              bottom={"40px"}
            >
              Record your rental payments with tlyfe and have these reported to all major credit reference agencies,
              helping to improve your credit score and get you mortgage-ready!
            </Typography>
          </View>
        </View>
        <View style={[styles.imageContainer, { backgroundColor: "white" }]}>
          <Image
            source={type == "web" ? require("./images/credit/howitworks.png") : require("./images/credit/howitworksMobile.png")}
            style={type !== "web" ? {
              width: "100%",
              height: 1200,
            } : {
              width: "100%",
              height: 1000,
            }}
            resizeMode={"contain"}
          />
        </View>

        {FEATURED.map((item, index) => {
          return (
            <ImageView
              key={index}
              reverse={index % 2 === 0 ? false : true}
              type={type}
              heading={item.heading}
              heading2={item.heading2}
              bColor={"#FAFAFB"}
              subheading={item.subheading}
              imageUrl={item.imageUrl}
              onButtonClick={item?.onButtonClick}
              buttonText={item?.buttonText}
              buttonStyle={item?.buttonStyle ? {
                ...item.buttonStyle,
                marginBottom: type != "web" ? 400 : 250,
              } : {}}
              isTLYF={true}
              bottomList={item?.bottomList ? item?.bottomList : []}

            />
          );
        })}
      </BackGroundGenerator>
    </>
  );
};
export default LandingCredit;
