import React, { useState, useEffect, useRef } from 'react';
import { View, Dimensions, Text, Image } from 'react-native';
import { useSelector } from 'react-redux';
import FooterLanding from '../../../../commonComponents/footerComponentLanding/customFooter';
import Headers from '../../../../commonComponents/headerComponent/headerComponent';
import { ContainerScrollView } from '../../../../helpers/appStyles';
import AutoHeightImage from '../AutoImage';
import { Ionicons } from '@expo/vector-icons';

import { TouchableOpacity } from 'react-native';
import Constant from '../../../openFlow/openFlowRedux/Constant';
const WIN_HEIGHT = Dimensions.get('window').height;
import CustomDrawerModal from '../../../../navigation/customDrawer/customDrawerModal';
import getPlatformType from '../../../../helpers/getPlatform';
import OpenFlowContainer from '../openFlowContainer';
import Header from '../../../tlyfePages/Header';
import { getTokens } from '../../../../../openbrix-design-system/design-tokens/color-tokens';
import Footer from '../../../tlyfePages/Footer';

const BackGroundGenerator = ({
  backgroundArr,
  children,
  layoutType,
  props,
  isTLYF,
  isTds,
  infoIcon = false,
  isBack = false,
  backNavigation,
  redirectTDSAccount,
  tdsAccountIcon = false,
  isLanding,
  cloudHeader,
  hideAuth,
  replace,
  scrollViewRef,
  hideMiddleLogo,
  isOpenflowContainer,
  openFlowData,
  paddingtop,
  isOpenflowHome,
  navigation
}) => {
  const [paintedWidth, setPaintedWidth] = useState(0);
  const cookiesBanner = localStorage.getItem('cookies_status');
  const token = useSelector((state) => state?.appReducer?.loginData?.data?.token);
  const authData = useSelector((state) => state?.appReducer?.mobileData?.data?.isWebView);
  const layout = getPlatformType();
  const tdsLocationSelected = useSelector((state) => state.appReducer?.tdsLocationSelected);

  const getName = (val) => {
    switch (val) {
      case 'Dashboard':
        return 'My Dashboard';
      default:
        return 'Back';
    }
  };

  const getStyles = (item, type) => {
    if (paintedWidth) {
      if (paintedWidth <= 767) {
        layoutType ? layoutType('mobile') : null;
        return type == 'style' ? item?.mobileStyle : item?.mobileUri;
      } else if (paintedWidth >= 768 && paintedWidth <= 1024) {
        layoutType ? layoutType('tab') : null;
        return type == 'style' ? item?.tabStyle : item?.tabUri;
      } else if (paintedWidth >= 1025) {
        layoutType ? layoutType('web') : null;
        return type == 'style' ? item?.webStyle : item?.webUri;
      }
    }
  };
  const themeObj = getTokens('light').colors;
  const renderFootor = () => {
    if (authData) {
      return <></>;
    } else if(token){
      return  <FooterLanding
      cloudFootor={cloudHeader}
      isLanding={isLanding}
      paintedWidth={paintedWidth}
      isTLYF={isTLYF}
      {...props}
      isTds={isTds}
    />
    } else {
      return <Footer bgColor={themeObj.main.blue[400]} />
    }
    
  };

  return (
    <View
      onLayout={(event) => setPaintedWidth(event.nativeEvent.layout.width)}
      style={{ backgroundColor: '#FAFAFA', height: WIN_HEIGHT }}
    >
      {authData ? null : token ? (
        <Headers
        noBottomMargin
          showLogoWeb
          showLogoTab
          showLogoMob
          rightChildLabelTab
          rightChildLabelWeb
          rightChildLabelMob
          showDrawerMob
          showDrawerTab
          showNotification
          isTds={isTds}
          {...props}
          isTLYF={isTLYF}
        />
      ) : (
        <Header
        bgColor={themeObj.main.blue[400]}
        />
      )}
      <ContainerScrollView style={{ zIndex: 15 }} height={70} ref={scrollViewRef}>
        {backgroundArr
          ? backgroundArr.map((item, index) => {
              if (getStyles(item, 'style')) {
                return (
                  <React.Fragment key={index}>
                    <AutoHeightImage
                      key={index}
                      Uniquekey={index}
                      style={getStyles(item, 'style')}
                      uri={getStyles(item, 'uri')}
                      blurRadius={item.blurRadius}
                      cheight={item.cheight}
                    />
                    {hideMiddleLogo ? null : (
                      <Image
                        source={
                          tdsLocationSelected == 'TdsScoctland'
                            ? require('../../../../assets/images/tds_scotland.png')
                            : require('../../../../assets/images/tdslogoWhite.png')
                        }
                        style={{
                          width: 178,
                          height: 88,
                          position: 'absolute',
                          top: layout == 'phone' ? 80 : 40,
                          alignSelf: 'center',
                        }}
                      />
                    )}
                    {isBack && (
                      <TouchableOpacity
                        onPress={() => backNavigation()}
                        style={{
                          position: 'absolute',
                          left: 30,
                          top: 15,
                          borderWidth: 1.5,
                          paddingVertical: 5,
                          borderColor: 'white',
                          alignItems: 'center',
                          borderRadius: 100,
                          paddingHorizontal: 10,
                          flexDirection: 'row',
                        }}
                        id={'TDS-BACK-ACTION'}
                      >
                        <Ionicons name="md-arrow-back" size={24} color="white" />
                        <Text style={{ marginLeft: 8, color: 'white' }}>{getName(replace)}</Text>
                      </TouchableOpacity>
                    )}
                    {infoIcon && (
                      <TouchableOpacity
                        onPress={() => window.open(Constant.TDS_HELP_URL, '_blank')}
                        style={{ position: 'absolute', right: 30, top: 15 }}
                      >
                        <Image
                          style={{ width: 70, height: 70 }}
                          resizeMode={'contain'}
                          source={require('./../../images/helpIcon.png')}
                        />
                      </TouchableOpacity>
                    )}

                    {tdsAccountIcon && (
                      <TouchableOpacity
                        onPress={() => redirectTDSAccount()}
                        style={{ position: 'absolute', right: 150, top: 15 }}
                      >
                        <div id="TDS-BUTTON-ACCOUNT">
                          <Image
                            style={{ width: 70, height: 50 }}
                            resizeMode={'contain'}
                            id="tds-account-button"
                            source={require('./../../images/tdsAccount.png')}
                          />
                        </div>
                      </TouchableOpacity>
                    )}
                  </React.Fragment>
                );
              } else {
                return null;
              }
            })
          : getStyles()}
        {isOpenflowContainer ? (
          <View style={{ position: 'absolute', width: '100%' }}>
            <OpenFlowContainer isOpenflowHome={isOpenflowHome} navigation={navigation} children={children} openFlowData={openFlowData} {...props} />
          </View>
        ) : (
          <View style={{ position: 'absolute', width: '100%',marginTop:paddingtop?paddingtop: 0 }}>
            {children}
            <CustomDrawerModal setDrawerState={true} isLanding />
            {isLanding ? renderFootor() : null}
          </View>
        )}
      </ContainerScrollView>
      {authData ? null : isLanding ? null : renderFootor()}
    </View>
  );
};
export default BackGroundGenerator;
