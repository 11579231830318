import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  shawow:{
    boxShadow: "0px 0px 25px #DADADA"
  },
  container: {
paddingTop:200,
width:"100%",
    paddingHorizontal:"8%",
    flexDirection:"column",
    height:1150,
  },
  formWrapper:{
    flex: 0.5,
    marginTop: 40,
  },
  imageContainer:{
    paddingHorizontal: '8%',
    paddingVertical:"9%"
  },
  textContainer:{
    paddingHorizontal: '8%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    paddingVertical:"12%"
  },
  searchSection: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingTop: 80,
    backgroundColor:"#F9B383",
    paddingBottom:353,
    paddingHorizontal: '8%',
  },
  wrapper:{
    paddingBottom:1000
              },
              videoWrapper:{
                width: 644,
                height: 351,
                borderWidth:0.5,
                borderColor: "#000000",
                borderRadius: 9,
                padding:9,
                backgroundColor: "transparent",
                marginTop:176
              },
});
