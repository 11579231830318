import React, { ReactChild } from 'react';
import Modal  from "react-native-modal";
import Portal from './potral';



interface ModalProps {
    children: ReactChild,
    visible: boolean
}
export const ODSModal = ({ children, visible, ...props }) => {
    return (
        <Portal>
        <Modal
            isVisible={visible}
            coverScreen={false}
            hasBackdrop={false}
            backdropOpacity={220}
            style={{
                margin: 0,
                width: "100%",
                flex: 1,
                backgroundColor: "#00000080",
            }}
            {...props}
        >
            {children}
        </Modal>
        </Portal>
    )
}
