import React, { useEffect, useState } from 'react';
import { CheckBox } from 'react-native-elements';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { useDispatch, useSelector } from 'react-redux';
import { Image, Linking, Platform, View } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { AntDesign, Entypo, FontAwesome, Ionicons, MaterialIcons } from '@expo/vector-icons';
import { CheckoutForm } from './checkoutForm';
import { Typography } from '../openFlow/style';
import { COLORS } from '../../constants/colorPallete';
import { showToaster } from '../../redux/actions/toast';
import getPlatformType from '../../helpers/getPlatform';
import WideButton from '../../commonComponents/wideButton';
import { CustomButton } from '../identifyAndFinance/stylesNew';
import { getDigitalVerificationStatus } from '../openFlow/helpers';
import { STRIPEKEY, YOTI_URL } from '../../helpers/connectionHelpers';
import { tenantActions } from '../openFlow/openFlowRedux/tenant.actions';
import { BoxShadow, IconButton, Line, Title2, TitleBlack, Wrapper } from './styles';
import { LineSaperator } from '../tds/style';
import { navigate } from '../../navigation/RootNavigation';
import PayByVoucher from './paybyVoucher';
import { ODSText } from '../../../openbrix-design-system/ui/atoms/ODSText';
import ODSBadges from '../../../openbrix-design-system/ui/atoms/ODSBadges';
import { getTokens } from '../../../openbrix-design-system/design-tokens/color-tokens';
import ODSButton from '../../../openbrix-design-system/ui/atoms/ODSButton';

const IdVerification = (props) => {
  const dispatch = useDispatch();
  const layoutType = getPlatformType();
  const [page, setPage] = React.useState(1);
  const [preQualData, setPreQualData] = React.useState([]);
  const globalState = useSelector((state) => state?.tenant);
  const [preRefData, setPreRefData] = React.useState(false);
  const [agreeTerms, setAgreeTerms] = React.useState(false);
  const [clientSecret, setClientSecret] = React.useState('');
  const [addOnSelected, setAddOnSelected] = React.useState(false);
  const [combinedPackage, setCombinedPackage] = React.useState({});
  const [selectedPackedge, setSelectedPackege] = React.useState('');
  const [referenceOnlyPackage, setReferenceOnlyPackage] = React.useState({});
  const [guarantorAddOnPackage, setGuarantorAddOnPackage] = React.useState({});
  const userData = useSelector((state) => state.authReducer?.profile?.data?.appUser);
  const yotiRequestId = useSelector((state) => state.authReducer?.profile?.data?.user_id);
  const authDataFromMobile = useSelector((state) => state?.appReducer?.mobileData?.data?.isWebView);
  const idVerification = useSelector(
    (state) => state.authReducer?.profile?.data?.appUser?.idverification
  );
  const hasUserSavedCookies = useSelector(
    (state) => state?.cookiesReducer?.userHasSavedCookiesPreferences
  );
  const [renderType, setType] = useState('card');
  const [paymentMode, setPaymentMode] = useState('');
  const [onFirstTimeCardClick, setFirstTimeClick] = useState(true);

  const { id_verification } = preQualData;

  let stripePromise = null;
  if (hasUserSavedCookies) {
    stripePromise = loadStripe(STRIPEKEY);
  }
  if (authDataFromMobile) {
    stripePromise = loadStripe(STRIPEKEY);
  }

  const getButtonStatus = (id) => {
    if (id == 0 || id === 5 || id == 9) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (page == 1) {
      dispatch(tenantActions.getPreQualificationStatus());
      dispatch(tenantActions.getPackedge('/pre-qualification/id-verification-packages'));
    }
  }, []);

  useEffect(() => {
    if (globalState?.pack && globalState?.pack?.length) {
      const package1 = globalState?.pack.find((x) => x.product_code === 'tenant_id_verification');
      if (package1) {
        package1.name = 'ID Verification only';
      }

      const package2 = globalState?.pack.find(
        (x) => x.product_code === 'tenant_digital_reference_and_id_verification'
      );
      if (package2) {
        package2.name = 'Rent Ready Reference & ID Verification';
      }

      const package3 = globalState?.pack.find(
        (x) => x.product_code === 'tenant_guarantor_digital_reference'
      );
      if (package3) {
        package3.name = '';
      }

      setGuarantorAddOnPackage(package3);
      setCombinedPackage(package2);
      setReferenceOnlyPackage(package1);
    }
  }, [globalState.pack]);

  const openLinkYoti = async (id) => {
    if (yotiRequestId && getButtonStatus(idVerification)) {
      let u = YOTI_URL + yotiRequestId;

      if (Platform.OS === 'web') {
        window.open(
          u,
          '_blank' // <- This is what makes it open in a new window.
        );
      } else {
        Linking.openURL(u).catch((err) => console.error('An error occurred', err));
      }
    } else {
      console.error('An error occurred', yotiRequestId, getButtonStatus());
    }
  };

  const downloadVerifiedDocuments = async () => {
    if (userData.yoti_verification_document) {
      window.open(userData.yoti_verification_document, '_blank');
    } else {
      alertActions.error('This user does not have verified Documents to download');
    }
  };

  useEffect(() => {
    if (globalState?.preQualData?.preQualStatus) {
      setPreQualData(globalState?.preQualData?.preQualStatus);
    }
    if (globalState?.preQualData?.preQualStatus.id_verification.payment_required) {
      setPage(1);
      setPreRefData(false);
    } else {
      setPreRefData(true);
    }
  }, [globalState]);

  const ButtonStyle = () => {
    return (
      <View style={{ width: layoutType == 'web' ? 620 : '100%' }}>
        <View style={{ paddingHorizontal: 20 }}>
          <ODSButton
            rIcon={'TB-Share'}
            restStyle={{ marginTop: 20, alignSelf: 'center', width: 300 }}
            type="secondary"
            disabled={false}
            themeColor={themeObj.main.purple}
            onPress={() => navigate('Document Share Center')}
          >
            My Share Center
          </ODSButton>
          <ODSText
            color={themeObj.neutral[700]}
            marginTop={10}
            style={{ alignSelf: 'center' }}
            textAlign={'center'}
            type={'sm'}
          >
            Prove you meet the referencing criteria by securing a Rent Ready reference to become a
            Rent Ready Tenant
          </ODSText>
          <ODSButton

            restStyle={{ marginTop: 20, alignSelf: 'center', width: 300 }}
            type="primary"
            disabled={false}
            themeColor={themeObj.main.purple}
            onPress={() => navigate('PreQualificationPreReference')}
          >
            Next: Get your digital reference
          </ODSButton>
        </View>
      </View>
    );
  };
  const themeObj = getTokens('light').colors;
  const renderDocs = () => {
    return (
      <>
        {id_verification?.idverification == 9 ? (
          <>
            <ODSText style={{ alignSelf: 'center' }} type={'h5'}>
              ID Verification
            </ODSText>
            <ODSText
              color={themeObj.neutral[700]}
              marginTop={14}
              style={{ alignSelf: 'center' }}
              type={'md'}
            >
              It appears your id verification has failed, you may try once more. After that, you'll
              need to buy again.
            </ODSText>
            <ODSText
              color={themeObj.neutral[700]}
              marginTop={14}
              style={{ alignSelf: 'center' }}
              type={'md'}
            >
              Check the reason for failure below.
            </ODSText>
            <ODSText
              color={themeObj.neutral[700]}
              marginTop={14}
              style={{ alignSelf: 'center' }}
              type={'button'}
            >
              Verification failed reason
            </ODSText>
            <ODSText
              color={themeObj.neutral[700]}
              marginTop={14}
              style={{ alignSelf: 'center' }}
              type={'md'}
            >
              {userData?.yoti_reason}
            </ODSText>
            <ODSButton
              rIcon={'TB-Rotating'}
              themeColor={themeObj.main.purple}
              restStyle={{ marginTop: 20, alignSelf: 'center', width: '100%' }}
              type="primary"
              disabled={false}
              onPress={() => openLinkYoti()}
            >
              Start Again
            </ODSButton>
          </>
        ) : id_verification?.idverification == 0 || id_verification?.idverification == 5 ? (
          <>
            <ODSText style={{ alignSelf: 'center' }} type={'h5'}>
              ID Verification
            </ODSText>
            <ODSText
              color={themeObj.neutral[700]}
              marginTop={14}
              style={{ alignSelf: 'center' }}
              type={'md'}
            >
              IMPORTANT: If you have a British or Irish passport, we strongly recommend uploading
              this, as your right to rent will be automatically confirmed.
            </ODSText>
            <View
              style={{
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'space-evenly',
                width: '100%',
              }}
            >
              <ODSButton
                lIcon={'TB-Arrow-Right'}
                themeColor={themeObj.main.purple}
                restStyle={{ marginTop: 20, alignSelf: 'center', width: '100%' }}
                type="primary"
                disabled={false}
                onPress={() => openLinkYoti()}
              >
                Start
              </ODSButton>
            </View>
            <ODSText color={themeObj.neutral[700]} marginTop={26} textAlign={'center'} type={'md'}>
              When you press start you’ll be redirected to our ID Verification partner YOTI
            </ODSText>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-around',
                alignItems: 'center',
                marginTop: 20,
              }}
            >
              <Image
                source={require('./../../assets/images/yoti.svg')}
                resizeMode="contain"
                style={{
                  height: 40,
                  width: 100,
                  alignSelf: 'center',
                }}
              />
              <View
                style={{
                  height: 54,
                  backgroundColor: themeObj.neutral[300],
                  width: 1,
                }}
              />
              <Image
                source={require('./../../../openbrix-design-system/images/tlyfelogo.png')}
                style={{ height: 38, width: 83, alignSelf: 'center' }}
              />
            </View>
            <ODSText
              color={themeObj.neutral[700]}
              marginTop={26}
              textAlign={'center'}
              type={'button'}
            >
              Confirmation of ID Verification may take up to 24 hours. If you have not received
              confirmation of verification within 24 hour please check the YOTI terms and conditions
              for next steps
            </ODSText>

            <Image
              style={{ height: 54, width: 130, alignSelf: 'center', marginTop: 20 }}
              source={require('./../../../openbrix-design-system/images/ukcert.png')}
            />
          </>
        ) : (
          <>
            {getDigitalVerificationStatus(
              id_verification?.idverification,
              id_verification?.yoti_reason
            )?.BtnText === 'Completed' ? (
              <>
                <ODSText
                  color={themeObj.neutral[700]}
                  marginTop={10}
                  style={{ alignSelf: 'center' }}
                  type={'md'}
                >
                  You can share your verified identity as many times as you like and you control who
                  sees this information.
                </ODSText>
                <ODSText
                  color={themeObj.neutral[700]}
                  marginTop={10}
                  style={{ alignSelf: 'center' }}
                  type={'md'}
                >
                  You can use various documents to verify your ID but we recommend using a passport.
                  If you have a British or Irish passport this will confirm your ‘right to rent’ in
                  England.
                </ODSText>
                <ODSText
                  color={themeObj.neutral[700]}
                  marginTop={10}
                  style={{ alignSelf: 'center' }}
                  type={'md'}
                >
                  Documents used for identity verification can be downloaded and saved in the Share
                  Centre. You can then share them easily and securely by issuing a time limited
                  share code to anyone you choose.
                </ODSText>
                <View
                  style={{
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'space-evenly',
                    width: '100%',
                  }}
                >
                  {userData?.yoti_verification_document && (
                    <ODSButton
                      rIcon={'TB-Download'}
                      themeColor={themeObj.main.purple}
                      restStyle={{ marginTop: 20, alignSelf: 'center', width: '100%' }}
                      type="primary"
                      disabled={false}
                      onPress={() => downloadVerifiedDocuments()}
                    >
                      Verification Doc
                    </ODSButton>
                  )}
                </View>
                <Image
                  style={{ height: 54, width: 130, alignSelf: 'center', marginTop: 20 }}
                  source={require('./../../../openbrix-design-system/images/ukcert.png')}
                />
              </>
            ) : null}
          </>
        )}
        {id_verification?.idverification == 1 ? (
          <>
            <ODSText style={{ alignSelf: 'center' }} type={'h5'}>
              ID Verification
            </ODSText>
            <ODSText
              color={themeObj.neutral[700]}
              marginTop={14}
              style={{ alignSelf: 'center' }}
              type={'md'}
            >
              You can share your verified identity as many times as you like and you control who
              sees this information.
            </ODSText>
            <ODSText
              color={themeObj.neutral[700]}
              marginTop={14}
              style={{ alignSelf: 'center' }}
              type={'md'}
            >
              You can use various documents to verify your ID but we recommend using a passport. If
              you have a British or Irish passport this will confirm your ‘right to rent’ in
              England.
            </ODSText>
            <ODSText
              color={themeObj.neutral[700]}
              marginTop={14}
              style={{ alignSelf: 'center' }}
              type={'md'}
            >
              Documents used for identity verification can be downloaded and saved in the Share
              Centre. You can then share them easily and securely by issuing a time limited share
              code to anyone you choose.
            </ODSText>
            <View
              style={{
                height: 1,
                backgroundColor: themeObj.neutral[300],
                width: '100%',
                marginVertical: 30,
              }}
            />
            <Image
              style={{ height: 30, width: 30, alignSelf: 'center' }}
              source={require('./../../../openbrix-design-system/images/pending.png')}
            />
            <ODSText textAlign={'center'} marginTop={10} type={'h5'}>
              Pending
            </ODSText>
            <ODSText
              color={themeObj.neutral[700]}
              marginTop={10}
              textAlign={'center'}
              alignSelf={'center'}
              type={'md'}
            >
              Yoti is still processing your data. This could take up to 24 hours. If you have any
              questions feel free to contact info@openbrix.co.uk
            </ODSText>
            <Image
              style={{ height: 54, width: 130, alignSelf: 'center', marginTop: 20 }}
              source={require('./../../../openbrix-design-system/images/ukcert.png')}
            />
          </>
        ) : (
          <></>
        )}
      </>
    );
  };

  const getTotalCost = () => {
    if (
      addOnSelected &&
      selectedPackedge &&
      selectedPackedge?.price_with_vat &&
      guarantorAddOnPackage &&
      guarantorAddOnPackage?.price_with_vat
    ) {
      const selectedPrice = parseFloat(selectedPackedge?.price_with_vat);
      const guarantorPrice = parseFloat(guarantorAddOnPackage?.price_with_vat);

      if (!isNaN(selectedPrice) && !isNaN(guarantorPrice)) {
        return (selectedPrice + guarantorPrice).toFixed(2);
      }
    } else if (!addOnSelected && selectedPackedge && selectedPackedge?.price_with_vat) {
      return selectedPackedge?.price_with_vat;
    } else if (addOnSelected && guarantorAddOnPackage && guarantorAddOnPackage?.price_with_vat) {
      return guarantorAddOnPackage?.price_with_vat;
    }
    return null;
  };

  const startPayment = () => {
    let payload = {
      ...selectedPackedge,
      addOnSelected,
      productId: selectedPackedge.product_id ? selectedPackedge.product_id : null,
    };
    dispatch(
      tenantActions.startPayment(payload, (data) => {
        if (data.status) {
          setClientSecret(data.data.client_secret);
          // setPage(3);
        } else {
          dispatch(showToaster('error', 'Failed to start payment'));
        }
      })
    );
  };
  const renderImagesSection = () => {
    return (
      <>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-around',
            marginTop: 10,
          }}
        >
          <Image
            source={require('./../../assets/images/yoti.svg')}
            resizeMode="contain"
            style={{
              height: 38,
              width: 86,
              marginTop: 10,
            }}
          />
          <View style={{ height: 40, width: 2, backgroundColor: 'black' }} />
          <Image
            source={require('./../../assets/images/tlyfe.png')}
            resizeMode="contain"
            style={{
              height: 38,
              width: 86,

              marginTop: 10,
            }}
          />
        </View>
        <Typography top={'24px'} size={'18px'} bold={'700'} color={'#4f4f4f'}>
          Digital Identity Checks
        </Typography>
        <Typography top={'10px'} size={'16px'} bold={'700'} color={'#4f4f4f'}>
          Smooth experience with YOTI and tlyfe
        </Typography>
        <Typography top={'14px'} size={'13px'} bold={'300'} color={'#4f4f4f'}>
          Yoti is the UK’s first certified Identity Services Provider (IDSP) under the Digital
          Identity and Attributes Trust Framework.
        </Typography>
        <Image
          source={require('./../../assets/images/gov.png')}
          resizeMode="contain"
          style={{
            height: 48,
            width: 100,
            alignSelf: 'center',
            marginTop: 10,
          }}
        />
      </>
    );
  };
  const renderPage1 = () => {
    return (
      <View style={{ flexDirection: 'column' }}>
        <BoxShadow style={{ alignSelf: 'center' }} wWidth={'620px'} bottom={'10px'} right={'0px'}>
          {getDigitalVerificationStatus(userData?.idverification, userData?.yoti_reason)
            ?.BtnText === 'Completed' ? (
            <ODSBadges restStyle={{ marginBottom: 10, alignSelf: 'flex-end' }} type={'Completed'}>
              Completed
            </ODSBadges>
          ) : null}

          {userData?.idverification == 1 ? (
            <ODSBadges restStyle={{ marginBottom: 10, alignSelf: 'flex-end' }} type={'Pending'}>
              Pending
            </ODSBadges>
          ) : null}
          {id_verification?.idverification == 9 ? (
            <ODSBadges restStyle={{ marginBottom: 10, alignSelf: 'flex-end' }} type={'Expired'}>
              Failed
            </ODSBadges>
          ) : null}

          {preRefData ? (
            renderDocs()
          ) : (
            <>
              <ODSText style={{ alignSelf: 'center' }} type={'h5'}>
                ID Verification
              </ODSText>
              <ODSText
                color={themeObj.neutral[700]}
                marginTop={14}
                style={{ alignSelf: 'center' }}
                type={'md'}
              >
                You can share your verified identity as many times as you like and you control who
                sees this information.
              </ODSText>
              <ODSText
                color={themeObj.neutral[700]}
                marginTop={14}
                style={{ alignSelf: 'center' }}
                type={'md'}
              >
                You can use various documents to verify your ID but we recommend using a passport.
                If you have a British or Irish passport this will confirm your ‘right to rent’ in
                England.
              </ODSText>
              <ODSText
                color={themeObj.neutral[700]}
                marginTop={14}
                style={{ alignSelf: 'center' }}
                type={'md'}
              >
                Documents used for identity verification can be downloaded and saved in the Share
                Centre. You can then share them easily and securely by issuing a time limited share
                code to anyone you choose.
              </ODSText>
              <View
                style={{
                  alignItems: 'center',
                  flexDirection: 'row',
                  justifyContent: 'space-evenly',
                  width: '100%',
                }}
              >
                <ODSButton
                  lIcon={'TB-Arrow-Right'}
                  themeColor={themeObj.main.purple}
                  restStyle={{ marginTop: 20, alignSelf: 'center', width: '100%' }}
                  type="primary"
                  disabled={false}
                  onPress={() => setPage(2)}
                >
                  Get started
                </ODSButton>
              </View>
              <ODSText
                color={themeObj.neutral[700]}
                marginTop={26}
                textAlign={'center'}
                type={'md'}
              >
                When you press start you’ll be redirected to our ID Verification partner YOTI
              </ODSText>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: layoutType == 'web' ? 'space-evenly' : 'space-around',
                  alignItems: 'center',
                  marginTop: 20,
                }}
              >
                <Image
                  style={{ height: 40, width: 100, alignSelf: 'center' }}
                  source={require('./../../assets/images/yoti.svg')}
                />
                <View
                  style={{
                    height: 54,
                    backgroundColor: themeObj.neutral[300],
                    width: 1,
                  }}
                />
                <Image
                  source={require('./../../../openbrix-design-system/images/tlyfelogo.png')}
                  style={{ height: 38, width: 83, alignSelf: 'center' }}
                />
              </View>
              <ODSText
                color={themeObj.neutral[700]}
                marginTop={26}
                textAlign={'center'}
                type={'button'}
              >
                Digital Identity Checks
              </ODSText>
              <ODSText color={themeObj.neutral[700]} marginTop={8} textAlign={'center'} type={'h6'}>
                Smooth experience with YOTI and tlyfe
              </ODSText>
              <ODSText
                color={themeObj.neutral[700]}
                marginTop={14}
                textAlign={'center'}
                type={'sm'}
              >
                Yoti is the UK’s first certified Identity Services Provider (IDSP) under the Digital
                Identity and Attributes Trust Framework.
              </ODSText>
              <Image
                style={{ height: 54, width: 130, alignSelf: 'center', marginTop: 20 }}
                source={require('./../../../openbrix-design-system/images/ukcert.png')}
              />
              \
            </>
          )}
        </BoxShadow>
        {ButtonStyle()}
         
      </View>
    );
  };

  const renderPage2 = () => {
    return (
      <View
        style={{
          flexDirection: 'column',
          flex: 1,
        }}
      >
        <ODSText marginBottom={20} style={{ alignSelf: 'center' }} type={'h5'}>
          Chose Package
        </ODSText>

        <View style={{ flexDirection: layoutType === 'web' ? 'row' : 'column' }}>
          <View
            id="idverificationpack1"
            style={{ flexDirection: layoutType == 'web' ? 'row' : 'column' }}
          >
            <TouchableOpacity
              id={'idverificationpack1'}
              testID={'idverificationpack1'}
              onPress={() => {
                if (selectedPackedge?.product_id == combinedPackage?.product_id) {
                  setSelectedPackege('');
                } else {
                  selectedPackedge?.product_id != 300 && setAddOnSelected(false);
                  setSelectedPackege(combinedPackage);
                }
              }}
            >
              <BoxShadow
                id={'idverificationpack1'}
                style={{
                  borderWidth: selectedPackedge?.product_id == combinedPackage?.product_id ? 1 : 0,
                  borderColor: COLORS.LGREEN,
                }}
                right={'19px'}
              >
                <View id={'sdsdsdsd'} style={{ alignSelf: 'flex-end' }}>
                  <Ionicons
                    name={
                      selectedPackedge?.product_id === combinedPackage?.product_id
                        ? 'md-radio-button-on'
                        : 'md-radio-button-off'
                    }
                    size={24}
                    color={
                      selectedPackedge?.product_id === combinedPackage?.product_id
                        ? COLORS.LGREEN
                        : COLORS.BLACK
                    }
                  />
                </View>
                <ODSText marginTop={20} style={{ alignSelf: 'center' }} type={'button'}>
                  {combinedPackage.name}
                </ODSText>
                <ODSText marginTop={10} style={{ alignSelf: 'center' }} type={'sm'}>
                  £{combinedPackage?.price_with_vat} (inc. VAT)
                </ODSText>

                <ODSText marginTop={10} style={{ alignSelf: 'center' }} type={'sm'}>
                  Prove you meet the referencing criteria by securing a Rent Ready reference and
                  verify your ID to become a Rent Ready Tenant.
                </ODSText>
              </BoxShadow>
            </TouchableOpacity>
          </View>

          <TouchableOpacity
            id={'idverificationpack2'}
            onPress={() => {
              if (selectedPackedge?.product_id == referenceOnlyPackage?.product_id) {
                setSelectedPackege('');
              } else {
                selectedPackedge?.product_id != 300 && setAddOnSelected(false);
                setSelectedPackege(referenceOnlyPackage);
              }
            }}
            style={{
              flexDirection: 'column',
              width: layoutType == 'phone' ? '100%' : '344px',
              marginRight: layoutType === 'web' ? 20 : 0,
              marginBottom: 10,
            }}
          >
            <BoxShadow
              style={{
                borderWidth:
                  selectedPackedge?.product_id == referenceOnlyPackage?.product_id ? 1 : 0,
                borderColor: COLORS.LGREEN,
              }}
            >
              <View style={{ alignSelf: 'flex-end' }}>
                <Ionicons
                  name={
                    selectedPackedge?.product_id === referenceOnlyPackage?.product_id
                      ? 'md-radio-button-on'
                      : 'md-radio-button-off'
                  }
                  size={24}
                  color={
                    selectedPackedge?.product_id === referenceOnlyPackage?.product_id
                      ? COLORS.LGREEN
                      : COLORS.BLACK
                  }
                />
              </View>
              <ODSText marginTop={10} style={{ alignSelf: 'center' }} type={'button'}>
                {referenceOnlyPackage.name}
              </ODSText>
              <ODSText marginTop={10} style={{ alignSelf: 'center' }} type={'sm'}>
                £{referenceOnlyPackage?.price_with_vat} (inc. VAT)
              </ODSText>
              <ODSText marginTop={10} style={{ alignSelf: 'center' }} type={'sm'}>
                For tlyfe users who are already ID verified you can simply opt for the "Rent Ready
                Reference only" route and use this, with your existing verified ID to become a Rent
                Ready Tenant
              </ODSText>
            </BoxShadow>
          </TouchableOpacity>
        </View>
        <ODSText marginTop={10} paddingHorizontal={20} style={{ alignSelf: 'center' }} type={'sm'}>
          Note: if you use a UK or Irish passport to verify your ID, this will satisfy Right to Rent
          requirements
        </ODSText>
        <View
          style={{
            flexDirection: 'row',
            width: '100%',
            alignItems: 'center',
            marginBottom: '10px',
          }}
        >
          <CheckBox
            checked={selectedPackedge?.product_id != 300 ? false : addOnSelected}
            onIconPress={() =>
              selectedPackedge?.product_id == 300 && setAddOnSelected(!addOnSelected)
            }
            disabled={selectedPackedge?.product_id == referenceOnlyPackage?.product_id}
            checkedColor={COLORS.LGREEN}
            uncheckedColor={COLORS.GREY1}
            containerStyle={{ padding: 0 }}
          />
          <View style={{ width: '80%' }}>
            <ODSText marginTop={10} style={{ alignSelf: 'center' }} type={'button'}>
              Add Guarantor Reference: £{guarantorAddOnPackage.price_with_vat} (inc. VAT)
            </ODSText>

            <ODSText marginTop={10} style={{ alignSelf: 'center' }} type={'sm'}>
              If, having read the referencing criteria, you believe you need a guarantor, please
              select this option
            </ODSText>
          </View>
        </View>

        <View
          style={{
            width: '100%',
            height: 1,
            backgroundColor: COLORS.GREY1,
            marginVertical: 20,
          }}
        />
        {selectedPackedge.product_code == 'tenant_digital_reference_and_id_verification' ? (
          <>
            <ODSText marginTop={20} style={{ alignSelf: 'center' }} type={'sm'}>
              Before purchasing the Rent Ready Reference, you must read and agree to the terms and
              conditions below:
            </ODSText>

            <View
              style={{
                flexDirection: 'row',
                width: '100%',
                alignItems: 'center',
                marginTop: 12,
              }}
            >
              <CheckBox
                checked={agreeTerms}
                onIconPress={() => setAgreeTerms(!agreeTerms)}
                checkedColor={COLORS.LGREEN}
                uncheckedColor={COLORS.GREY1}
                containerStyle={{ padding: 0 }}
              />
              <View
                style={{
                  flexDirection: 'row',
                }}
              >
                <TouchableOpacity
                  style={{ width: layoutType == 'phone' && '60%' }}
                  onPress={() =>
                    window.open(
                      'https://fccparagon.com/wp-content/uploads/2023/03/prequalificationreferencetermsandconditions.pdf',
                      '_blank'
                    )
                  }
                >
                  <ODSText
                    textDecorationLine={'underline'}
                    style={{ alignSelf: 'center' }}
                    type={'sm'}
                  >
                    I agree to the terms and conditions of FCC Paragon Rent Ready Reference
                  </ODSText>
                </TouchableOpacity>
              </View>
            </View>
          </>
        ) : null}
        <View
          style={{
            marginTop: 20,
            width: 200,
            alignSelf: 'flex-end',
          }}
        >
          <ODSButton
            lIcon={'TB-Arrow-Right'}
            themeColor={themeObj.main.purple}
            restStyle={{ alignSelf: 'center', width: '100%' }}
            type="primary"
            disabled={false}
            onPress={() => {
              if (selectedPackedge?.product_id) {
                if (
                  selectedPackedge?.product_code == 'tenant_digital_reference_and_id_verification'
                ) {
                  if (agreeTerms) {
                    // startPayment();
                    setPage(9);
                  } else {
                    dispatch(showToaster('error', 'Accept the terms'));
                  }
                } else {
                  // startPayment();
                  setPage(9);
                }
              } else {
                dispatch(showToaster('error', 'Please select one package to proceed!'));
              }
            }}
          >
            Checkout
          </ODSButton>
        </View>
      </View>
    );
  };
  const renderPaymentMode = () => {
    return (
      <View
        style={{
          flexDirection: 'column',
          flex: 1,
          marginTop: 20,
        }}
      >
        <ODSText color={themeObj.neutral[700]} type={'h5'} marginBottom={20}>
          Choose a payment mode
        </ODSText>

        <View style={{ flexDirection: layoutType === 'web' ? 'row' : 'column' }}>
          <TouchableOpacity
            id={'idverificationpack1'}
            testID={'idverificationpack1'}
            onPress={() => {
              setType('card');
            }}
          >
            <BoxShadow
              id={'idverificationpack1'}
              style={{
                borderWidth: renderType == 'card' ? 1 : 0,
                borderColor: COLORS.LGREEN,
              }}
              bottom={'10px'}
              right={'19px'}
            >
              <ODSText
                textAlign={'flex-start'}
                color={themeObj.neutral[700]}
                style={{ marginTop: 10 }}
                marginTop={20}
                type={'button'}
              >
                <FontAwesome
                  name={'credit-card'}
                  size={16}
                  color={COLORS.LGREEN}
                  style={{ marginRight: 10 }}
                />
                Pay by card
              </ODSText>

              <Image
                source={require('../../assets/images/infohub/cards.png')}
                style={{ width: 150, height: 40, marginTop: 10, resizeMode: 'contain' }}
              />
              <ODSText
                textAlign={'flex-start'}
                color={themeObj.neutral[700]}
                style={{ marginTop: 10 }}
                marginTop={20}
                type={'button'}
              >
                Total cost £{getTotalCost()}
              </ODSText>
            </BoxShadow>
          </TouchableOpacity>

          <TouchableOpacity
            id={'idverificationpack2'}
            onPress={() => {
              setType('agent voucher');
            }}
            style={{
              flexDirection: 'column',
              width: layoutType == 'phone' ? '100%' : '344px',
              marginRight: 0,
              marginBottom: 10,
            }}
          >
            <BoxShadow
              style={{
                borderWidth: renderType == 'agent voucher' ? 1 : 0,
                borderColor: COLORS.LGREEN,
              }}
              bottom={'10px'}
              right={'19px'}
            >
              <ODSText
                textAlign={'flex-start'}
                color={themeObj.neutral[700]}
                style={{ marginTop: 10 }}
                marginTop={20}
                type={'button'}
              >
                <FontAwesome
                  name={'tag'}
                  size={16}
                  color={COLORS.LGREEN}
                  style={{ marginRight: 10 }}
                />
                Pay by agent voucher
              </ODSText>
              <ODSText color={themeObj.neutral[700]} type={'sm'}>
                By paying with agent voucher, you also get the rent ready reference included
              </ODSText>

              <ODSText
                textAlign={'flex-start'}
                color={themeObj.neutral[700]}
                style={{ marginTop: 10 }}
                marginTop={20}
                type={'button'}
              >
                Total cost £0
              </ODSText>
            </BoxShadow>
          </TouchableOpacity>
        </View>
        <View
          style={{
            marginTop: 20,
            width: 200,
            alignSelf: 'flex-end',
          }}
        >
          <ODSButton
            lIcon={'TB-Arrow-Right'}
            restStyle={{ width: '100%', marginTop: 20, alignSelf: 'center' }}
            type="secondary"
            themeColor={themeObj.main.purple}
            disabled={false}
            onPress={() => {
              onFirstTimeCardClick && renderType == 'card' && startPayment();
              setPaymentMode(renderType);
              renderType == 'card' && setFirstTimeClick(false);
              setPage(3);
            }}
          >
            Continue
          </ODSButton>
        </View>
      </View>
    );
  };

  const renderPage3 = () => {
    return (
      <View style={{ flexDirection: 'column' }}>
        <ODSText color={themeObj.neutral[700]} type={'h5'} marginBottom={20}>
          Checkout
        </ODSText>

        <View
          style={{
            flexDirection: 'column',
            width: layoutType == 'phone' ? '100%' : '344px',
          }}
        >
          <BoxShadow wWidth={'344px'} bottom={'10px'} right={'19px'} style={{ marginBottom: 24 }}>
            <View
              style={{
                width: '100%',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <ODSText color={themeObj.neutral[700]} type={'sm'}>
                {selectedPackedge?.name}
              </ODSText>
              <ODSText color={themeObj.neutral[700]} type={'sm'}>
                £{selectedPackedge.price_with_vat}
              </ODSText>
            </View>

            {addOnSelected ? (
              <View
                style={{
                  width: '100%',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginTop: 10,
                }}
              >
                <ODSText color={themeObj.neutral[700]} type={'sm'}>
                  Guarantor Reference
                </ODSText>
                <ODSText color={themeObj.neutral[700]} type={'sm'}>
                  £{guarantorAddOnPackage.price_with_vat}
                </ODSText>
              </View>
            ) : null}
            <ODSText
              textAlign={'flex-start'}
              color={themeObj.neutral[700]}
              style={{ marginTop: 10 }}
              marginTop={20}
              type={'button'}
            >
              Total cost: £{getTotalCost()}
            </ODSText>
          </BoxShadow>
          <View
            style={{
              flex: 1,
              justifyContent: 'flex-end',
              alignItems: 'flex-end',
              marginBottom: 10,
            }}
          >
            <TouchableOpacity
              onPress={() => {
                onFirstTimeCardClick && startPayment();
                setPaymentMode(paymentMode == 'card' ? 'agent voucher' : 'card');
                setFirstTimeClick(false);
              }}
              style={{
                flexDirection: 'row',
                marginVertical: 10,
                paddingHorizontal: 15,
                paddingVertical: 5,
                borderWidth: 1,
                borderColor: COLORS.LGREEN,
                borderRadius: 1000,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <FontAwesome
                name={paymentMode == 'card' ? 'tags' : 'credit-card'}
                size={16}
                color={COLORS.LGREEN}
              />
              <Typography left={10} size={14} color={'black'}>
                Pay by {paymentMode == 'card' ? 'agent voucher' : 'card'}
              </Typography>
            </TouchableOpacity>
          </View>
          {paymentMode == 'agent voucher' && (
            <PayByVoucher
              onSuccessToken={(success) => {
                if (success) {
                  setPage(4);
                } else {
                  setPage(6);
                }
              }}
            />
          )}
          {authDataFromMobile && paymentMode == 'card' && clientSecret ? (
            <Elements
              stripe={stripePromise}
              options={{
                clientSecret: clientSecret,
              }}
            >
              <CheckoutForm
                onSuccessToken={(success) => {
                  if (success) {
                    setPage(4);
                  } else {
                    setPage(6);
                  }
                }}
              />
            </Elements>
          ) : null}
          {hasUserSavedCookies && paymentMode == 'card' && clientSecret ? (
            <Elements
              stripe={stripePromise}
              options={{
                clientSecret: clientSecret,
              }}
            >
              <CheckoutForm
                onSuccessToken={(success) => {
                  if (success) {
                    setPage(4);
                  } else {
                    setPage(6);
                  }
                }}
              />
            </Elements>
          ) : null}
        </View>
      </View>
    );
  };

  const renderPage6 = () => {
    return (
      <View
        style={{
          flexDirection: 'column',
          width: layoutType == 'phone' ? '100%' : '344px',
        }}
      >
        <Image
          source={require('./../../assets/images/failed.png')}
          resizeMode="contain"
          style={{
            height: 100,
            width: 100,
            alignSelf: 'center',
            marginTop: 45,
          }}
        />
          <ODSText
          textAlign={'center'}
          color={themeObj.neutral[700]}
          style={{ alignSelf: 'center' }}
          type={'button'}
          marginTop={20}
        >
               Payment Failed!
        </ODSText>
        
        <ODSButton
          rIcon={'TB-Arrow-Left'}
          restStyle={{ width: 300, marginTop: 20, alignSelf: 'center' }}
          type="secondary"
          themeColor={themeObj.main.salmon}
          disabled={false}
          onPress={() => {
            setPage(2);
          }}
        >
          Back
        </ODSButton>
        
      </View>
    );
  };

  const renderPage4 = () => {
    return (
      <View
        style={{
          flexDirection: 'column',
          width: layoutType == 'phone' ? '100%' : '344px',
        }}
      >
        <Image
          source={require('./../../assets/images/Progress.svg')}
          resizeMode="contain"
          style={{
            height: 100,
            width: 100,
            alignSelf: 'center',
            marginTop: 45,
          }}
        />
        <ODSText
          textAlign={'center'}
          color={themeObj.neutral[700]}
          style={{ alignSelf: 'center' }}
          type={'button'}
          marginTop={20}
        >
          Payment successful!
        </ODSText>
        <ODSButton
          lIcon={'TB-Arrow-Right'}
          restStyle={{ width: 300, marginTop: 20, alignSelf: 'center' }}
          type="secondary"
          themeColor={themeObj.main.purple}
          disabled={false}
          onPress={() => openLinkYoti()}
        >
          Continue
        </ODSButton>

        <ODSText
          textAlign={'center'}
          color={themeObj.neutral[700]}
          style={{ alignSelf: 'center' }}
          textAlign={'center'}
          type={'sm'}
          marginTop={40}
        >
          When you press start you’ll be redirected to our Id Verification partner YOTI
        </ODSText>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignItems: 'center',
            marginTop: 20,
          }}
        >
          <Image
            source={require('./../../assets/images/yoti.svg')}
            resizeMode="contain"
            style={{
              height: 40,
              width: 100,
              alignSelf: 'center',
            }}
          />
          <View
            style={{
              height: 54,
              backgroundColor: themeObj.neutral[300],
              width: 1,
            }}
          />
          <Image
            source={require('./../../../openbrix-design-system/images/tlyfelogo.png')}
            style={{ height: 38, width: 83, alignSelf: 'center' }}
          />
        </View>
        <ODSText color={themeObj.neutral[700]} marginTop={26} textAlign={'center'} type={'h5'}>
          Digital Identity Checks
        </ODSText>
        <ODSText color={themeObj.neutral[700]} marginTop={26} textAlign={'center'} type={'button'}>
          Smooth experience with YOTI and tlyfe
        </ODSText>
        <ODSText color={themeObj.neutral[700]} marginTop={10} textAlign={'center'} type={'sm'}>
          Yoti is the UK’s first certified Identity Services Provider (IDSP) under the Digital
          Identity and Attributes Trust Framework.
        </ODSText>
        <Image
          style={{ height: 54, width: 130, alignSelf: 'center', marginTop: 20 }}
          source={require('./../../../openbrix-design-system/images/ukcert.png')}
        />
      </View>
    );
  };

  return (
    <>
      <Wrapper>
        {page == 1
          ? renderPage1()
          : page == 2
          ? renderPage2()
          : page == 3
          ? renderPage3()
          : page == 4
          ? renderPage4()
          : page == 6
          ? renderPage6()
          : renderPaymentMode()}
      </Wrapper>
    </>
  );
};

export default IdVerification;
