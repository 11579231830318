import React, { useState } from "react";
import { ScrollView, View } from "react-native";
import ODSTabs from "../../atoms/ODSTabs";
import { ODSText } from "../../atoms/ODSText";
import { useTheme } from "@emotion/react";
import MyDocs from "./MyDocs";
import getScreenType from "../../../utils/getScreenType";
import PropertyDocs from "./PropertyDocs";
import ShareCenter from "./ShareCenter";
// import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
const DocNShare = ({ DocumentData, onDelete, addDoc, propertyDocs, globalState, onShareSave }) => {
    const { colors } = useTheme();
    const layout = getScreenType();
    const [activeTab, setActiveTab] = useState(0);
    return (
        <>
            <View style={{ flex: 1, padding: 16 }}>
                <View style={{ width: layout == "phone" ? '100%' : layout == 'web' ? 840 : 621, alignItems: 'center', alignSelf: 'center', marginTop: layout == 'web' ? 60 : 8 }}>
                    <ODSText type="h3" color={colors.text.primary} textAlign='center'>Welcome to tlyfe Document Share Centre</ODSText>
                    <ODSText type="md-bold" marginTop={20} textAlign='center' color={colors.text.primary}>With tlyfe you are in control of what data you choose to share with your agents.</ODSText>
                    <ODSText type="sm" marginTop={24} textAlign='center' color={colors.text.primary}>Explore the tabs below to view, upload and share your documents safely with a temporary share code.</ODSText>
                    <View style={{ width: layout == "phone" ? '100%' : layout == 'web' ? 630 : '100%', marginTop: layout == 'web' ? 64 : 20 }}>
                        <ODSTabs onTabChange={setActiveTab} />
                    </View>
                    {activeTab == 0 && <MyDocs data={DocumentData} onDelete={onDelete} addDoc={addDoc} />}
                    {activeTab == 1 && <PropertyDocs data={propertyDocs} />}
                    {activeTab == 2 && <ShareCenter globalState={globalState} DocumentData={DocumentData} onShareSave={onShareSave} />}
                </View>


            </View>
        </>
    )
}
export default DocNShare;