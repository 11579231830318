import React, { useState } from 'react';
import {
    Text,
    StyleSheet,
    TouchableOpacity,
    View,
    ImageBackground,
    Image,
    Platform,
    Linking,
} from 'react-native';
import { ODSIcon } from '../ODSIcon';
import { ODSText } from '../ODSText';
import getScreenType from '../../../utils/getScreenType';
import { useTheme } from '@emotion/react';
import ODSButton from '../ODSButton';
import ODSKnob from '../ODSKnob';
import WebView from 'react-native-webview';
const webImage1 = require('../../../images/dashboard/tenancy-web1.png')
const mobileImage1 = require('../../../images/dashboard/tenancy-mobile2.png')
const webImage = require('../../../images/dashboard/tenancy-web.png')
const mobileImage = require('../../../images/dashboard/tenancy-mobile.png')


const ODSHomeBoxTile = ({
    onPress,
    movedIn,
    actionRequired = true,
    percentage = 20,
    fullWidth = false
}) => {
    const theme = useTheme();
    const layout = getScreenType();
    const handleWebViewMessage = (event: any) => {
        const { data } = event.nativeEvent;
        const fromWeb = JSON.parse(data);
        console.log('fromWeb', fromWeb);
        if (fromWeb?.val?.type && fromWeb?.val?.type === 'doctoopen_inapp') {
            Linking.openURL(fromWeb?.val?.Url);
        }
    };

    return (
        <View style={[styles(theme.colors, fullWidth).containerStyle]}>
            {Platform.OS == "web" ?
                <div className="rounded">
                    <iframe width="100%"
                        frameBorder="0"
                        hspace="0" vspace="0"
                        height="100%"
                        src=
                        "https://partners.home.cc/tlyfe/tile"
                        title="Home box" >
                    </iframe>
                </div> : <WebView
                    scalesPageToFit={true}
                    bounces={false}
                    javaScriptEnabled
                    style={{ height: "100%", width: '100%', resizeMode: 'cover', flex: 1, borderRadius: 8, opacity: 0.99 }}
                    injectedJavaScript={`const meta = document.createElement('meta'); meta.setAttribute('content', 'width=width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no'); meta.setAttribute('name', 'viewport'); document.getElementsByTagName('head')[0].appendChild(meta); `}
                    source={{ uri: 'https://partners.home.cc/tlyfe/tile' }}
                    automaticallyAdjustContentInsets={false}
                    onMessage={handleWebViewMessage}
                />
            }

        </View>
    );
};

const styles = (theme, fullWidth) =>
    StyleSheet.create({
        containerStyle: {
            width: getScreenType() == "phone" ? "96%" : fullWidth ? 628 : 405,
            borderRadius: 12,
            shadowColor: '#101828',
            height: 427,
            shadowOffset: { width: 0, height: 1 },
            shadowOpacity: 0.1,
            shadowRadius: 4,
            elevation: 2,
            backgroundColor: theme.neutral[100],
            alignSelf: 'center'
        },
    });

export default ODSHomeBoxTile;
