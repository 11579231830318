import React, { useState } from 'react';
import {
  Pressable,
  Text,
  StyleSheet,
  TouchableWithoutFeedback,
  TouchableOpacity,
  View,
  ImageBackground,
} from 'react-native';
import { ODSIcon } from '../ODSIcon';
import { ODSText } from '../ODSText';
import ODSButton from '../ODSButton';
import ODSBadges from '../ODSBadges';
import getScreenType from '../../../utils/getScreenType';
import { getTokens } from '../../../design-tokens/color-tokens';

const ODSTiles = ({
  subtitle,
  title,
  status,
  disabled,
  children,
  restStyle,
  rIcon,
  lIcon,
  themeColor,
  source,
  btnTheme,
  onPress,
  isRight,
}) => {
  const themeObj = getTokens('light').colors;
  // console.log('themeObj', themeObj);
  const [isPressed, setIsPressed] = useState(false);

  return (
    <View style={[styles(themeObj, isRight).containerStyle, restStyle]}>
      <ImageBackground
        source={source ? source : require('./Affordablity_Hero.png')}
        style={{ width: 140 }}
        resizeMode="cover"
        imageStyle={
          isRight
            ? { borderTopRightRadius: 20, borderBottomRightRadius: 12 }
            : { borderTopLeftRadius: 20, borderBottomLeftRadius: 12 }
        }
      />
      <View style={{ marginHorizontal: 12, marginVertical: 10, flex: 1 }}>
        {status ? (
          <ODSBadges restStyle={{ marginBottom: 10, alignSelf: 'flex-end' }} type={status}>
            {status}
          </ODSBadges>
        ) : null}
        <View style={{}}>
          <ODSText marginTop={6} color="black" type={'h5'}>
            {title}
          </ODSText>
          <ODSText marginTop={12} color={themeObj?.text.secondary} type={'sm'}>
            {subtitle}
          </ODSText>
        </View>
        <View style={{ marginTop: 20, marginBottom: 24 }}>
          <ODSButton
            restStyle={{ width: '100%' }}
            type="primary"
            disabled={false}
            themeColor={btnTheme}
            onPress={onPress}>
            Go
          </ODSButton>
        </View>
      </View>
    </View>
  );
};

const styles = (theme, isRight) =>
  StyleSheet.create({
    containerStyle: {
      display: 'flex',
      width: getScreenType() == 'phone' ? '100%' : 360,
      borderRadius: 20,
      shadowColor: '#000',
      shadowOffset: { width: 1, height: 3 },
      shadowOpacity: 0.15,
      shadowRadius: 8,
      elevation: 5,
      backgroundColor: theme.neutral[100],
      flexDirection: isRight ? 'row-reverse' : 'row',
    },
  });

export default ODSTiles;
