export const types = {
  //
  // ─── LISTING ACTION TYPES ───────────────────────────────────────────────────────
  //
  GET_USERLIST_REQUEST: "GET_USERLIST_REQUEST",
  saveAuthTokenFromMobile:"saveAuthTokenFromMobile",
  GET_USERLIST_SUCCESS: "GET_USERLIST_SUCCESS",
  GET_INVOICE_REQUSET: "GET_INVOICE_REQUSET",
  GET_INVOICE_RESPONSE: "GET_INVOICE_RESPONSE",
  GET_USERLIST_FAILED: "GET_USERLIST_FAILED",
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILED: "LOGIN_FAILED",
  SIGNUP_REQUEST: "SIGNUP_REQUEST",
  SIGNUP_SUCCESS: "SIGNUP_SUCCESS",
  SIGNUP_FAILED: "SIGNUP_FAILED",
  PROFILE_REQUEST: "PROFILE_REQUEST",
  PROFILE_REQUEST_SUCCESS: "PROFILE_REQUEST_SUCCESS",
  PROFILE_REQUEST_FAILED: "PROFILE_REQUEST_FAILED",
  SEARCH_REQUEST: "SEARCH_REQUEST",
  SEARCH_SUCCESS: "SEARCH_SUCCESS",
  SEARCH_FAILED: "SEARCH_FAILED",
  SEARCHMAP_REQUEST: "SEARCHMAP_REQUEST",
  SEARCHMAP_SUCCESS: "SEARCHMAP_SUCCESS",
  SEARCHMAP_FAILED: "SEARCHMAP_FAILED",
  RE_SEARCH_REQUEST: "RE_SEARCH_REQUEST",
  RE_SEARCH_SUCCESS: "RE_SEARCH_SUCCESS",
  RE_SEARCH_FAILED: "RE_SEARCH_FAILED",
  RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILED: "RESET_PASSWORD_FAILED",
  CHANGE_PASSWORD_REQUEST: "CHANGE_PASSWORD_REQUEST",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_FAILED: "CHANGE_PASSWORD_FAILED",
  GET_PROPERTY_REQUEST: "GET_PROPERTY_REQUEST",
  GET_PROPERTY_SUCCESS: "GET_PROPERTY_SUCCESS",
  GET_PROPERTY_FAILED: "GET_PROPERTY_FAILED",
  GET_PROPERTY_CARD_REQUEST: "GET_PROPERTY_CARD_REQUEST",
  GET_PROPERTY_CARD_SUCCESS: "GET_PROPERTY_CARD_SUCCESS",
  GET_PROPERTY_CARD_FAILED: "GET_PROPERTY_CARD_FAILED",
  REMOVE_PROPERTY_CARD: "REMOVE_PROPERTY_CARD",
  SAVE_SEARCH_OBJECT: "SAVE_SEARCH_OBJECT",
  SAVE_PROPERTY_REQUEST: "SAVE_PROPERTY_REQUEST",
  SAVE_PROPERTY_SUCCESS: "SAVE_PROPERTY_SUCCESS",
  SAVE_PROPERTY_FAILED: "SAVE_PROPERTY_FAILED",
  POSTS_VIDEOS_SUCCESS: "POSTS_VIDEOS_SUCCESS",
  GET_SAVED_REQUEST: "GET_SAVED_REQUEST",
  GET_SAVED_SUCCESS: "GET_SAVED_SUCCESS",
  GET_SAVED_FAILED: "GET_SAVED_FAILED",
  CONTACTUS_REQUEST: "CONTACTUS_REQUEST",
  CONTACTUS_SUCCESS: "CONTACTUS_SUCCESS",
  CONTACTUS_FAILED: "CONTACTUS_FAILED",

  UPLOAD_DOC: "UPLOAD_DOC",
  UPLOAD_DOC_SUCCESS: "UPLOAD_DOC_SUCCESS",
  UPLOAD_DOC_FAIL: "UPLOAD_DOC_FAIL",

  UPLOAD_FILE: "UPLOAD_FILE",
  UPLOAD_FILE_SUCCESS: "UPLOAD_FILE_SUCCESS",
  UPLOAD_FILE_FAIL: "UPLOAD_FILE_FAIL",
  UPLOAD_FILE_RESET: "UPLOAD_FILE_RESET",


  GET_DOC: "GET_DOC",
  GET_DOC_SUCCESS: "GET_DOC_SUCCESS",
  GET_DOC_FAIL: "GET_DOC_FAIL",
  UPDATE_SHARE_STATUS_DOC: "UPDATE_SHARE_STATUS_DOC",
  UPDATE_SHARE_STATUS_DOC_SUCCESS: "UPDATE_SHARE_STATUS_DOC_SUCCESS",
  UPDATE_SHARE_STATUS_DOC_FAIL: "UPDATE_SHARE_STATUS_DOC_FAIL",
  DELETE_DOC: "DELETE_DOC",
  DELETE_DOC_SUCCESS: "DELETE_DOC_SUCCESS",
  DELETE_DOC_FAIL: "DELETE_DOC_FAIL",
  DESTROY_STATE: "DESTROY_STATE",

  CREATE_ALERT_REQUEST: "CREATE_ALERT_REQUEST",
  CREATE_ALERT_SUCCESS: "CREATE_ALERT_SUCCESS",
  CREATE_ALERT_FAILED: "CREATE_ALERT_FAILED",
  GET_MY_ALERTS_REQUEST: "GET_MY_ALERTS_REQUEST",
  GET_MY_ALERTS_SUCCESS: "GET_MY_ALERTS_SUCCESS",
  GET_MY_ALERTS_FAILED: "GET_MY_ALERTS_FAILED",
  GET_ALERT_REQUEST: "GET_ALERT_REQUEST",
  GET_ALERT_SUCCESS: "GET_ALERT_SUCCESS",
  GET_ALERT_FAILED: "GET_ALERT_FAILED",
  GET_ALERT_BY_ID_REQUEST: "GET_ALERT_BY_ID_REQUEST",
  GET_ALERT_BY_ID_SUCCESS: "GET_ALERT_BY_ID_SUCCESS",
  GET_ALERT_BY_ID_FAILED: "GET_ALERT_BY_ID_FAILED",
  DEL_ALERT_REQUEST: "DEL_ALERT_REQUEST",
  DEL_ALERT_SUCCESS: "DEL_ALERT_SUCCESS",
  DEL_ALERT_FAILED: "DEL_ALERT_FAILED",
  CLEAR_ALERT: "CLEAR_ALERT",
  UPDATE_PROFILE: "UPDATE_PROFILE",
  UPDATE_PROFILE_SUCCESS: "UPDATE_PROFILE_SUCCESS",
  UPDATE_PROFILE_FAIL: "UPDATE_PROFILE_FAIL",
  PAGE_DATA: "PAGE_DATA",
  //
  // ─── STATUS ACTION TYPES ───────────────────────────────────────────────────────
  //
  SHOW_ALERT: "SHOW_ALERT",
  HIDE_ALERT: "HIDE_ALERT",
  //
  SHOW_TOASTER_POPUP: "SHOW_TOASTER_POPUP",
  HIDE_TOASTER_POPUP: "HIDE_TOASTER_POPUP",
  // ─── TOKJEN ACTION TYPES ───────────────────────────────────────────────────────
  //
  SAVE_AUTH_TOKEN: "SAVE_AUTH_TOKEN",
  REMOVE_AUTH_TOKEN: "REMOVE_AUTH_TOKEN",

  LOG_ERROR_REQUEST: "LOG_ERROR_REQUEST",
  LOG_ERROR_SUCCESS: "LOG_ERROR_SUCCESS",
  LOG_ERROR_FAILED: "LOG_ERROR_FAILED",

  // ------Credit Ladder Types--------------------------

  CLHISTORY_REQUEST: "CLHISTORY_REQUEST",
  CLHISTORY_SUCCESS: "CLHISTORY_SUCCESS",
  CLHISTORY_FAILED: "CLHISTORY_FAILED",

  // -------Ladger Action Types--------------------------

  LADGER_REQUEST: "LADGER_REQUEST",
  LADGER_SUCCESS: "LADGER_SUCCESS",
  LADGER_FAILED: "LADGER_FAILED",

  CL_PAYMENT_FAILED: "CL_PAYMENT_FAILED",
  CL_PAYMENT_REQUEST: "CL_PAYMENT_REQUEST",
  CL_PAYMENT_SUCCESS: "CL_PAYMENT_SUCCESS",

  SAVE_SORT_VALUE: "SAVE_SORT_VALUE",
  SAVE_TDS_SELECTION: "SAVE_TDS_SELECTION",

  // -------MEDIA HUB Types--------------------------

  GET_ARTICLES: "GET_ARTICLES",
  GET_ARTICLES_SUCCESS: "GET_ARTICLES_SUCCESS",
  GET_ARTICLES_FAILED: "GET_ARTICLES_FAILED",

  GET_VIDEOS: "GET_VIDEOS",
  GET_VIDEOS_SUCCESS: "GET_VIDEOS_SUCCESS",
  GET_VIDEOS_FAILED: "GET_VIDEOS_FAILED",

  GET_POST_BY_ID: "GET_POST_BY_ID",
  GET_POST_BY_ID_SUCCESS: "GET_POST_BY_ID_SUCCESS",
  GET_POST_BY_ID_FAILED: "GET_POST_BY_ID_FAILED",

  PROPERTYNEARME_SUCCESS: "PROPERTYNEARME_SUCCESS",
  PROPERTYNEARME_REQUEST: "PROPERTYNEARME_REQUEST",
  PROPERTYNEARME_FAIL: "PROPERTYNEARME_FAIL",

  REMOVE_TENANT: "REMOVE_TENANT",
  REMOVE_TENANT_SUCCESS: "REMOVE_TENANT_SUCCESS",
  REMOVE_TENANT_FAIL: "REMOVE_TENANT_FAIL",

  // --------------pROPERTY DATA-------------
  GET_PROPERTY_COUNCIL_REQUEST: "GET_PROPERTY_COUNCIL_REQUEST",
  GET_PROPERTY_COUNCIL_SUCCESS: "GET_PROPERTY_COUNCIL_SUCCESS",
  GET_PROPERTY_COUNCIL_FAILED: "GET_PROPERTY_COUNCIL_FAILED",
  //==============
  GET_PROPERTY_SCHOOL_REQUEST: "GET_PROPERTY_SCHOOL_REQUEST",
  GET_PROPERTY_SCHOOL_SUCCESS: "GET_PROPERTY_SCHOOL_SUCCESS",
  GET_PROPERT_SCHOOL_FAILED: "GET_PROPERT_SCHOOL_FAILED",
  //================\
  GET_TRANSPORT_REQUEST: "GET_TRANSPORT_REQUEST",
  GET_TRANSPORT_SUCCESS: "GET_TRANSPORT_SUCCESS",
  GET_TRANSPORT_FAILED: "GET_TRANSPORT_FAILED",
  //=============
  UPDATE_NOTIFICATION_REQUEST: "UPDATE_NOTIFICATION_REQUEST",
  UPDATE_NOTIFICATION_SUCCESS: "UPDATE_NOTIFICATION_SUCCESS",
  UPDATE_NOTIFICATION_FAILED: "UPDATE_NOTIFICATION_FAILED",
  //........notification
  ADDNOTIFICATION: "ADDNOTIFICATION",
  ADDNOTIFICATIONARCHIVED:"ADDNOTIFICATIONARCHIVED",
//rEMOVE NOTIFICATION
  REMOVE_NOTIFICATION_REQUEST: "REMOVE_NOTIFICATION_REQUEST",
  REMOVE_NOTIFICATION_SUCCESS: "REMOVE_NOTIFICATION_SUCCESS",
  REMOVE_NOTIFICATION_FAILED: "REMOVE_NOTIFICATION_FAILED",
//..........PreReferencing
GET_AFFORD_DATA:"GET_AFFORD_DATA",

 GET_AFFORD_DATA_RESET:"GET_AFFORD_DATA_RESET",
 GET_AFFORD_SUCCESS:"GET_AFFORD_SUCCESS",
 GET_AFFORD_FAIL:"GET_AFFORD_FAIL",
 GET_CCQ_DATA:"GET_CCQ_DATA",
 GET_CCQ_SUCCESS:"GET_CCQ_SUCCESS",
 GET_CCQ_FAIL:"GET_CCQ_FAIL",

 SKIP_CCQ_DATA: "SKIP_CCQ_DATA",
 SKIP_CCQ_DATA_FAIL: "SKIP_CCQ_DATA_FAIL",
 SKIP_CCQ_DATA_SUCCESS: "SKIP_CCQ_DATA_SUCCESS",


 CCJ_PENDING: "SKIP_CCJ_PENDING",
 CCJ_PENDING_FAIL: "SKIP_CCJ_PENDING_FAIL",
 CCJ_PENDING_SUCCESS: "SKIP_CCJ_PENDING_SUCCESS",
 GET_CCQ_URI_SUCCESS: "GET_CCQ_URI_SUCCESS",
 GET_CCQ_URI : "GET_CCQ_URI",
 GET_CCQ_URI_FAIL: "GET_CCQ_URI_FAIL",

 GET_MAINTANANCE: "GET_MAINTANANCE",
 GET_MAINTANANCE_FAIL: "GET_MAINTANANCE_FAIL",
 GET_MAINTANANCE_SUCCESS: "GET_MAINTANANCE_SUCCESS",

 REMOVE_AFFORDIBILITY:"REMOVE_AFFORDIBILITY",
 REMOVE_AFFORDIBILITY_SUCCESS:"REMOVE_AFFORDIBILITY_SUCCESS",
 REMOVE_AFFORDIBILITY_FAIL:"REMOVE_AFFORDIBILITY_FAIL",

 REMOVE_CCJ:"REMOVE_CCJ",
 REMOVE_CCJ_SUCCESS:"REMOVE_CCJ_SUCCESS",
 REMOVE_CCJ_FAIL:"REMOVE_CCJ_FAIL",
 GET_PROPERTY_SCHOOL_FAILED:"GET_PROPERTY_SCHOOL_FAILED",
 SET_CREDIT_PAYMENT:"SET_CREDIT_PAYMENT",
 SET_INAPP_WEBVIEW:"SET_INAPP_WEBVIEW",


 UPDATE_IS_BRITISH_PASSWORD:'UPDATE_IS_BRITISH_PASSWORD'
};
